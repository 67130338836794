import i18n from '@/vendors/i18n'
import { projects_overview_options } from '@/configurations/general/components-default-data'

export default {
  name: 'custom_homepage_111111',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Header',
        }
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'header.banner',
      settings: {
        crop: true,
        size: { width: 1440, height: 604 }
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'header.first_button',
      content: {
        label: {
          'nl-NL': 'First Button'
        }
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'header.second_button',
      content: {
        label: {
          'nl-NL': 'Second Button'
        }
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'header.third_button',
      content: {
        label: {
          'nl-NL': 'Third Button'
        }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.campaign')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'campaign.title_first_part',
      content: {
        label: {
          'nl-NL': 'Title first part'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'campaign.title_second_part',
      content: {
        label: {
          'nl-NL': 'Title second part'
        }
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'campaign.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'campaign.image',
      settings: {
        crop: true,
        size: { width: 500, height: 601 }
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'campaign.read_more',
      content: {
        label: {
          'nl-NL': 'Read More Button'
        }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.projects')
      }
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'projects.query.campaign_id',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionSelect',
      model: 'projects.query.sort',
      value: 'created_at_first',
      settings: {
        options: projects_overview_options
      },
      content: {
        label: i18n.getLocalesTranslations('literal.sort_project_by')
      }
    },
    {
      path: 'modules/pages/SectionInputNumber',
      model: 'projects.query.per_page',
      value: 4,
      content: {
        label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.about')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'about.image',
      settings: {
        crop: true,
        size: { width: 517, height: 620 }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'about.title_first_part',
      content: {
        label: {
          'nl-NL': 'Title first part'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'about.title_second_part',
      content: {
        label: {
          'nl-NL': 'Title second part'
        }
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'about.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'about.read_more',
      content: {
        label: {
          'nl-NL': 'Read More Button'
        }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Testimonials'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'testimonials.items',
      settings: {
        hide_label: true
      },
      content: {
        btn_label: {
          'nl-NL': 'Add new testimonial'
        }
      },
      components: [
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          settings: {
            configuration: ['bold', 'italic', 'underline']
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'button'
        },

      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Social networks'
        }
      }
    },
    {
      path: 'modules/pages/SectionNetworks'
    }
  ],
  content: {
    title: {
      'en-GB': '11 11 11',
      'fr-FR': '11 11 11',
      'nl-NL': '11 11 11'
    }
  }
}
