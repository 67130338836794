import FormConfiguration from '@/classes/procedures/FormConfiguration'
import TwikeyFormConfiguration from '@/classes/procedures/TwikeyFormConfiguration'
import Settings from '@/classes/procedures/Settings'
import Action from '@/classes/procedures/Action'
import i18n from '@/vendors/i18n'
import defaultActions from '@/configurations/procedures/actions'
import { clone } from '@/utils/utils'
import { get as _get, flattenDeep as _flattenDeep, isUndefined as _isUndefined } from 'lodash'

export default class Procedure {
  constructor ({ id, name, form_configuration, actions, payment_profile, status, slug, category, created_at, emails, contributors_count, current_amount, target_type, target_id, fixed_goal_amount, goal_amount, settings, start_date, end_date, default_values, external_reference, additional_external_reference, tax_receipt_allowed, form_provider } = {}) {
    this.id = id || null
    this.name = name || i18n.getLocalesTranslations()
    this.form_configuration = this.setFormConfiguration({ form_configuration, form_provider })
    this.actions = actions || {}
    this.payment_profile = payment_profile || {}
    this.status = status || null
    this.slug = slug || null
    this.category = category || null
    this.created_at = created_at || null
    this.emails = emails || []
    this.contributors_count = contributors_count || 0
    this.current_amount = current_amount || 0
    this.target_type = target_type || null
    this.target_id = target_id || null
    this.fixed_goal_amount = fixed_goal_amount || null
    this.goal_amount = goal_amount || null
    this.settings = new Settings(settings || {})
    this.start_date = start_date || null
    this.end_date = end_date || null
    this.default_values = default_values || {}
    this.external_reference = external_reference || null
    this.additional_external_reference = additional_external_reference || null
    this.tax_receipt_allowed = !_isUndefined(tax_receipt_allowed) ? tax_receipt_allowed : (this.category === 'donation' ? true : false)
    this.form_provider = form_provider || null
    this.avoidMigration()
    this.setActions()
  }

  get is_live () {
    return this.status === 'published'
  }

  get is_draft () {
    return this.status === 'draft'
  }

  get custom_field_models () {
    return this.getBlocks().filter(block => block.is_custom_field).map(block => block.model)
  }

  get has_custom_fields () {
    return !!this.custom_field_models.length
  }

  get payment_provider () {
    return _get(this.payment_profile, 'payment_provider')
  }

  get is_tamaro () {
    return this.payment_provider === 'tamaro'
  }

  database = () => {
    return {
      id: this.id,
      name: this.name,
      form_configuration: this.setDbFormConfiguration(),
      actions: this.actions,
      payment_profile: this.payment_profile,
      status: this.status,
      slug: this.slug,
      category: this.category,
      created_at: this.created_at,
      target_type: this.target_type,
      target_id: this.target_id,
      settings: this.settings,
      start_date: this.start_date,
      end_date: this.end_date,
      default_values: this.default_values,
      fixed_goal_amount: this.fixed_goal_amount,
      external_reference: this.external_reference,
      additional_external_reference: this.additional_external_reference,
      tax_receipt_allowed: this.tax_receipt_allowed,
      form_provider: this.form_provider
    }
  }

  setFormConfiguration ({ form_configuration, form_provider }) {
    if (!form_provider) {
      return form_configuration ? new FormConfiguration(form_configuration) : null
    } else if (form_provider === 'twikey') {
      return new TwikeyFormConfiguration(form_configuration)
    }
  }

  setDbFormConfiguration () {
    if (!this.form_provider) {
      return this.form_configuration ? new FormConfiguration(this.form_configuration).database() : null
    } else if (this.form_provider === 'twikey') {
      return new TwikeyFormConfiguration(this.form_configuration).database()
    }
  }

  getBlocks () {
    return _get(this.form_configuration, 'steps') ? _flattenDeep(this.form_configuration.steps.map(step => step.sections.map(section => section.blocks))) : []
  }

  isAlreadyUsed (block) {
    return this.getBlocks().some(_block => _block.name === block.name)
  }

  getSections () {
    return _get(this.form_configuration, 'steps') ? _flattenDeep(this.form_configuration.steps.map(step => step.sections)) : []
  }

  hasAmountChoiceBlock () {
    return this.getBlocks().some(block => block.model && block.model.includes('actions_data.transaction.amount'))
  }

  getFrequencyOptions () {
    return _get(this.getBlocks().find(block => block.model && block.model.includes('actions_data.transaction.frequency')), 'attributes.options', [])
  }

  getBlocksByName () {
    return this.getBlocks().map(block => ({ [block.name]: block }))
  }

  amountChoiceBlockHasConditions () {
    const block = this.getBlocks().find(block => block.model && block.model.includes('actions_data.transaction.amount'))
    return block ? !!block.options.has_conditions : false
  }

  getDefaultAction (key) {
    return new Action(clone(defaultActions[key]))
  }

  getFee () {
    let action = this.getDefaultAction('fee')

    const amount = _get(this.form_configuration, 'fee')
    const optional = this.getBlocks().some(block => block.model && block.model.includes('actions_data.transaction.payment_method') && block.options.has_conditions)

    action.parameters.amount = amount
    action.parameters.optional = optional

    return amount ? action : false
  }

  getOrder () {
    let action = this.getDefaultAction('order')

    const optional = this.getBlocks().some(block => block.model && block.model.includes('actions_data.order.order_lines') && (!block.mandatory.includes('productRequired') || block.options.has_conditions))
    const products = _flattenDeep(this.getBlocks().filter(block => block.model && block.model.includes('actions_data.order.order_lines')).map(block => block.attributes.ids))

    action.parameters.optional = !products.length ? true : optional
    action.parameters.products = products

    return products.length || this.category === 'donation' ? action : false
  }

  getCreateInvite () {
    let action = this.getDefaultAction('create_invite')

    const optional = this.getBlocks().some(block => block.model && block.model.includes('actions_data.create_invite') && (!block.mandatory.includes('required') || block.options.has_conditions))

    action.parameters.optional = optional

    return this.requiresCreateInviteAction() ? action : false
  }

  getAcceptInvite () {
    let action = this.getDefaultAction('accept_invite')
    return this.category === 'project-invite' ? action : false
  }

  getTransaction () {
    let action = this.getDefaultAction('transaction')

    const fee = _get(this.actions, 'fee.parameters.amount', 0)
    const is_fee_optional = _get(this.actions, 'fee.parameters.optional', false)
    const optional = !(this.hasAmountChoiceBlock() && !this.amountChoiceBlockHasConditions() || (fee && !is_fee_optional))
    const frequencies = this.getFrequencyOptions()
    const statement_descriptor = _get(this.form_configuration, 'statement_descriptor')

    action.parameters.optional = optional
    action.parameters.statement_descriptor = statement_descriptor

    if (frequencies.some(frequency => frequency.value === 'monthly') && frequencies.some(frequency => frequency.value === 'one_time')) {
      delete action.attributes.is_subscription
    } else if (frequencies.some(frequency => frequency.value === 'monthly')) {
      action.attributes.is_subscription = true
    } else {
      action.attributes.is_subscription = false
    }

    return this.hasFeeAction() || this.hasOrderAction() || this.hasAmountChoiceBlock() ? action : false
  }

  getProject (actions) {
    let action = this.getDefaultAction('project')

    action.attributes.status = _get(actions, 'project.attributes.status', 'published')

    return this.category === 'create-project' ? action : false
  }

  getRecaptcha () {
    let action = this.getDefaultAction('recaptcha')

    const transaction = this.getTransaction()

    return this.category === 'donation' && (!transaction || transaction.parameters.optional) ? action : false
  }

  requiresCreateInviteAction () {
    return this.getBlocks().some(block => block.model && block.model.includes('actions_data.create_invite'))
  }

  hasFeeAction () {
    return 'fee' in this.actions
  }

  hasOrderAction () {
    return 'order' in this.actions
  }

  hasTransactionAction () {
    return 'transaction' in this.actions
  }

  getAction (name, actions) {
    if (name === 'fee') return this.getFee()
    if (name === 'create_invite') return this.getCreateInvite()
    if (name === 'accept_invite') return this.getAcceptInvite()
    if (name === 'order') return this.getOrder()
    if (name === 'transaction') return this.getTransaction()
    if (name === 'project') return this.getProject(actions)
    if (name === 'recaptcha') return this.getRecaptcha()
    return false
  }

  setActions () {
    const current_actions = this.actions
    const actions = ['fee', 'create_invite', 'accept_invite', 'order', 'transaction', 'project', 'recaptcha']

    this.actions = {}

    actions.forEach(name => {
      const action = this.getAction(name, current_actions)
      if (action) this.actions[name] = action
    })

    return this.actions
  }

  avoidMigration () {
    if (this.form_configuration) {
      if (!_get(this.form_configuration, 'statement_descriptor')) {
        this.form_configuration.statement_descriptor = _get(this.actions, 'transaction.parameters.statement_descriptor', null)
      }

      if (_get(this.form_configuration, 'fee') === undefined) this.form_configuration.fee = _get(this.actions, 'fee.parameters.amount', 0)
    }
  }
}
