import i18n from '@/vendors/i18n'

export default {
  name: 'informations_manual_domiciliation_bic',
  model: 'informations.manual_domiciliation.bic',
  type: 'input_text',
  settings: {
    icon: 'bic',
    require_model: true,
    is_deletable: false
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_informations_bic')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      value: i18n.getLocalesTranslations('$block_informations_bic.label_bic'),
      content: {
        label: i18n.getLocalesTranslations('literal.label')
      }
    },
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.placeholder',
      value: i18n.getLocalesTranslations('$block_informations_bic.placeholder_bic'),
      content: {
        label: i18n.getLocalesTranslations('literal.placeholder')
      }
    }
  ],
  is_allowed: {
    'procedure.category': ['donation'],
    'client.allowed_features': ['manual_domiciliation']
  }
}
