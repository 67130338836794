import { createPinia } from 'pinia'
import { resetStore, actionsStore, gettersStore } from '@/stores/plugins'

const pinia = createPinia()

pinia.use(resetStore)
pinia.use(actionsStore)
pinia.use(gettersStore)

export default pinia
