import i18n from '@/vendors/i18n'
import Block from '@/classes/procedures/Block'

export default {
  name: 'transaction_payment',
  content: {
    title: i18n.getLocalesTranslations('$procedure_section.type_payment'),
    description: i18n.getLocalesTranslations('$procedure_section.type_payment_description')
  },
  options: {
    collapse: false
  },
  blocks: [
    Block.set({
      name: 'title',
      attributes: {
        title: i18n.getLocalesTranslations('$procedure_block.payment_title')
      }
    }),
    Block.set({ name: 'transaction_payment_methods' })
  ]
}
