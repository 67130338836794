import i18n from '@/vendors/i18n'
import Block from '@/classes/procedures/Block'

export default {
  name: 'success',
  content: {
    title: i18n.getLocalesTranslations('$procedure_section.type_congrats'),
    description: i18n.getLocalesTranslations('$procedure_section.type_congrats_description')
  },
  options: {
    collapse: false
  },
  blocks: [
    Block.set({
      name: 'title',
      attributes: {
        title: i18n.getLocalesTranslations('$procedure_block.congrats_title')
      }
    })
  ]
}
