import i18n from '@/vendors/i18n'

export default {
  name: 'transaction_customer_is_anonymous',
  model: 'customer.is_anonymous',
  type: 'input_checkbox',
  settings: {
    icon: 'ghost',
    category: 'personal_informations',
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('literal.anonymous_option')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      value: i18n.getLocalesTranslations('literal.anonymous_contribution'),
      content: {
        label: i18n.getLocalesTranslations('literal.label')
      }
    },
    {
      path: 'molecules/ModuleInputTextarea',
      model: 'attributes.text',
      value: i18n.getLocalesTranslations('literal.your_name_wont_appear_on_the_project_page'),
      content: {
        label: i18n.getLocalesTranslations('literal.short_description')
      }
    },
    {
      path: 'molecules/ModuleInputSelect',
      model: 'attributes.default_value',
      value: false,
      options: [
        {
          label: i18n.getLocalesTranslations('literal.checked'),
          value: true
        },
        {
          label: i18n.getLocalesTranslations('literal.not_checked'),
          value: false
        }
      ],
      content: {
        label: i18n.getLocalesTranslations('literal.default_value')
      }
    },
  ],
  is_allowed: {
    'procedure.target_type': ['Campaign'],
    'procedure.category': ['donation']
  }
}
