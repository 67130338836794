import i18n from '@/vendors/i18n'

export default {
  name: 'custom_header_expeditienatuurpunt',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionLogo'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.menu')

      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'links',
      settings: {
        layout: 'inline',
        hide_label: true
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'submenu',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_sublink')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.actions')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'login',
      content: {
        label: {
          'nl-NL': 'Login button'
        }
      }
    }
  ]
}
