import i18n from '@/vendors/i18n'
import { metrics_type_options, projects_overview_options } from '@/configurations/general/components-default-data'

export default {
  name: 'custom_homepage_azvmsv',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'hero.image',
      settings: {
        crop: false,
        size: { width: 1600, height: 600 }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'hero.title',
      content: {
        label: {
          'fr-FR': 'Titre',
          'nl-NL': 'Title'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'hero.subtitle',
      content: {
        label: {
          'fr-FR': 'Phrase d\'accroche',
          'nl-NL': 'Vang de zin'
        }
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'hero.cta1',
      content: {
        label: {
          'fr-FR': 'Bouton d\'action principal',
          'nl-NL': 'Belangrijkste actieknop'
        }
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'hero.cta2',
      content: {
        label: {
          'fr-FR': 'Bouton d\'action secondaire',
          'nl-NL': 'Secundaire actieknop'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'metrics',
      content: {
        label: {
          'fr-FR': 'Statistiques',
          'nl-NL': 'Statistieken'
        },
        wrapper_title: 'text',
        btn_label: i18n.getLocalesTranslations('literal.add_metric'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          value: 'contributors_count',
          settings: {
            options: metrics_type_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.metric')
          }
        },
        {
          path: 'modules/pages/SectionInputDate',
          model: 'date',
          content: {
            label: i18n.getLocalesTranslations('literal.date')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'date',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text'
        }
      ]
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'article.title',
      content: {
        label: {
          'fr-FR': 'Titre',
          'nl-NL': 'Title'
        }
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'article.text',
      content: {
        label: {
          'fr-FR': 'Texte',
          'nl-NL': 'Tekst'
        }
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'article.cta',
      content: {
        label: {
          'fr-FR': 'Bouton',
          'nl-NL': 'Knop'
        }
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'article.image',
      settings: {
        crop: false,
        size: { width: 545, height: 364 }
      },
      content: {
        label: {
          'fr-FR': 'Illustration',
          'nl-NL': 'Tekening'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'action.title',
      content: {
        label: {
          'fr-FR': 'Titre',
          'nl-NL': 'Title'
        }
      }
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'action.query',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
      },
      components: [
        {
          path: 'modules/pages/SectionSelectCampaigns',
          model: 'campaign_id',
          content: {
            label: i18n.getLocalesTranslations('literal.campaigns')
          }
        },
        {
          path: 'modules/pages/SectionSelect',
          model: 'sort',
          value: 'activity_last',
          settings: {
            options: projects_overview_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.sort_project_by')
          }
        },
        {
          path: 'modules/pages/SectionInputNumber',
          model: 'per_page',
          value: 4,
          content: {
            label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'action.cta',
      content: {
        label: {
          'fr-FR': 'Bouton',
          'nl-NL': 'Knop'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'more.title',
      content: {
        label: {
          'fr-FR': 'Titre',
          'nl-NL': 'Title'
        }
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'more.text',
      content: {
        label: {
          'fr-FR': 'Texte',
          'nl-NL': 'Tekst'
        }
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'more.image',
      settings: {
        crop: false,
        size: { width: 545, height: 364 }
      },
      content: {
        label: {
          'fr-FR': 'Illustration',
          'nl-NL': 'Tekening'
        }
      }
    }
  ],
  content: {
    title: {
      'fr-FR': 'MSV',
      'nl-NL': 'ASV'
    }
  }
}
