import i18n from '@/vendors/i18n'
import { metrics_type_options, metrics_sporting_options, projects_overview_options } from '@/configurations/general/components-default-data'

export default {
  name: 'custom_homepage_ihecs',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.banner')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'banner.slider',
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_image'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.image')
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          },
          model: 'image',
          settings: {
            crop: false,
            size: { width: 1600, height: 844 }
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'banner.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'banner.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'banner.button',
      setting: {
        hide_label: true
      },
      content: {
        label: i18n.getLocalesTranslations('literal.button')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.metrics')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'metrics',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'text',
        btn_label: i18n.getLocalesTranslations('literal.add_metric'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          value: 'contributors_count',
          settings: {
            options: metrics_type_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.metric')
          }
        },
        {
          path: 'modules/pages/SectionInputDate',
          model: 'date',
          content: {
            label: i18n.getLocalesTranslations('literal.date')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'date',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionSelect',
          model: 'sporting_statistics',
          value: 'total_distance',
          settings: {
            options: metrics_sporting_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.sporting_statistics')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'sporting_statistics',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text'
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.steps')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'steps.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'steps.text',
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'steps.items',
      content: {
        label: i18n.getLocalesTranslations('literal.steps'),
        btn_label: i18n.getLocalesTranslations('literal.add_step'),
        wrapper_title: 'title'
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'img',
          settings: {
            size: { width: 250, height: 250 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          settings: {
            configuration: ['bold', 'italic', 'underline']
          },
          content: {
            label: i18n.getLocalesTranslations('literal.step_description')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'steps.button',
      content: {
        label: i18n.getLocalesTranslations('literal.button'),
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.projects')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'projects.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'projects.button',
      content: {
        label: i18n.getLocalesTranslations('literal.button'),
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.ongoing_projects')
      }
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'projects.query_ongoing.campaign_id',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionSelect',
      model: 'projects.query_ongoing.sort',
      value: 'created_at_first',
      settings: {
        options: projects_overview_options
      },
      content: {
        label: i18n.getLocalesTranslations('literal.sort_project_by')
      }
    },
    {
      path: 'modules/pages/SectionInputNumber',
      model: 'projects.query_ongoing.per_page',
      value: 4,
      content: {
        label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.ended_projects')
      }
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'projects.query_ended.campaign_id',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionSelect',
      model: 'projects.query_ended.sort',
      value: 'created_at_first',
      settings: {
        options: projects_overview_options
      },
      content: {
        label: i18n.getLocalesTranslations('literal.sort_project_by')
      }
    },
    {
      path: 'modules/pages/SectionInputNumber',
      model: 'projects.query_ended.per_page',
      value: 4,
      content: {
        label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'projects.query_ended.button',
      content: {
        label: i18n.getLocalesTranslations('literal.button'),
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.create_project')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      content: {
        label: i18n.getLocalesTranslations('literal.image')
      },
      model: 'add_project.image',
      settings: {
        crop: true,
        size: { width: 1800, height: 844 }
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'add_project.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'add_project.button',
      content: {
        label: i18n.getLocalesTranslations('literal.button'),
      }
    },
  ],
  content: {
    title: {
      'en-GB': 'IHECS',
      'fr-FR': 'IHECS',
      'nl-NL': 'IHECS'
    }
  }
}
