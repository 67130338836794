import i18n from '@/vendors/i18n'

export default {
  name: 'transaction_payment_methods',
  model: 'actions_data.transaction.payment_method',
  type: 'input_radio',
  settings: {
    icon: 'payment_method',
    category: 'payments',
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_payment_methods')
  },
  components: [
    { path: 'modules/procedures/BlockPaymentProvider' },
    {
      path: 'molecules/ModuleText',
      content: {
        text: i18n.getLocalesTranslations('literal.select_payment_methods_you_want_in_your_form_and_order_them')
      }
    },
    { path: 'molecules/ModuleDivider' },
    {
      path: 'molecules/ModuleTabs',
      model: 'attributes.active_tab',
      value: 'one_time',
      options: 'transaction_frequency.attributes.options'
    },
    { path: 'modules/procedures/BlockPaymentMethods' }
  ],
  is_allowed: {
    'procedure.actions': ['transaction']
  },
  is_required: {
    'procedure.actions': ['transaction']
  }
}
