import Procedure from '@/classes/procedures/Procedure'
import Link from '@/classes/Link'

export default class Campaign {
  constructor ({ id, name, cta, learn_more, content, image, remote_image_url, description, procedures, emails, slug, status, end_date, start_date, projects, projects_count, projects_collected_amount, projects_contributor_count, created_at, project_display_settings, goal_amount_per_member, limit_of_members, limit_of_projects, sporting_activities_settings, settings, template_id } = {}) {
    this.id = id || null
    this.name = name || {}
    this.cta = new Link(cta)
    this.learn_more = new Link(learn_more)
    this.content = content || {}
    this.image = image || null
    this.remote_image_url = remote_image_url || null
    this.description = description || {}
    this.procedures = Array.isArray(procedures) ? procedures.map(procedure => new Procedure(procedure)) : []
    this.emails = emails || []
    this.slug = slug || null
    this.status = status || null
    this.end_date = end_date || null
    this.start_date = start_date || null
    this.projects = projects || []
    this.projects_count = projects_count || 0
    this.projects_collected_amount = projects_collected_amount || 0
    this.projects_contributor_count = projects_contributor_count || 0
    this.created_at = created_at || null
    this.project_display_settings = project_display_settings || {}
    this.goal_amount_per_member = goal_amount_per_member || 0
    this.limit_of_members = limit_of_members || 0
    this.limit_of_projects = limit_of_projects || 0
    this.sporting_activities_settings = sporting_activities_settings || {}
    this.settings = settings || {}
    this.template_id = template_id || null
  }

  get is_live () {
    return this.status === 'published'
  }

  get is_draft () {
    return this.status === 'draft'
  }
}
