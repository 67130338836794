import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_allezchantez',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Eerste kolom',
          'fr-FR': 'Première colonne',
          'en-GB': 'First column'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_1.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'column_1.text',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'link', 'align_left', 'align_center', 'align_right']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Tweede kolom',
          'fr-FR': 'Deuxième colonne',
          'en-GB': 'Second column'
        }
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'column_2.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'column_2.text',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'link', 'align_left', 'align_center', 'align_right']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Derde kolom',
          'fr-FR': 'Troisième colonne',
          'en-GB': 'Third column'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_3.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'column_3.text',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'link', 'align_left', 'align_center', 'align_right']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    }
  ]
}
