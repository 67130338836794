import i18n from '@/vendors/i18n'
import Section from '@/classes/procedures/Section'

const success = {
  name: 'success',
  attributes: {
    back_btn: i18n.getLocalesTranslations('$procedure_step.button_back'),
    next_btn: i18n.getLocalesTranslations('$procedure_step.button_next_to_website')
  },
  sections: [
    Section.set({ name: 'success' })
  ],
  static_options: {
    icon: 'task_checklist',
    is_not_deletable: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_step.success')
  }
}

export default success
