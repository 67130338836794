import i18n from '@/vendors/i18n'

export default [
  {
    name: 'sterren_kikirpa_banner',
    settings: {
      icon: 'image',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.background')
        },
        model: 'background',
        settings: {
          crop: false,
          size: { width: 1600, height: 9999 }
        }
      },
      {
        path: 'modules/pages/SectionInputUrl',
        content: {
          label: {
            'nl-NL': 'Video url (mp4)',
            'en-GB': 'Video link (mp4)',
            'fr-FR': 'Lien de votre vidéo (mp4)'
          }
        },
        model: 'video'
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.banner')
    }
  },
  {
    name: 'sterren_kikirpa_quotes',
    settings: {
      icon: 'quote',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'left',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.quote'),
          wrapper_title: 'name'
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 128, height: 128 }
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.quote')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'name',
            content: {
              label: i18n.getLocalesTranslations('literal.name')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'right',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.quote'),
          wrapper_title: 'name'
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 128, height: 128 }
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.quote')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'name',
            content: {
              label: i18n.getLocalesTranslations('literal.name')
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.quote')
    }
  },
  {
    name: 'sterren_kikirpa_dots',
    settings: {
      icon: 'flag',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'settings',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.campaign')
            },
            settings: {
              limit: 1
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'popup',
        content: {
          default_wrapper_title: {
            'fr-FR': 'Popup',
            'en-GB': 'Popup',
            'nl-NL': 'Popup'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 520, height: 242 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      }
    ],
    content: {
      title: {
        'fr-FR': 'Points intéractifs',
        'en-GB': 'Interactive dots',
        'nl-NL': 'Interactive dots'
      }
    }
  },
  {
    name: 'sterren_kikirpa_capsule',
    settings: {
      icon: 'banner',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionRepeater',
        model: 'slides',
        content: {
          label: {
            'fr-FR': 'Slides',
            'en-GB': 'Slides',
            'nl-NL': 'Slides',
          },
          wrapper_title: 'title',
          btn_label: {
            'fr-FR': 'Ajouter un slide',
            'en-GB': 'Add a slide',
            'nl-NL': 'Add a slide',
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.background')
            },
            model: 'background',
            settings: {
              crop: true,
              size: { width: 625, height: 625 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'amount',
            content: {
              label: i18n.getLocalesTranslations('literal.amount')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        },
        settings: {
          configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.button')
        }
      }
    ],
    content: {
      title: {
        'fr-FR': 'Capsule temporelle',
        'en-GB': 'Time capsule',
        'nl-NL': 'Tijdscapsule'
      }
    }
  }
]