import i18n from '@/vendors/i18n'
import { projects_overview_options } from '@/configurations/general/components-default-data'

export default [
  {
    name: 'homepage_unicefbelgique_banner',
    settings: {
      icon: 'image',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionSelect',
        model: 'type',
        value: 'slider',
        settings: {
          options: [
            { value: 'slider', label: i18n.getLocalesTranslations('literal.slider') },
            { value: 'video', label: i18n.getLocalesTranslations('literal.video') }
          ]
        },
        content: {
          label: i18n.getLocalesTranslations('literal.type')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'slides',
        content: {
          btn_label: i18n.getLocalesTranslations('literal.add_image'),
          default_wrapper_title: i18n.getLocalesTranslations('literal.image'),
          wrapper_title: 'title'
        },
        settings: {
          hide_label: true
        },
        conditions: [
          {
            logic_operator: 'and',
            model: 'type',
            value: 'slider',
            comparison_operator: 'eq'
          }
        ],
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 1600, height: 600 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline', 'text_color', 'link']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.call_to_action')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'video',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.video'),
        },
        components: [
          {
            path: 'modules/pages/SectionInputUrl',
            model: 'url',
            content: {
              label: i18n.getLocalesTranslations('literal.url')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline', 'text_color']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.call_to_action')
            }
          }
        ],
        conditions: [
          {
            logic_operator: 'and',
            model: 'type',
            value: 'video',
            comparison_operator: 'eq'
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.banner')
    }
  },
  {
    name: 'homepage_unicefbelgique_how_it_works',
    settings: {
      icon: 'message_cog',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'steps',
        content: {
          label: i18n.getLocalesTranslations('literal.steps'),
          btn_label: i18n.getLocalesTranslations('literal.add_step'),
          wrapper_title: 'title'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline', 'text_color']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.call_to_action')
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.how_it_works')
    }
  },
  {
    name: 'homepage_unicefbelgique_stats',
    settings: {
      icon: 'timer',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputTextarea',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        },
        settings: {
          variables: ['contributors_count', 'contributions_count', 'amount_collected', 'projects_count', 'project_members_count']
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'query',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_campaigns')
            }
          },
          {
            path: 'modules/pages/SectionSelectForms',
            model: 'procedure_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_forms')
            }
          },
          {
            path: 'modules/pages/SectionSelectBenefitings',
            model: 'benefiting_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_beneficiaries')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'subtitle',
        content: {
          label: i18n.getLocalesTranslations('literal.subtitle')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'metrics',
        content: {
          btn_label: i18n.getLocalesTranslations('literal.add_metric'),
          wrapper_title: 'text'
        },
        settings: {
          hide_label: true
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.icon')
            },
            model: 'icon',
            settings: {
              crop: true,
              size: { width: 100, height: 100 }
            }
          },
          {
            path: 'modules/pages/SectionInputTextarea',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              type: 'text',
              variables: ['malnutrition', 'water', 'vaccines']
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.metrics')
    }
  },
  {
    name: 'homepage_unicefbelgique_campaign',
    settings: {
      icon: 'flag',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionMedia',
        model: 'media',
        settings: {
          crop: true,
          size: { width: 658, height: 503 }
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'text_color', 'link']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.campaign')
    }
  },
  {
    name: 'homepage_unicefbelgique_actions',
    settings: {
      icon: 'markbook',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'query',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelect',
            model: 'sort',
            value: 'created_at_first',
            settings: {
              options: projects_overview_options
            },
            content: {
              label: i18n.getLocalesTranslations('literal.sort_project_by')
            }
          },
          {
            path: 'modules/pages/SectionInputNumber',
            model: 'per_page',
            value: 4,
            content: {
              label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
            }
          },
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.campaigns')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.projects')
    }
  },
  {
    name: 'homepage_unicefbelgique_testimonial',
    settings: {
      icon: 'quote',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'quote',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'text_color'],
          hide_label: true
        },
        content: {
          label: i18n.getLocalesTranslations('literal.quote')
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'author',
        content: {
          label: i18n.getLocalesTranslations('literal.author')
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'role',
        content: {
          label: i18n.getLocalesTranslations('literal.role')
        }
      },
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        },
        model: 'image',
        settings: {
          crop: true,
          size: { width: 427, height: 315 }
        }
      },
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.quote')
    }
  }
]