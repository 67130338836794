import Section from '@/classes/procedures/Section'
import procedureSteps from '@/configurations/procedures/steps'
import { clone } from '@/utils/utils'
import { merge as _merge } from 'lodash'

let Step = class Step {
  constructor ({ name, attributes, sections, static_options, content, allowed } = {}) {
    this.name = name || null
    this.attributes = attributes || {}
    this.sections = Array.isArray(sections) ? sections.map(section => new Section(section).static()) : []
    this.static_options = static_options || {}
    this.content = content || {}
    this.allowed = allowed || {}
  }

  disallowTransactionAction = (procedure) => {
    return !('transaction' in procedure.actions) && Array.isArray(this.allowed.actions) && this.allowed.actions.includes('transaction')
  }

  static set (obj) {
    let step = clone(procedureSteps.find(_step => _step.name === obj.name))
    step = _merge(step, obj)
    return new Step(step)
  }

  database = () => {
    return {
      name: this.name,
      attributes: this.attributes,
      sections: Array.isArray(this.sections) ? this.sections.map(section => new Section(section).database()) : []
    }
  }

  static = () => {
    let stepNormalized = clone(procedureSteps.find(step => step.name === this.name))
    if (stepNormalized) stepNormalized.sections = []
    return stepNormalized ? new Step(_merge(stepNormalized, this.database())) : this
  }
}

export default Step
