import i18n from '@/vendors/i18n'
import { metrics_sporting_options, metrics_type_options } from '@/configurations/general/components-default-data'

export default {
  name: 'custom_homepage_jessa',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.banner')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'banner.slider',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'title'
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'image',
          settings: {
            crop: false,
            size: { width: 1920, height: 550 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'banner.title',
      settings: {
        configuration: ['heading_h1', 'bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'banner.button',
      content: {
        label: i18n.getLocalesTranslations('literal.button')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'banner.metrics',
      content: {
        label: i18n.getLocalesTranslations('literal.metrics'),
        wrapper_title: 'text',
        btn_label: i18n.getLocalesTranslations('literal.add_metric'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          value: 'contributors_count',
          settings: {
            options: metrics_type_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.metric')
          }
        },
        {
          path: 'modules/pages/SectionInputDate',
          model: 'date',
          content: {
            label: i18n.getLocalesTranslations('literal.date')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'date',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionSelect',
          model: 'sporting_statistics',
          value: 'total_distance',
          settings: {
            options: metrics_sporting_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.sporting_statistics')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'sporting_statistics',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text'
        }
      ]
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'banner.navigation',
      content: {
        wrapper_title: 'link.text',
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'image',
          settings: {
            crop: false,
            size: { width: 50, height: 50 }
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'campaigns.title',
      settings: {
        configuration: ['heading_h2', 'bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'campaigns.description',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.description')
      }
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'campaigns.ids',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'campaigns.image',
      settings: {
        crop: false,
        size: { width: 500, height: 600 }
      },
      content: {
        label: i18n.getLocalesTranslations('literal.image')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'campaigns.email',
      content: {
        label: i18n.getLocalesTranslations('literal.email')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.contact')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'contact.title',
      settings: {
        configuration: ['heading_h2', 'bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'contact.description',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.description')
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.address')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'contact.address',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.address'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_line')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'line',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.bank_accounts')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'contact.bank',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.bank_accounts'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_line')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'line',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'contact.details',
      content: {
        label: i18n.getLocalesTranslations('literal.details')
      }
    },
  ],
  content: {
    title: {
      'en-GB': 'Jessa Fond',
      'fr-FR': 'Jessa fond',
      'nl-NL': 'Jessa fond'
    }
  }
}
