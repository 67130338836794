import i18n from '@/vendors/i18n'

export default {
  name: 'transaction_customer_email',
  model: 'customer.email',
  type: 'input_email',
  settings: {
    icon: 'input_email',
    category: 'personal_informations',
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_customer_email')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      value: i18n.getLocalesTranslations('$block_customer_email.label_email'),
      content: {
        label: i18n.getLocalesTranslations('literal.question')
      }
    },
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.placeholder',
      value: i18n.getLocalesTranslations('$block_customer_email.label_email'),
      content: {
        label: i18n.getLocalesTranslations('literal.placeholder')
      }
    }
  ],
  is_required: {
    'procedure.category': ['donation']
  }
}
