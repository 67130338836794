import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_paralympic',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'en-GB': 'Second column',
          'fr-FR': 'Deuxième colonne',
          'nl-NL': 'Tweede kolom kolom'
        }
      }
    },
    {
      path: 'modules/pages/SectionLogo'
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_2.name',
      content: {
        label: i18n.getLocalesTranslations('literal.name')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_2.infos',
      settings: {
        layout: 'inline'
      },
      content: {
        label: {
          'fr-FR': 'Adresse et compte bancaire',
          'en-GB': 'Addresses and bank account',
          'nl-NL': 'Adressen en bankrekeningen'
        },
        btn_label: i18n.getLocalesTranslations('literal.add_new_line')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'line',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_2.contact',
      content: {
        label: i18n.getLocalesTranslations('literal.contact'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_line'),
        wrapper_title: 'text'
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'prefix',
          settings: {
            hide_label: true
          }
        },
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          content: {
            label: i18n.getLocalesTranslations('literal.type')
          },
          value: 'text',
          settings: {
            hide_label: true,
            options: [
              {
                label: i18n.getLocalesTranslations('literal.text'),
                value: 'text'
              },
              {
                label: i18n.getLocalesTranslations('literal.website'),
                value: 'website'
              },
              {
                label: i18n.getLocalesTranslations('literal.email'),
                value: 'email'
              },
              {
                label: i18n.getLocalesTranslations('literal.phone_number'),
                value: 'phone_number'
              }
            ]
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'en-GB': 'Third column',
          'fr-FR': 'Troisième colonne',
          'nl-NL': 'Derde kolom kolom'
        }
      }
    },
    {
      path: 'modules/pages/SectionNetworks'
    }
  ]
}
