import i18n from '@/vendors/i18n'

export default {
  name: 'quote',
  settings: {
    icon: 'quote'
  },
  components: [
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'text_editor'
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'quote',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.quote')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'author_name',
      content: {
        label: i18n.getLocalesTranslations('literal.author_name')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'author_job',
      content: {
        label: i18n.getLocalesTranslations('literal.author_job_title')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'image',
      settings: {
        size: { width: 300, height: 300 }
      }
    }
  ],
  style: {
    background: {
      image: i18n.getLocalesTranslations(),
      color: null,
      type: 'color'
    },
    text: {
      color: null
    }
  },
  content: {
    title: i18n.getLocalesTranslations('literal.quote')
  }
}
