import i18n from '@/vendors/i18n'

const colors = [
  {
    label: i18n.t('@settings|branding|colors.label_primary_color'),
    value: 'prim'
  },
  {
    label: i18n.t('@settings|branding|colors.label_secondary_color'),
    value: 'sec'
  },
  {
    label: i18n.t('@settings|branding|colors.label_tertiary_color'),
    value: 'ter'
  }
]

export default {
  name: 'custom_header_fineartsmuseum',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.login_register_and_profile')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--24']
      }
    },
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.language_switch')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--24']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.logo')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'logo',
      settings: {
        crop: false,
        size: { width: 1600, height: 640 }
      },
      content: {
        label: { 'fr-FR': ' ', 'nl-NL': ' ', 'en-GB': ' ' }
      }
    },
    {
      path: 'modules/pages/SectionInputSwitch',
      model: 'is_search',
      content: {
        label: i18n.getLocalesTranslations('literal.search_field')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'search',
      content: {
        label: i18n.getLocalesTranslations('literal.search')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--24']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'links',
      settings: {
        hide_label: true,
        layout: 'inline'
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--24']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.action_button')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'actions',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'link.text',
        btn_label: i18n.getLocalesTranslations('literal.add_new_button')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        },
        {
          path: 'modules/pages/SectionSelect',
          model: 'color',
          value: 'prim',
          content: {
            label: i18n.getLocalesTranslations('literal.color')
          },
          settings: {
            options: colors
          }
        }
      ]
    }
  ]
}
