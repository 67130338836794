import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_lightfortheworld',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.newsletter')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'newsletter.text',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'link']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.logo')
      }
    },
    {
      path: 'modules/pages/SectionLogo'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.contact')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'contact.email_label',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'contact.email',
      content: {
        label: i18n.getLocalesTranslations('literal.email')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'contact.phone_number',
      content: {
        label: i18n.getLocalesTranslations('literal.phone_number')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'contact.faq',
      content: {
        label: i18n.getLocalesTranslations('literal.faq')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.social_media')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'social.text_1',
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionNetworks'
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'social.text_2',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    }
  ]
}
