import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_24htelevie',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.links')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_1.links',
      settings: {
        hide_label: true,
        layout: 'inline'
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionNetworks'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_2.navigation',
      settings: {
        hide_label: true,
        layout: 'inline'
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Information'
        }
      }
    },
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.login_register_and_profile')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'column_3.cta',
      content: {
        label: {
          'fr-FR': 'Appel à l\'action'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_3.organiser.title',
      content: {
        label: {
          'fr-FR': 'Organisation'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_3.organisers.logos',
      content: {
        wrapper_title: 'link.text',
        label: {
          'fr-FR': 'Logo des organisateurs'
        },
        btn_label: {
          'fr-FR': 'Ajouter un nouveau logo'
        }
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          content: {
            label: {
              'fr-FR': 'Lien'
            }
          }
        },
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'logo',
          settings: {
            crop: false,
            size: { width: 110, height: 9999 }
          }
        }
      ]
    }
  ]
}
