import i18n from '@/vendors/i18n'
import { metrics_type_options } from '@/configurations/general/components-default-data'

export default {
  name: 'custom_homepage_uzgent',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'hero.image',
      settings: {
        crop: false,
        size: { width: 1600, height: 611 }
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'no_metric.content'
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'metrics',
      content: {
        label: {
          'nl-NL': 'Statistieken'
        },
        wrapper_title: 'text',
        btn_label: i18n.getLocalesTranslations('literal.add_metric'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          value: 'contributors_count',
          settings: {
            options: metrics_type_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.metric')
          }
        },
        {
          path: 'modules/pages/SectionInputDate',
          model: 'date',
          content: {
            label: i18n.getLocalesTranslations('literal.date')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'date',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text'
        }
      ]
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'actions.cta_1'
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'actions.cta_2'
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'actions.cta_3'
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'about.image',
      settings: {
        crop: false,
        size: { width: 768, height: 893 }
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'about.content'
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'campaign.image',
      settings: {
        crop: false,
        size: { width: 768, height: 743 }
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'campaign.content'
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'campaign.cta'
    }
  ],
  content: {
    title: {
      'nl-NL': 'UZ Gent'
    }
  }
}
