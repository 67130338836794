export default [
  {
    key: 'procedures_how_to_get_started'
  },
  {
    key: 'procedures_what_is_the_email_section_and_how_to_start'
  },
  {
    key: 'procedures_can_i_change_the_sender_adress_of_the_automatic_emails'
  },
  {
    key: 'procedures_how_can_i_modify_the_confirmation_email_of_the_donation_contribution'
  },
  {
    key: 'procedures_what_are_products'
  },
  {
    key: 'procedures_how_can_i_use_products_in_my_forms'
  },
  {
    key: 'procedures_how_can_i_build_a_form'
  },
  {
    key: 'procedures_what_is_benefiting',
    is_allowed: { 'route.params.blockSlug': ['project_benefiting', 'project_benefiting[-0-9]+'] }
  },
  {
    key: 'procedures_where_can_i_find_the_title_on_my_form'
  },
  {
    key: 'procedures_how_do_i_setup_payment_settings_for_my_form'
  },
  {
    key: 'procedures_what_are_the_qr_codes_with_payconiq'
  },
  {
    key: 'procedures_how_to_use_the_payconiq_qr_codes'
  },
  {
    key: 'procedures_how_can_i_integrate_conditions_in_my_forms'
  }
]
