import Block from '@/classes/procedures/Block'
import procedureSections from '@/configurations/procedures/sections'
import { clone } from '@/utils/utils'
import { merge as _merge } from 'lodash'

let Section = class Section {
  constructor ({ name, blocks, options, content, static_options } = {}) {
    this.name = name || null
    this.blocks = Array.isArray(blocks) ? blocks.map(block => new Block(block).static()) : []
    this.options = options || {}
    this.content = content || {}
    this.static_options = static_options || {}
  }

  disallowTransactionAction = procedure => {
    return !('transaction' in procedure.actions) && this.blocks.some(block => block.requiresTransactionAction())
  }

  static set (obj) {
    let section = clone(procedureSections.find(_section => _section.name === obj.name))
    section = _merge(section, obj)
    return new Section(section)
  }

  certify = (attribute, models) => {
    if (typeof this[attribute] === 'boolean') return this[attribute]

    return this.blocks.some(block => block.certify('is_required', models))
  }

  database = () => {
    return {
      name: this.name,
      options: this.options,
      blocks: Array.isArray(this.blocks) ? this.blocks.map(block => new Block(block).database()) : []
    }
  }

  static = () => {
    let sectionNormalized = clone(procedureSections.find(section => section.name === this.name))
    if (sectionNormalized) sectionNormalized.blocks = []
    return sectionNormalized ? new Section(_merge(sectionNormalized, this.database())) : this
  }
}

export default Section
