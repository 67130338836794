import i18n from '@/vendors/i18n'

export default {
  name: 'image',
  settings: {
    icon: 'image',
    category: 'medias',
    is_reusable: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_image')
  },
  components: [
    {
      path: 'molecules/ModuleImage',
      model: 'attributes.image'
    }
  ]
}
