import i18n from '@/vendors/i18n'

export default [
  {
    name: 'custom_homepage_expeditienatuurpunt_banner',
    settings: {
      icon: 'video',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.background')
        },
        model: 'background',
        settings: {
          crop: false,
          size: { width: 1600, height: 9999 }
        }
      },
      {
        path: 'modules/pages/SectionInputUrl',
        content: {
          label: {
            'nl-NL': 'Video url for desktop (mp4)',
            'en-GB': 'Video link for desktop (mp4)',
            'fr-FR': 'Lien de votre vidéo grand format (mp4)'
          }
        },
        model: 'video_desktop'
      },
      {
        path: 'modules/pages/SectionInputUrl',
        content: {
          label: {
            'nl-NL': 'Video url for mobile (mp4)',
            'en-GB': 'Video link for mobile (mp4)',
            'fr-FR': 'Lien de votre vidéo petit format (mp4)'
          }
        },
        model: 'video_mobile'
      },
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.logo')
        },
        model: 'logo',
        settings: {
          crop: false,
          size: { width: 300, height: 9999 }
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'button',
        setting: {
          hide_label: true
        },
        content: {
          label: i18n.getLocalesTranslations('literal.button')
        }
      }
    ],
    content: {
      title: {
        'en-GB': 'Banner',
        'fr-FR': 'Banner',
        'nl-NL': 'Banner'
      }
    }
  },
  {
    name: 'custom_homepage_expeditienatuurpunt_description',
    settings: {
      icon: 'text',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'dates',
        content: {
          label: {
            'en-GB': 'Dates',
            'fr-FR': 'Dates',
            'nl-NL': 'Dates'
          }
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'left_text',
        settings: {
          configuration: ['bold', 'italic', 'underline']
        },
        content: {
          label: {
            'en-GB': 'Text on the left',
            'fr-FR': 'Text on the left',
            'nl-NL': 'Text on the left'
          }
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'middle_text',
        settings: {
          configuration: ['bold', 'italic', 'underline']
        },
        content: {
          label: {
            'en-GB': 'Text in the middle',
            'fr-FR': 'Text in the middle',
            'nl-NL': 'Text in the middle'
          }
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'right_text',
        settings: {
          configuration: ['bold', 'italic', 'underline']
        },
        content: {
          label: {
            'en-GB': 'Text on the right',
            'fr-FR': 'Text on the right',
            'nl-NL': 'Text on the right'
          }
        }
      }
    ],
    content: {
      title: {
        'en-GB': 'Baseline',
        'fr-FR': 'Baseline',
        'nl-NL': 'Baseline'
      }
    }
  },
  {
    name: 'custom_homepage_expeditienatuurpunt_infos',
    settings: {
      icon: 'location',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'left',
        content: {
          default_wrapper_title: {
            'fr-FR': 'Left side',
            'en-GB': 'Left side',
            'nl-NL': 'Left side'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.background')
            },
            model: 'background',
            settings: {
              crop: true,
              size: { width: 1500, height: 500 }
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'right',
        content: {
          default_wrapper_title: {
            'fr-FR': 'Right side',
            'en-GB': 'Right side',
            'nl-NL': 'Right side'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.background')
            },
            model: 'background',
            settings: {
              crop: true,
              size: { width: 1500, height: 500 }
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      }
    ],
    content: {
      title: {
        'en-GB': 'Informations',
        'fr-FR': 'Informations',
        'nl-NL': 'Informations'
      }
    }
  },
  {
    name: 'custom_homepage_expeditienatuurpunt_routes',
    settings: {
      icon: 'map',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.background')
        },
        model: 'background',
        settings: {
          crop: true,
          size: { width: 2500, height: 1000 }
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'button',
        content: {
          label: i18n.getLocalesTranslations('literal.button')
        }
      }
    ],
    content: {
      title: {
        'en-GB': 'Routes',
        'fr-FR': 'Routes',
        'nl-NL': 'Routes'
      }
    }
  },
  {
    name: 'custom_homepage_expeditienatuurpunt_social',
    settings: {
      icon: 'sharing-links',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionNetworks'
      }
    ],
    content: {
      title: {
        'en-GB': 'Social media',
        'fr-FR': 'Social media',
        'nl-NL': 'Social media'
      }
    }
  }
]