import i18n from '@/vendors/i18n'
import { metrics_type_options, projects_overview_options } from '@/configurations/general/components-default-data'

export default [
  {
    name: 'benefiting_jez_settings',
    settings: {
      icon: 'form',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionSelectProjects',
        model: 'project_id',
        content: {
          label: i18n.getLocalesTranslations('literal.project')
        },
        settings: {
          limit: 1
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.settings')
    }
  },
  {
    name: 'benefiting_jez_banner',
    settings: {
      icon: 'banner',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionButton',
        model: 'back_to',
        content: {
          label: {
            'nl-NL': 'Terug naar alle organisaties',
            'en-GB': 'Back to all organizations',
            'fr-FR': 'Retour à toutes les organisations'
          }
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.banner')
    }
  },
  {
    name: 'benefiting_jez_counter',
    settings: {
      icon: 'timer',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        content: {
          label: i18n.getLocalesTranslations('literal.metrics'),
          wrapper_title: 'text',
          btn_label: i18n.getLocalesTranslations('literal.add_metric'),
          default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
        },
        components: [
          {
            path: 'modules/pages/SectionSelect',
            model: 'type',
            value: 'contributors_count',
            settings: {
              options: metrics_type_options
            },
            content: {
              label: i18n.getLocalesTranslations('literal.metric')
            }
          },
          {
            path: 'modules/pages/SectionInputDate',
            model: 'date',
            content: {
              label: i18n.getLocalesTranslations('literal.date')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'date',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'text'
          }
        ]
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'start_action',
        content: {
          label: {
            'nl-NL': 'Start een actie',
            'en-GB': 'Start an action',
            'fr-FR': 'Lancer une action'
          }
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'support_us',
        content: {
          label: {
            'nl-NL': 'Steun ons',
            'en-GB': 'Support us',
            'fr-FR': 'Soutenez-nous'
          }
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.counter')
    }
  },
  {
    name: 'benefiting_jez_action',
    settings: {
      icon: 'markbook',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        model: 'image',
        settings: {
          crop: true,
          size: { width: 696, height: 464 }
        },
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        },
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link']
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'more_info',
        content: {
          label: {
            'nl-NL': 'Meer info',
            'en-GB': 'More info',
            'fr-FR': 'Plus d\'infos'
          }
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'support_us',
        content: {
          label: {
            'nl-NL': 'Steun ons',
            'en-GB': 'Support us',
            'fr-FR': 'Soutenez-nous'
          }
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.action')
    }
  },
  {
    name: 'benefiting_jez_actions',
    settings: {
      icon: 'markbook',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        },
        settings: {
          configuration: ['bold', 'italic', 'underline']
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'query',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelect',
            model: 'sort',
            value: 'created_at_first',
            settings: {
              options: projects_overview_options
            },
            content: {
              label: i18n.getLocalesTranslations('literal.sort_project_by')
            }
          },
          {
            path: 'modules/pages/SectionInputNumber',
            model: 'per_page',
            value: 4,
            content: {
              label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'support',
        content: {
          label: {
            'nl-NL': 'Steun',
            'en-GB': 'Support',
            'fr-FR': 'Soutenir'
          }
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'see_more',
        content: {
          label: i18n.getLocalesTranslations('literal.see_more_button')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.actions')
    }
  },
  {
    name: 'benefiting_jez_about',
    settings: {
      icon: 'flag',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        },
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link']
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'start_action',
        content: {
          label: {
            'nl-NL': 'Start een actie',
            'en-GB': 'Start an action',
            'fr-FR': 'Lancer une action'
          }
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.about')
    }
  },
  {
    name: 'benefiting_jez_ngo',
    settings: {
      icon: 'love_hand',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputUrl',
        model: 'video',
        content: {
          label: i18n.getLocalesTranslations('literal.video')
        }
      },
      {
        path: 'modules/pages/SectionImageUpload',
        model: 'image',
        settings: {
          crop: true,
          size: { width: 624, height: 350 }
        },
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        }
      },
      {
        path: 'modules/pages/SectionImageUpload',
        model: 'logo',
        settings: {
          crop: false,
          size: { width: 9999, height: 90 }
        },
        content: {
          label: i18n.getLocalesTranslations('literal.logo')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        },
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link']
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.benefiting')
    }
  }
]