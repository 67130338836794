import i18n from '@/vendors/i18n'

export default {
  name: 'informations_private_message',
  model: 'informations.private_message',
  type: 'input_text',
  settings: {
    icon: 'message_lock',
    category: 'form_fields',
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_informations_private_message')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      value: i18n.getLocalesTranslations('$block_message.label_private_message'),
      content: {
        label: i18n.getLocalesTranslations('literal.question')
      }
    },
    {
      path: 'molecules/ModuleInputTextarea',
      model: 'attributes.placeholder',
      value: i18n.getLocalesTranslations('$block_message.placeholder_private_message'),
      content: {
        label: i18n.getLocalesTranslations('literal.example')
      }
    },
    {
      path: 'molecules/ModuleInputCheckboxMandatory',
      value: 'required',
      content: {
        label: i18n.getLocalesTranslations('$procedure_block.label_is_mandatory')
      }
    }
  ]
}