<template>
  <transition name="btn">
    <button
      class="btn btn__color--sec"
      @click="show_faq = !show_faq"
      v-if="allowedQuestions.length > 0"
    >
      <transition name="btn">
        <span
          class="btn__icon"
          v-if="show_faq"
        >
          <fa-icon
            class="icon"
            :icon="['fal', 'xmark']"
          />
        </span>

        <span
          class="btn__icon"
          v-else
        >
          <fa-icon
            class="icon"
            :icon="['fal', 'circle-question']"
          />
        </span>
      </transition>
    </button>
  </transition>

  <transition name="faq">
    <div
      class="faq bg__color--white color--black"
      v-if="allowedQuestions.length > 0 && show_faq"
    >
      <div class="faq__header padding--18 margin__b--18 bg__color--sec color--white d-flex justify-content-between align-items-center">
        <h3 class="bold">
          <fa-icon
            class="icon margin__r--6"
            :icon="['fal', 'circle-question']"
          />

          {{ t('literal.help') }}
        </h3>

        <a
          href="https://faq.koalect.com"
          target="_blank"
          class="link__color--white"
        >
          {{ t('literal.check_our_faq') }}

          <fa-icon
            class="icon margin__l--6"
            :icon="['fal', 'external-link-square']"
          />
        </a>
      </div>

      <div class="faq__main padding__lr--18">
        <ul>
          <li
            class="faq__li"
            v-for="{ question, link } in allowedQuestions"
            :key="question"
          >
            <a
              :href="t(link)"
              target="_blank"
              class="d-block padding__tb--6 padding__lr--12 link__color--black-sec link--naked faq__question"
            >
              {{ t(question) }}
            </a>
          </li>
        </ul>
      </div>

      <p
        class="faq__footer margin__t--18"
        v-html="t('literal.need_help_send_us_an_email_to_x', { x: email })"
      />
    </div>
  </transition>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from '@/vendors/i18n'
import { useFaq } from '@/composables/app/useFaq'
import { isCertified } from '@/utils/utils'
import { get as _get } from 'lodash'

const route = useRoute()
const { t } = useI18n()
const { questions } = useFaq()

const show_faq = ref(false)
const email = '<a href="mailto:support@koalect.com" class="bold link__color--black-sec">support@koalect.com</a>'

const faq = computed(() => _get(route, 'meta.faq', []))
const allowedQuestions = computed(() => questions.value.filter(question => faq.value.includes(question.key) && isAllowed(question.is_allowed)))

const isAllowed = attributes => isCertified({ attributes, models: { route } })

watch(faq, () => show_faq.value = false)
</script>

<style scoped lang="scss">
.btn {
  width: 48px;
  height: 48px;
  position: fixed;
  right: 12px;
  bottom: 74px;
  border-radius: 50%;
  font-size: rem(24px);
  transform-origin: 50%;

  @include mq(sm) {
    bottom: 92px;
    right: 24px;
  }

  @media (min-width: 450px) {
    bottom: 92px;
  }

  @include mq(md) {
    bottom: 24px;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }

  &-enter-active {
    animation-name: grow;
    animation-duration: 0.7s;
  }

  &__icon {
    position: absolute;
    transform-origin: 50%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 48px;
    text-align: center;
  }
}

.faq {
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  right: 12px;
  bottom: 134px;
  background: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(82, 97, 115, 0.10);
  border-radius: 7px;
  width: 280px;
  max-width: 90vw;
  max-height: calc(90vh - 100px);
  transition: all $transition__duration;
  display: flex;
  flex-direction: column;

  @media (min-width: 450px) {
    bottom: 152px;
  }

  @include mq(sm) {
    bottom: 152px;
    right: 24px;
  }

  @include mq(md) {
    bottom: 90px;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
    transform: translateY(12px);
  }

  &__header {
    flex: 0;
  }

  &__main {
    max-height: 180px;
    position: relative;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    flex: 1 1 auto;
    scrollbar-color: $color--secondary $color--secondary;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 3px;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      background-color: $vdw;
    }

    &::-webkit-scrollbar-thumb {
      background: $color--secondary;
      border-radius: 2px;
    }
  }

  &__li {
    &:not(:last-child) {
      margin-bottom: $margin__base;
    }
  }

  &__question {
    border: 1px solid rgba(46, 41, 61, 0.10);
    border-radius: 4px;
    transition: all $transition__duration;
    word-break: break-word;

    &:hover {
      border-color: $color--secondary;
    }
  }

  &__footer {
    padding: $padding__base calc($padding__base * 1.5) calc($padding__base * 1.5);
    border-top: 1px solid $vdw;
  }
}
</style>