import { toValue } from 'vue'
import { set as _set, isObject as _isObject } from 'lodash'
import { format } from 'date-fns'
import { generateCsv, download, mkConfig } from 'export-to-csv'
import axios from '@/vendors/axios/axios'
import { getStringifyQuery, slugify } from '@/utils/utils'

export default function actionsStore ({ store }) {
  store.$fetchItems = ({ endpoint, resource, model, query }) => {
    if (['null', 'undefined'].some(value => endpoint.includes(value))) return Promise.reject()

    const q = getStringifyQuery({ query: toValue(query), default_value: '' })
    const key = q || 'default'

    _set(store.$state, `${resource}.['${key}'].loader`, true)
    _set(store.$state, `${resource}.['${key}'].error`, null)
    _set(store.$state, `${resource}.['${key}'].timestamp`, Date.now())

    return new Promise((resolve, reject) => {
      axios.get(endpoint + q)
        .then(response => {
          const paginator = response.data.paginator
          const items = response.data.data.map(item => model ? new model(item) : item)

          _set(store.$state, `${resource}.['${key}'].items`, items)
          if (paginator) _set(store.$state, `${resource}.['${key}'].pagination`, paginator)
          resolve(items)
        })
        .catch(error => {
          _set(store.$state, `${resource}.['${key}'].error`, error)
          reject(error)
        })
        .finally(() => _set(store.$state, `${resource}.['${key}'].loader`, false))
    })
  }

  store.$fetchItem = ({ endpoint, resource, model }) => {
    if (['null', 'undefined'].some(value => endpoint.includes(value))) return Promise.reject()

    if (resource) {
      _set(store.$state, `${resource}.loader`, true)
      _set(store.$state, `${resource}.error`, null)
    }

    return new Promise((resolve, reject) => {
      axios.get(endpoint)
        .then(response => {
          const item = model ? new model(response.data.data) : response.data.data
          if (resource) _set(store.$state, `${resource}.item`, item)
          resolve(item)
        })
        .catch(error => {
          if (resource) _set(store.$state, `${resource}.error`, error)
          reject(error)
        })
        .finally(() => {
          if (resource) _set(store.$state, `${resource}.loader`, false)
        })
    })
  }

  store.$postItem = ({ endpoint, resource, model, params, config = {} }) => {
    if (['null', 'undefined'].some(value => endpoint.includes(value))) return Promise.reject()

    _set(store.$state, 'loader', true)
    _set(store.$state, 'error', null)

    return new Promise((resolve, reject) => {
      axios.post(endpoint, params, config)
        .then(response => {
          const item = model ? new model(response.data.data) : response.data.data
          const regex = /(\w+)\.\{([\w-]+)\}/
          const [, attribute, key] = regex.exec(resource) || []
          const id = key && item[key] ? `${attribute}.${item[key]}` : resource
          if (id) _set(store.$state, `${id}.item`, item)
          resolve(item)
        })
        .catch(error => {
          _set(store.$state, 'error', error)
          reject(error)
        })
        .finally(() => _set(store.$state, 'loader', false))
    })
  }

  store.$postFile = ({ endpoint, params }) => {
    if (['null', 'undefined'].some(value => endpoint.includes(value))) return Promise.reject()

    _set(store.$state, 'loader', true)
    _set(store.$state, 'error', null)

    return new Promise((resolve, reject) => {
      axios.post(endpoint, { filename: params.name })
        .then(response => {
          axios.put(response.data.data.upload_url, params.binary, {
            headers: { 'Content-Type': params.type },
            transformRequest: [(data, headers) => {
              delete headers.Authorization
              return data
            }]
          })
            .then(() => resolve(response.data.data.file_url))
            .catch(error => {
              _set(store.$state, 'error', error)
              reject(error)
            })
            .finally(() => _set(store.$state, 'loader', false))
        })
        .catch(error => {
          _set(store.$state, 'error', error)
          reject(error)
        })
    })
  }

  store.$postItems = ({ endpoint, resource, model, params, query, config = {} }) => {
    if (['null', 'undefined'].some(value => endpoint.includes(value))) return Promise.reject()

    const q = getStringifyQuery({ query: toValue(query), default_value: '' })
    const key = q || 'default'

    if (resource) {
      _set(store.$state, `${resource}.['${key}'].loader`, true)
      _set(store.$state, `${resource}.['${key}'].error`, null)
    }

    return new Promise((resolve, reject) => {
      axios.post(endpoint, params, config)
        .then(response => {
          const items = response.data.data.map(item => model ? new model(item) : item)

          if (resource) _set(store.$state, `${resource}.['${key}'].items`, items)
          resolve(items)
        })
        .catch(error => {
          if (resource) _set(store.$state, `${resource}.['${key}'].error`, error)
          reject(error)
        })
        .finally(() => {
          if (resource) _set(store.$state, `${resource}.['${key}'].loader`, false)
        })
    })
  }

  store.$patchItem = ({ endpoint, resource, model, params, config = {} }) => {
    if (['null', 'undefined'].some(value => endpoint.includes(value))) return Promise.reject()

    if (resource) {
      _set(store.$state, `${resource}.loader`, true)
      _set(store.$state, `${resource}.error`, null)
    }

    return new Promise((resolve, reject) => {
      axios.patch(endpoint, params, config)
        .then(response => {
          const item = model ? new model(response.data.data) : response.data.data
          if (resource) _set(store.$state, `${resource}.item`, item)
          resolve(item)
        })
        .catch(error => {
          if (resource) _set(store.$state, `${resource}.error`, error)
          reject(error)
        })
        .finally(() => {
          if (resource) _set(store.$state, `${resource}.loader`, false)
        })
    })
  }

  store.$deleteItem = ({ endpoint, resource, query }) => {
    if (['null', 'undefined'].some(value => endpoint.includes(value))) return Promise.reject()

    const q = getStringifyQuery({ query: toValue(query), default_value: '' })

    _set(store.$state, 'loader', true)
    _set(store.$state, 'error', null)

    return new Promise((resolve, reject) => {
      axios.delete(endpoint + q)
        .then(response => {
          if (resource) {
            Object.keys(store.$state).forEach(attribute => {
              const state = store.$state[attribute]
              if (_isObject(state) && (resource in state)) delete state[resource]
            })
          }
          resolve(response.data.data)
        })
        .catch(error => {
          _set(store.$state, 'error', error)
          reject(error)
        })
        .finally(() => _set(store.$state, 'loader', false))
    })
  }

  store.$fetchExport = ({ endpoint, filename }) => {
    if (['null', 'undefined'].some(value => endpoint.includes(value))) return Promise.reject()

    _set(store.$state, 'loader', true)
    _set(store.$state, 'error', null)

    return new Promise((resolve, reject) => {
      axios.get(endpoint)
        .then(response => {
          if (!response.data.length) return resolve()

          const csvConfig = mkConfig({ fieldSeparator: ';', useKeysAsHeaders: true, filename: slugify(filename), title: filename })

          download(csvConfig)('\ufeff' + response.data)

          resolve(response.data)
        })
        .catch(error => {
          _set(store.$state, 'error', error)
          reject(error)
        })
        .finally(() => _set(store.$state, 'loader', false))
    })
  }

  store.$postExport = ({ endpoint, query, name, config = {}, async = false }) => {
    if (['null', 'undefined'].some(value => endpoint.includes(value))) return Promise.reject()

    const q = getStringifyQuery({ query: { ...query, async } })
    _set(store.$state, 'loader', true)
    _set(store.$state, 'error', null)

    return new Promise((resolve, reject) => {
      axios.post(endpoint + q)
        .then(response => {
          if (async || !response.data.length) return resolve()

          const filename = `${name}-${format(new Date(), 'dd-MM-yyyy-HH-mm')}`
          const csvConfig = mkConfig({ fieldSeparator: ';', useKeysAsHeaders: true, filename: slugify(filename), title: filename, ...config })
          const csv = generateCsv(csvConfig)(response.data)

          download(csvConfig)(csv)

          resolve(response.data)
        })
        .catch(error => {
          _set(store.$state, 'error', error)
          reject(error)
        })
        .finally(() => _set(store.$state, 'loader', false))
    })
  }
}