import i18n from '@/vendors/i18n'
import Block from '@/classes/procedures/Block'

export default {
  name: 'order_products',
  content: {
    title: i18n.getLocalesTranslations('$procedure_section.type_order_products'),
    description: i18n.getLocalesTranslations('$procedure_section.type_order_products_description')
  },
  options: {
    collapse: false
  },
  static_options: {
    is_reusable: true
  },
  blocks: [
    Block.set({
      name: 'title',
      attributes: {
        title: i18n.getLocalesTranslations('$procedure_block.order_products_title')
      }
    }),
    Block.set({
      name: 'text',
      attributes: {
        editor_content: i18n.getLocalesTranslations('$procedure_block.order_products_text')
      }
    }),
    Block.set({ name: 'product_selection' })
  ]
}
