import i18n from '@/vendors/i18n'
import Block from '@/classes/procedures/Block'

export default {
  name: 'transaction_customer_address',
  content: {
    title: i18n.getLocalesTranslations('$procedure_section.type_customer_address'),
    description: i18n.getLocalesTranslations('$procedure_section.type_customer_address_description')
  },
  options: {
    collapse: false
  },
  blocks: [
    Block.set({
      name: 'title',
      attributes: {
        title: i18n.getLocalesTranslations('$procedure_block.address_title')
      }
    }),
    Block.set({ name: 'transaction_customer_address_line1' }),
    Block.set({ name: 'transaction_customer_address_line2' }),
    Block.set({ name: 'transaction_customer_address_postal_code' }),
    Block.set({ name: 'transaction_customer_address_city' }),
    Block.set({ name: 'transaction_customer_address_country' })
  ]
}
