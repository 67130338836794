import i18n from '@/vendors/i18n'
import socialNetworks from '@/configurations/general/social-networks'
import { projects_overview_options } from '@/configurations/general/components-default-data'

const cta = [
  {
    path: 'modules/pages/SectionButton',
    model: 'link',
    settings: {
      hide_label: true
    }
  },
  {
    path: 'modules/pages/SectionSelect',
    model: 'color',
    value: 'prim',
    content: {
      label: i18n.getLocalesTranslations('literal.color')
    },
    settings: {
      options: [
        { value: 'prim', label: i18n.t('@settings|branding|colors.label_primary_color') },
        { value: 'sec', label: i18n.t('@settings|branding|colors.label_secondary_color') },
        { value: 'ter', label: i18n.t('@settings|branding|colors.label_tertiary_color') }
      ]
    }
  },
  {
    path: 'modules/pages/SectionSelect',
    model: 'type',
    value: 'color',
    content: {
      label: i18n.getLocalesTranslations('literal.type')
    },
    settings: {
      options: [
        { value: 'color', label: { 'fr-FR': 'Classique', 'nl-NL': 'Klassiek', 'en-GB': 'Classic' } },
        { value: 'solid', label: { 'fr-FR': 'Contour', 'nl-NL': 'Omlijning', 'en-GB': 'Outline' } }
      ]
    }
  }
]

const rooms = [
  { value: 1, label: { 'fr-FR': 'Espace de production Les Pots de L\'Ilot', 'nl-NL': 'Espace de production Les Pots de L\'Ilot', 'en-GB': 'Espace de production Les Pots de L\'Ilot' } },
  { value: 2, label: { 'fr-FR': 'Cuisine espace plonge', 'nl-NL': 'Cuisine espace plonge', 'en-GB': 'Cuisine espace plonge' } },
  { value: 3, label: { 'fr-FR': 'Douches', 'nl-NL': 'Douches', 'en-GB': 'Douches' } },
  { value: 4, label: { 'fr-FR': 'Sanitaires WC', 'nl-NL': 'Sanitaires WC', 'en-GB': 'Sanitaires WC' } },
  { value: 5, label: { 'fr-FR': 'Restaurant social', 'nl-NL': 'Restaurant social', 'en-GB': 'Restaurant social' } },
  { value: 6, label: { 'fr-FR': 'Comptoir de service (Restaurant)', 'nl-NL': 'Comptoir de service (Restaurant)', 'en-GB': 'Comptoir de service (Restaurant)' } },
  { value: 7, label: { 'fr-FR': 'Consignes', 'nl-NL': 'Consignes', 'en-GB': 'Consignes' } },
  { value: 8, label: { 'fr-FR': 'Laverie', 'nl-NL': 'Laverie', 'en-GB': 'Laverie' } },
  { value: 9, label: { 'fr-FR': 'Bureaux d\'accompagnement psychosocial 1', 'nl-NL': 'Bureaux d\'accompagnement psychosocial 1', 'en-GB': 'Bureaux d\'accompagnement psychosocial 1' } },
  { value: 10, label: { 'fr-FR': 'Bureaux d\'accompagnement psychosocial 2', 'nl-NL': 'Bureaux d\'accompagnement psychosocial 2', 'en-GB': 'Bureaux d\'accompagnement psychosocial 2' } },
  { value: 11, label: { 'fr-FR': 'Accès internet pour les usagers', 'nl-NL': 'Accès internet pour les usagers', 'en-GB': 'Accès internet pour les usagers' } }
]

export default [
  {
    name: 'crowdfunding_ilot_banner',
    settings: {
      icon: 'image',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'heading_h1', 'heading_h2']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'social_networks',
        settings: {},
        content: {
          label: i18n.getLocalesTranslations('literal.social_networks'),
          btn_label: i18n.getLocalesTranslations('literal.add_social_link'),
          wrapper_title: 'url',
        },
        components: [
          {
            path: 'modules/pages/SectionSelect',
            model: 'network',
            settings: {
              options: socialNetworks.map(network => ({ value: network.icon[1].replace('facebook-square', 'facebook-f').replace('linkedin', 'linkedin-in'), label: network.text }))
            },
            content: {
              label: i18n.getLocalesTranslations('literal.type'),
            }
          },
          {
            path: 'modules/pages/SectionInputUrl',
            settings: {
              hide_label: true
            },
            content: {
              label: i18n.getLocalesTranslations('literal.url')
            },
            model: 'url'
          }
        ]
      },
      {
        path: 'modules/pages/SectionDivider',
        style: {
          divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--24']
        }
      },
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        },
        model: 'image.src',
        settings: {
          crop: false,
          size: { width: 530, height: 9999 }
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'image.alt',
        content: {
          label: i18n.getLocalesTranslations('literal.description')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.banner')
    }
  },
  {
    name: 'crowdfunding_ilot_building',
    settings: {
      icon: 'location',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionSelectCampaigns',
        model: 'campaign_id',
        content: {
          label: i18n.getLocalesTranslations('literal.campaign')
        },
        settings: {
          limit: 1
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'legend',
        content: {
          default_wrapper_title: {
            'fr-FR': 'Légende',
            'nl-NL': 'Legende',
            'en-GB': 'Legend'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'already_funded',
            content: {
              label: {
                'fr-FR': 'Déjà financé',
                'nl-NL': 'Reeds gefinancierd',
                'en-GB': 'Already funded'
              }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'to_funded',
            content: {
              label: {
                'fr-FR': 'À financé',
                'nl-NL': 'Naar gefinancierd',
                'en-GB': 'To funded'
              }
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'rooms',
        content: {
          label: { 'fr-FR': 'Pièces', 'nl-NL': 'Kamers', 'en-GB': 'Rooms' }
        },
        components: [
          {
            path: 'modules/pages/SectionSelect',
            model: 'room_id',
            settings: {
              options: rooms
            },
            content: {
              label: { 'fr-FR': 'Pièce', 'nl-NL': 'Kamer', 'en-GB': 'Room' }
            }
          },
          {
            path: 'modules/pages/SectionSelectProjects',
            model: 'project_id',
            content: {
              label: i18n.getLocalesTranslations('literal.project')
            },
            settings: {
              limit: 1
            }
          }
        ]
      }
    ],
    content: {
      title: { 'fr-FR': 'Bâtiment', 'nl-NL': 'Gebouw', 'en-GB': 'Building' }
    }
  },
  {
    name: 'crowdfunding_ilot_campaign',
    settings: {
      icon: 'flag',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'heading_h2', 'link']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'cta',
        settings: {
          hide_label: true
        },
        content: {
          wrapper_title: 'link.text',
          btn_label: i18n.getLocalesTranslations('literal.add_new_button')
        },
        components: cta
      },
      {
        path: 'modules/pages/SectionDivider',
        style: {
          divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--24']
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'goal',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.goal')
        },
        components: [
          {
            path: 'modules/pages/SectionInputDate',
            model: 'end_date',
            content: {
              label: i18n.getLocalesTranslations('literal.end_date')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'time_left',
            content: {
              label: { 'fr-FR': 'Temps restant', 'nl-NL': 'Time left', 'en-GB': 'Tijd over' }
            }
          },
          {
            path: 'modules/pages/SectionInputNumber',
            model: 'goal_1',
            content: {
              label: { 'fr-FR': 'Objectif - 1 (k)', 'en-GB': 'Goal - 1 (k)', 'nl-NL': 'Doelstelling - 1 (k)' }
            }
          },
          {
            path: 'modules/pages/SectionInputNumber',
            model: 'goal_2',
            content: {
              label: { 'fr-FR': 'Objectif - 2 (k)', 'en-GB': 'Goal - 2 (k)', 'nl-NL': 'Doelstelling - 2 (k)' }
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'metric',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.metric')
        },
        components: [
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_campaigns')
            }
          },
          {
            path: 'modules/pages/SectionSelectForms',
            model: 'procedure_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_forms')
            }
          }
        ]
      },
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.campaign')
    }
  },
  {
    name: 'crowdfunding_ilot_projects',
    settings: {
      icon: 'markbook',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'query',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.campaigns')
            }
          },
          {
            path: 'modules/pages/SectionSelect',
            model: 'sort',
            value: 'activity_last',
            settings: {
              options: projects_overview_options
            },
            content: {
              label: i18n.getLocalesTranslations('literal.sort_project_by')
            }
          },
          {
            path: 'modules/pages/SectionInputNumber',
            model: 'per_page',
            value: 4,
            content: {
              label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'cta',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.button')
        },
        components: cta
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.projects')
    }
  },
  {
    name: 'crowdfunding_ilot_howitworks',
    settings: {
      icon: 'message_cog',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'steps',
        settings: {
          hide_label: true
        },
        content: {
          wrapper_title: 'link.text',
          label: i18n.getLocalesTranslations('literal.steps'),
          btn_label: i18n.getLocalesTranslations('literal.add_step')
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'icon',
            settings: {
              size: { width: 9999, height: 68 }
            },
            content: {
              label: i18n.getLocalesTranslations('literal.icon')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'content',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.content')
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'link',
            content: {
              label: i18n.getLocalesTranslations('literal.link')
            }
          }
        ]
      },
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.how_it_works')
    }
  },
  {
    name: 'crowdfunding_ilot_testimonials',
    settings: {
      icon: 'quote',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionInputUrl',
        model: 'video',
        content: {
          label: i18n.getLocalesTranslations('literal.video')
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'cta',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.button')
        },
        components: cta
      }
    ],
    content: {
      title: { 'fr-FR': 'Témoignages', 'nl-NL': 'Getuigenissen', 'en-GB': 'Testimonials' }
    }
  },
  {
    name: 'crowdfunding_ilot_about',
    settings: {
      icon: 'text_editor',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        },
        model: 'image',
        settings: {
          crop: true,
          size: { width: 650, height: 557 }
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'cta',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.button')
        },
        components: cta
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.about')
    }
  },
  {
    name: 'crowdfunding_ilot_partners',
    settings: {
      icon: 'sponsor',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'sponsors',
        settings: {},
        content: {
          label: i18n.getLocalesTranslations('literal.sponsors'),
          wrapper_title: 'name',
          btn_label: i18n.getLocalesTranslations('literal.add_new_sponsor'),
          default_wrapper_title: i18n.getLocalesTranslations('literal.sponsors')
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'logo',
            settings: {
              crop: false,
              size: { width: 9999, height: 98 }
            },
            content: {
              label: i18n.getLocalesTranslations('literal.logo')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'name',
            content: {
              label: i18n.getLocalesTranslations('literal.name')
            }
          },
          {
            path: 'modules/pages/SectionInputUrl',
            content: {
              label: i18n.getLocalesTranslations('literal.url')
            },
            model: 'url'
          }
        ]
      },
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.partners')
    }
  }
]