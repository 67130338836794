import i18n from '@/vendors/i18n'
import { projects_overview_options } from '@/configurations/general/components-default-data'

export default [
  {
    name: 'homepage_kikirpa_banner',
    settings: {
      icon: 'image',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.background')
        },
        model: 'background',
        settings: {
          crop: false,
          size: { width: 1600, height: 9999 }
        }
      },
      {
        path: 'modules/pages/SectionInputUrl',
        content: {
          label: {
            'nl-NL': 'Video url (mp4)',
            'en-GB': 'Video link (mp4)',
            'fr-FR': 'Lien de votre vidéo (mp4)'
          }
        },
        model: 'video'
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.banner')
    }
  },
  {
    name: 'homepage_kikirpa_stats',
    settings: {
      icon: 'timer',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionSelectCampaigns',
        model: 'campaign_id',
        content: {
          label: {
            'fr-FR': 'Limiter les montants collectés à ces campagnes',
            'en-GB': 'Limit collected amount to those campaigns',
            'nl-NL': 'Limit collected amount to those campaigns'
          }
        }
      },
      {
        path: 'modules/pages/SectionSelectForms',
        model: 'procedure_id',
        content: {
          label: {
            'fr-FR': 'Limiter les montants collectés à ces formulaires',
            'en-GB': 'Limit collected amount to those forms',
            'nl-NL': 'Limit collected amount to those forms'
          }
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.metrics')
    }
  },
  {
    name: 'homepage_kikirpa_quotes',
    settings: {
      icon: 'quote',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'left',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.quote'),
          wrapper_title: 'name'
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 128, height: 128 }
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.quote')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'name',
            content: {
              label: i18n.getLocalesTranslations('literal.name')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'right',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.quote'),
          wrapper_title: 'name'
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 128, height: 128 }
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.quote')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'name',
            content: {
              label: i18n.getLocalesTranslations('literal.name')
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.quote')
    }
  },
  {
    name: 'homepage_kikirpa_dots',
    settings: {
      icon: 'flag',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'dot_1',
        content: {
          default_wrapper_title: {
            'fr-FR': '#1',
            'en-GB': '#1',
            'nl-NL': '#1'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 520, height: 242 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'dot_2',
        content: {
          default_wrapper_title: {
            'fr-FR': '#2',
            'en-GB': '#2',
            'nl-NL': '#2'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 520, height: 242 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'dot_3',
        content: {
          default_wrapper_title: {
            'fr-FR': '#3',
            'en-GB': '#3',
            'nl-NL': '#3'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 520, height: 242 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'dot_4',
        content: {
          default_wrapper_title: {
            'fr-FR': '#4',
            'en-GB': '#4',
            'nl-NL': '#4'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 520, height: 242 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'dot_5',
        content: {
          default_wrapper_title: {
            'fr-FR': '#5',
            'en-GB': '#5',
            'nl-NL': '#5'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 520, height: 242 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'dot_6',
        content: {
          default_wrapper_title: {
            'fr-FR': '#6',
            'en-GB': '#6',
            'nl-NL': '#6'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 520, height: 242 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'dot_7',
        content: {
          default_wrapper_title: {
            'fr-FR': '#7',
            'en-GB': '#7',
            'nl-NL': '#7'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 520, height: 242 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'dot_8',
        content: {
          default_wrapper_title: {
            'fr-FR': '#8',
            'en-GB': '#8',
            'nl-NL': '#8'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 520, height: 242 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'dot_9',
        content: {
          default_wrapper_title: {
            'fr-FR': '#9',
            'en-GB': '#9',
            'nl-NL': '#9'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 520, height: 242 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'dot_10',
        content: {
          default_wrapper_title: {
            'fr-FR': '#10',
            'en-GB': '#10',
            'nl-NL': '#10'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 520, height: 242 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'dot_11',
        content: {
          default_wrapper_title: {
            'fr-FR': '#11',
            'en-GB': '#11',
            'nl-NL': '#11'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 520, height: 242 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'dot_12',
        content: {
          default_wrapper_title: {
            'fr-FR': '#12',
            'en-GB': '#12',
            'nl-NL': '#12'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 520, height: 242 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'dot_13',
        content: {
          default_wrapper_title: {
            'fr-FR': '#13',
            'en-GB': '#13',
            'nl-NL': '#13'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 520, height: 242 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'dot_14',
        content: {
          default_wrapper_title: {
            'fr-FR': '#14',
            'en-GB': '#14',
            'nl-NL': '#14'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 520, height: 242 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'dot_15',
        content: {
          default_wrapper_title: {
            'fr-FR': '#15',
            'en-GB': '#15',
            'nl-NL': '#15'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 520, height: 242 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'dot_16',
        content: {
          default_wrapper_title: {
            'fr-FR': '#16',
            'en-GB': '#16',
            'nl-NL': '#16'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 520, height: 242 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      }
    ],
    content: {
      title: {
        'fr-FR': 'Points intéractifs',
        'en-GB': 'Interactive dots',
        'nl-NL': 'Interactive dots'
      }
    }
  },
  {
    name: 'homepage_kikirpa_events',
    settings: {
      icon: 'newsfeed',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        settings: {
          hide_label: true
        },
        content: {
          label: {
            'fr-FR': 'Événements',
            'en-GB': 'Events',
            'nl-NL': 'Events'
          },
          btn_label: {
            'fr-FR': 'Ajouter un événement',
            'en-GB': 'Add an event',
            'nl-NL': 'Add an event',
          },
          wrapper_title: 'title'
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 408, height: 228 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'link',
            content: {
              label: i18n.getLocalesTranslations('literal.link')
            }
          }
        ]
      }
    ],
    content: {
      title: {
        'fr-FR': 'Événements',
        'en-GB': 'Events',
        'nl-NL': 'Events'
      }
    }
  },
  {
    name: 'homepage_kikirpa_projects',
    settings: {
      icon: 'markbook',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionSelectCampaigns',
        model: 'query.campaign_id',
        content: {
          label: i18n.getLocalesTranslations('literal.campaigns')
        }
      },
      {
        path: 'modules/pages/SectionSelect',
        model: 'query.sort',
        value: 'created_at_first',
        settings: {
          options: projects_overview_options
        },
        content: {
          label: i18n.getLocalesTranslations('literal.sort_project_by')
        }
      },
      {
        path: 'modules/pages/SectionInputNumber',
        model: 'query.per_page',
        value: 4,
        content: {
          label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.button')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.projects')
    }
  },
  {
    name: 'homepage_kikirpa_challenge',
    settings: {
      icon: 'text',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        },
        settings: {
          configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'big_beer_title',
        content: {
          label: {
            'fr-FR': 'Big beer title',
            'en-GB': 'Big beer title',
            'nl-NL': 'Big beer title'
          }
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'small_beer_title',
        content: {
          label: {
            'fr-FR': 'Small beer title',
            'en-GB': 'Small beer title',
            'nl-NL': 'Small beer title'
          }
        }
      }
    ],
    content: {
      title: {
        'fr-FR': 'Challenge',
        'en-GB': 'Challenge',
        'nl-NL': 'Challenge'
      }
    }
  },
  {
    name: 'homepage_kikirpa_capsule',
    settings: {
      icon: 'banner',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionRepeater',
        model: 'slides',
        content: {
          label: {
            'fr-FR': 'Slides',
            'en-GB': 'Slides',
            'nl-NL': 'Slides',
          },
          wrapper_title: 'title',
          btn_label: {
            'fr-FR': 'Ajouter un slide',
            'en-GB': 'Add a slide',
            'nl-NL': 'Add a slide',
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.background')
            },
            model: 'background',
            settings: {
              crop: true,
              size: { width: 625, height: 625 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'amount',
            content: {
              label: i18n.getLocalesTranslations('literal.amount')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        },
        settings: {
          configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.button')
        }
      }
    ],
    content: {
      title: {
        'fr-FR': 'Capsule temporelle',
        'en-GB': 'Time capsule',
        'nl-NL': 'Tijdscapsule'
      }
    }
  },
  {
    name: 'homepage_kikirpa_timeline',
    settings: {
      icon: 'banner',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'news',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.news'),
          wrapper_title: 'title'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'zomer_2022',
        content: {
          default_wrapper_title: {
            'fr-FR': 'Zomer 2022',
            'en-GB': 'Zomer 2022',
            'nl-NL': 'Zomer 2022'
          },
          wrapper_title: 'title'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 137, height: 154 }
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'herfst_2022',
        content: {
          default_wrapper_title: {
            'fr-FR': 'Herfst 2022',
            'en-GB': 'Herfst 2022',
            'nl-NL': 'Herfst 2022'
          },
          wrapper_title: 'title'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'lente_2023',
        content: {
          default_wrapper_title: {
            'fr-FR': 'Lente 2023',
            'en-GB': 'Lente 2023',
            'nl-NL': 'Lente 2023'
          },
          wrapper_title: 'title'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 165, height: 154 }
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'herfst_2024',
        content: {
          default_wrapper_title: {
            'fr-FR': 'Herfst 2024',
            'en-GB': 'Herfst 2024',
            'nl-NL': 'Herfst 2024'
          },
          wrapper_title: 'title'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 137, height: 154 }
            }
          }
        ]
      }
    ],
    content: {
      title: {
        'fr-FR': 'Timeline',
        'en-GB': 'Timeline',
        'nl-NL': 'Tijdslijn'
      }
    }
  },
  {
    name: 'homepage_kikirpa_partners',
    settings: {
      icon: 'sponsor',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'founding',
        content: {
          default_wrapper_title: {
            'fr-FR': 'Founding Partner',
            'en-GB': 'Founding Partner',
            'nl-NL': 'Founding Partner'
          },
          wrapper_title: 'title'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionRepeater',
            model: 'images',
            settings: {
              layout: 'inline'
            },
            content: {
              label: {
                'fr-FR': 'Logos',
                'en-GB': 'Logos',
                'nl-NL': 'Logos',
              },
              btn_label: {
                'fr-FR': 'Ajouter un logo',
                'en-GB': 'Add a logo',
                'nl-NL': 'Add a logo',
              },
            },
            components: [
              {
                path: 'modules/pages/SectionImageUpload',
                content: {
                  label: i18n.getLocalesTranslations('literal.logo')
                },
                model: 'logo',
                settings: {
                  hide_label: true,
                  crop: false,
                  size: { width: 270, height: 9999 }
                }
              }
            ]
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'marketing',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.partners'),
          wrapper_title: 'title'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionRepeater',
            model: 'images',
            settings: {
              layout: 'inline'
            },
            content: {
              label: {
                'fr-FR': 'Logos',
                'en-GB': 'Logos',
                'nl-NL': 'Logos',
              },
              btn_label: {
                'fr-FR': 'Ajouter un logo',
                'en-GB': 'Add a logo',
                'nl-NL': 'Add a logo',
              }
            },
            components: [
              {
                path: 'modules/pages/SectionImageUpload',
                content: {
                  label: i18n.getLocalesTranslations('literal.logo')
                },
                model: 'logo',
                settings: {
                  hide_label: true,
                  crop: false,
                  size: { width: 270, height: 9999 }
                }
              }
            ]
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.partners')
    }
  }
]