import i18n from '@/vendors/i18n'

export default {
  name: 'title',
  settings: {
    icon: 'title',
    category: 'texts',
    is_reusable: true
  },
  content: {
    title: i18n.getLocalesTranslations('literal.title')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    }
  ]
}
