import i18n from '@/vendors/i18n'

export default {
  name: 'transaction_informations_sms',
  model: 'informations.sms_consent',
  type: 'input_checkbox',
  settings: {
    icon: 'phone_text',
    category: 'legal_fields',
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_sms')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      value: i18n.getLocalesTranslations('$block_sms.label_sms'),
      content: {
        label: i18n.getLocalesTranslations('literal.label')
      }
    },
    {
      path: 'molecules/ModuleInputSelect',
      model: 'attributes.default_value',
      value: false,
      options: [
        {
          label: i18n.getLocalesTranslations('literal.checked'),
          value: true
        },
        {
          label: i18n.getLocalesTranslations('literal.not_checked'),
          value: false
        }
      ],
      content: {
        label: i18n.getLocalesTranslations('literal.default_value')
      }
    },
    {
      path: 'molecules/ModuleInputCheckboxMandatory',
      value: 'sameAs:true',
      content: {
        label: i18n.getLocalesTranslations('$procedure_block.label_is_mandatory')
      }
    }
  ]
}