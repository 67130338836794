const Pages = () => import(/* webpackChunkName: 'Pages' */'@/views/pages/ViewPages')
const PagePartial = () => import(/* webpackChunkName: 'PagePartial' */'@/views/pages/ViewPagePartial')
const ViewPageLayout = () => import(/* webpackChunkName: 'ViewPageLayout' */'@/views/pages/ViewPageLayout')

const routes = [
  {
    path: 'pages',
    name: 'Pages',
    component: Pages,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.pages', routeName: 'Pages' }
      ],
      faq: [
        'pages_what_are_the_system_pages',
        'pages_how_can_i_create_a_new_landing_or_homepage',
        'pages_how_can_i_link_a_page_to_my_header_or_footer'
      ]
    }
  },
  {
    path: 'pages/header',
    name: 'PagesHeader',
    component: ViewPageLayout,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.pages', routeName: 'Pages' },
        { label: 'literal.header', routeName: 'PagesHeader' }
      ],
      faq: ['pages_how_can_i_link_a_page_to_my_header_or_footer']
    }
  },
  {
    path: 'pages/footer',
    name: 'PagesFooter',
    component: ViewPageLayout,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.pages', routeName: 'Pages' },
        { label: 'literal.footer', routeName: 'PagesFooter' }
      ],
      faq: ['pages_how_can_i_link_a_page_to_my_header_or_footer']
    }
  },
  {
    path: 'pages/:id/edit',
    name: 'EditPage',
    component: PagePartial,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.pages', routeName: 'Pages' },
        { routeName: 'EditPage' }
      ],
      faq: ['pages_how_can_i_add_campaigns_to_my_campaign_page']
    }
  }
]

export default routes
