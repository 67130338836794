import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_iles_de_paix',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionLogo'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Première colonne',
          'en-GB': 'First column'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_1.links',
      settings: {
        hide_label: true,
        layout: 'inline'
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Deuxième colonne',
          'en-GB': 'Second column'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_2.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_2.name',
      settings: {
        label: i18n.getLocalesTranslations('literal.name')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_2.address',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.address'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_line')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'text',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Troisième colonne',
          'en-GB': 'Third column'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_3.contact',
      content: {
        label: i18n.getLocalesTranslations('literal.contact'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_line'),
        wrapper_title: 'text'
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          content: {
            label: i18n.getLocalesTranslations('literal.type')
          },
          value: 'text',
          settings: {
            hide_label: true,
            options: [
              {
                label: i18n.getLocalesTranslations('literal.text'),
                value: 'text'
              },
              {
                label: i18n.getLocalesTranslations('literal.email'),
                value: 'email'
              },
              {
                label: i18n.getLocalesTranslations('literal.phone_number'),
                value: 'phone_number'
              }
            ]
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Quatrième colonne',
          'en-GB': 'Fourth column'
        }
      }
    },
    {
      path: 'modules/pages/SectionNetworks'
    }
  ]
}
