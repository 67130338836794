import { defineStore } from 'pinia'

export const useNotificationStore = defineStore('NotificationStore', {
  state: () => ({
    side_notifications: []
  }),
  actions: {
    postSideNotification (notification) {
      this.side_notifications.push({ ...notification, timestamp: Date.now() })
    },
    deleteSideNotification (notification) {
      this.side_notifications = this.side_notifications.filter(({ timestamp }) => timestamp !== notification.timestamp)
    }
  }
})