import { inject } from 'vue'
import { createI18n as I18n } from 'vue-i18n'
import enGB from './translations/en-GB.json'
import frFR from './translations/fr-FR.json'
import nlNL from './translations/nl-NL.json'
import deDE from './translations/de-DE.json'
import available from './translations/available'
import { get as _get, mapValues as _mapValues } from 'lodash'

const locale = 'en-GB'
const i18nSymbol = Symbol()

const i18n = I18n({
  locale,
  fallbackLocale: 'en-GB',
  messages: {
    'en-GB': enGB,
    'fr-FR': frFR,
    'nl-NL': nlNL,
    'de-DE': deDE
  }
})

i18n.global.translate = function (key, newlocale) {
  const locale = newlocale && newlocale !== undefined ? newlocale : i18n.global.locale
  if (key && typeof key === 'object') return key[locale] || Object.values(key).find(v => v)
  if (key && typeof key === 'string') return key
  return false
}

i18n.global.getLanguageValue = function (locale) {
  const lang = Object.values(available).find(lang => lang.id === locale)
  return lang ? lang.value : 'en'
}

i18n.global.getLanguageLabel = function (locale) {
  const lang = Object.values(available).find(lang => lang.id === locale)
  return lang ? lang.label.name : 'English'
}

i18n.global.getLocalesTranslations = function (key) {
  return _mapValues(available, lang => key ? i18n.global.t(key, lang.id) : null)
}

i18n.global.getCurrentLanguage = function () {
  return Object.values(available).find(lang => lang.id === i18n.global.locale)
}

export default i18n.global

export const createI18n = {
  install: function (app) {
    app.use(i18n)
    app.provide(i18nSymbol, i18n.global)
  }
}

export function useI18n () {
  return inject(i18nSymbol)
}
