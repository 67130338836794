import i18n from '@/vendors/i18n'
import { projects_overview_options } from '@/configurations/general/components-default-data'

export default {
  name: 'custom_homepage_rodekruisvlaanderen',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Banner #1',
        }
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'header.tertiary.image',
      settings: {
        crop: false,
        size: { width: 1280, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'header.tertiary.title',
      content: {
        label: {
          'nl-NL': 'Titel'
        }
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'header.tertiary.text'
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Banner #2',
        }
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'header.primary.image',
      settings: {
        crop: false,
        size: { width: 1280, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'header.primary.title',
      content: {
        label: {
          'nl-NL': 'Titel'
        }
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'header.primary.text'
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Banner #3',
        }
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'header.secondary.image',
      settings: {
        crop: false,
        size: { width: 1280, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'header.secondary.title',
      content: {
        label: {
          'nl-NL': 'Titel'
        }
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'header.secondary.text'
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'header.secondary.cta1',
      value: {
        target_url: 'campaigns',
        type: 'page',
        text: {
          'nl-NL': 'Ik start een actie'
        }
      },
      content: {
        label: {
          'nl-NL': 'Call to action #1'
        }
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'header.secondary.cta2',
      value: {
        target_url: 'projects',
        type: 'page',
        text: {
          'nl-NL': 'Ik steun financieel'
        }
      },
      content: {
        label: {
          'nl-NL': 'Call to action #2'
        }
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'header.cta',
      value: {
        target_url: 'projects',
        type: 'page',
        text: {
          'nl-NL': 'Naar alle projecten'
        }
      },
      content: {
        label: {
          'nl-NL': 'Call to action #3'
        }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Campagnes',
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'campaigns.title',
      content: {
        label: {
          'nl-NL': 'Titel',
        }
      }
    },
    {
      path: 'modules/pages/SectionCampaigns',
      model: 'campaigns.items'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Projecten',
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'projects.title',
      content: {
        label: {
          'nl-NL': 'Titel'
        }
      }
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'projects.query.campaign_id',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionSelect',
      model: 'projects.query.sort',
      value: 'created_at_first',
      settings: {
        options: projects_overview_options
      },
      content: {
        label: i18n.getLocalesTranslations('literal.sort_project_by')
      }
    },
    {
      path: 'modules/pages/SectionInputNumber',
      model: 'projects.query.per_page',
      value: 4,
      content: {
        label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'projects.cta1',
      value: {
        target_url: 'campaigns',
        type: 'page',
        text: {
          'nl-NL': 'Ik start een actie'
        }
      },
      content: {
        label: {
          'nl-NL': 'Call to action #1'
        }
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'projects.cta2',
      value: {
        target_url: 'projects',
        type: 'page',
        text: {
          'nl-NL': 'Steun een actie'
        }
      },
      content: {
        label: {
          'nl-NL': 'Call to action #2'
        }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Testimonials',
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'quote.title',
      content: {
        label: {
          'nl-NL': 'Titel'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'quote.items',
      content: {
        label: {
          'nl-NL': 'Testimonials'
        },
        btn_label: {
          'nl-NL': 'Add new testimonial'
        }
      },
      components: [
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          settings: {
            configuration: ['bold', 'italic', 'underline']
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'author',
          content: {
            label: {
              'nl-NL': 'Author'
            }
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Mission',
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'about.title',
      content: {
        label: {
          'nl-NL': 'Titel'
        }
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'about.text'
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'about.image',
      settings: {
        crop: false,
        size: { width: 1280, height: 9999 }
      }
    }
  ],
  content: {
    title: {
      'en-GB': 'Rode Kruis Vlaanderen',
      'fr-FR': 'Rode Kruis Vlaanderen',
      'nl-NL': 'Rode Kruis Vlaanderen'
    }
  }
}
