import { mixpanel } from '@/vendors/integrations'

export default class {
  static init = ({ user }) => {
    mixpanel.identify(user.email)
    mixpanel.register({
      '$user_id': user.id,
      '$email': user.email
    })
  }

  static login = ({ type }) => {
    mixpanel.track('Login', {
      'Type': type
    })
  }
}
