import i18n from '@/vendors/i18n'

export default {
  name: 'custom_header_allezchantez',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.website_link')

      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'back_link',
      settings: {
        hide_label: true
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionLogo'
    }
  ]
}
