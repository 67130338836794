import { computed } from 'vue'
import { get as _get } from 'lodash'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'

export function useRoles () {
  const { getAuthUser } = useAuthenticationStore()
  const { authUser } = getAuthUser()
  const authRole = computed(() => _get(authUser.value, 'role'))
  const isManager = computed(() => ['manager', 'accountant', 'admin', 'koalect'].includes(authRole.value))
  const isAccountant = computed(() => ['accountant', 'admin', 'koalect'].includes(authRole.value))
  const isAdmin = computed(() => ['admin', 'koalect'].includes(authRole.value))
  const isKoalect = computed(() => ['koalect'].includes(authRole.value))
  const isAuthorized = role => {
    const available_roles = ['manager', 'accountant', 'admin', 'koalect']
    const roleIndex = available_roles.indexOf(role)
    const roles = roleIndex !== undefined ? available_roles.slice(roleIndex, available_roles.length) : []
    return roles.includes(authRole.value)
  }

  return {
    isManager,
    isAccountant,
    isAdmin,
    isKoalect,
    isAuthorized
  }
}
