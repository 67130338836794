import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_refugeewalk',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.contact')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_1.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'column_1.logo',
      settings: {
        hide_label: true,
        crop: false,
        size: { width: 100, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_1.organisation.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_1.organisation.address',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.address'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_line')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'line',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_1.contact.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_1.contact.infos',
      content: {
        label: i18n.getLocalesTranslations('literal.contact'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_line'),
        wrapper_title: 'text'
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          content: {
            label: i18n.getLocalesTranslations('literal.type')
          },
          value: 'text',
          settings: {
            hide_label: true,
            options: [
              {
                label: i18n.getLocalesTranslations('literal.text'),
                value: 'text'
              },
              {
                label: i18n.getLocalesTranslations('literal.email'),
                value: 'email'
              },
              {
                label: i18n.getLocalesTranslations('literal.phone_number'),
                value: 'phone_number'
              }
            ]
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.sponsors')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_2.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_2.sponsors',
      content: {
        label: i18n.getLocalesTranslations('literal.sponsors'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_sponsor'),
        wrapper_title: 'link.text'
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'logo',
          settings: {
            hide_label: true,
            crop: false,
            size: { width: 85, height: 9999 }
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          content: {
            label: i18n.getLocalesTranslations('literal.link')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.social_networks')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_3.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionNetworks'
    }
  ]
}
