import i18n from '@/vendors/i18n'
import { metrics_sporting_options, metrics_type_options } from '@/configurations/general/components-default-data'

export default {
  name: 'custom_homepage_boven_de_wolken',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.header')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'header.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.metrics')
      }
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'stats.settings',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
      },
      components: [
        {
          path: 'modules/pages/SectionSelectCampaigns',
          model: 'campaign_id',
          content: {
            label: i18n.getLocalesTranslations('literal.show_metrics_from_these_campaigns')
          }
        },
        {
          path: 'modules/pages/SectionSelectForms',
          model: 'procedure_id',
          content: {
            label: i18n.getLocalesTranslations('literal.show_metrics_from_these_forms')
          }
        },
        {
          path: 'modules/pages/SectionSelectBenefitings',
          model: 'benefiting_id',
          content: {
            label: i18n.getLocalesTranslations('literal.show_metrics_from_these_beneficiaries')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'stats.metrics',
      content: {
        wrapper_title: 'text',
        btn_label: i18n.getLocalesTranslations('literal.add_metric'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          value: 'contributors_count',
          settings: {
            options: metrics_type_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.metric')
          }
        },
        {
          path: 'modules/pages/SectionInputDate',
          model: 'date',
          content: {
            label: i18n.getLocalesTranslations('literal.date')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'date',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionSelect',
          model: 'sporting_statistics',
          value: 'total_distance',
          settings: {
            options: metrics_sporting_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.sporting_statistics')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'sporting_statistics',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text'
        }
      ]
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'header.description',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.description')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.actions')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'actions.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'actions.items',
      content: {
        label: i18n.getLocalesTranslations('literal.actions'),
        btn_label: i18n.getLocalesTranslations('literal.add_action')
      },
      components: [
        {
          path: 'modules/pages/SectionSelectCampaigns',
          model: 'id',
          settings: {
            limit: 1
          },
          content: {
            label: i18n.getLocalesTranslations('literal.select_a_campaign')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          settings: {
            configuration: ['bold', 'italic', 'underline']
          },
          content: {
            label: i18n.getLocalesTranslations('literal.description')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.body')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'body.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'body.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.description')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'campaigns.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'campaigns.items',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns'),
        btn_label: i18n.getLocalesTranslations('literal.add_campaign')
      },
      components: [
        {
          path: 'modules/pages/SectionSelectCampaigns',
          model: 'id',
          settings: {
            limit: 1
          },
          content: {
            label: i18n.getLocalesTranslations('literal.select_a_campaign')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.gift')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      content: {
        label: i18n.getLocalesTranslations('literal.image')
      },
      model: 'gift.image',
      settings: {
        crop: false,
        size: { width: 1280, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'gift.link',
      content: {
        label: i18n.getLocalesTranslations('literal.link')
      }
    }
  ],
  content: {
    title: {
      'en-GB': 'Boven De Wolken',
      'fr-FR': 'Boven De Wolken',
      'nl-NL': 'Boven De Wolken'
    }
  }
}
