import i18n from '@/vendors/i18n'
import { metrics_type_options, metrics_sporting_options } from '@/configurations/general/components-default-data'

export default {
  name: 'counter',
  settings: {
    icon: 'timer'
  },
  components: [
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'text'
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'query.campaign_id',
      content: {
        label: i18n.getLocalesTranslations('literal.show_metrics_from_these_campaigns')
      }
    },
    {
      path: 'modules/pages/SectionSelectForms',
      model: 'query.procedure_id',
      content: {
        label: i18n.getLocalesTranslations('literal.show_metrics_from_these_forms')
      }
    },
    {
      path: 'modules/pages/SectionSelectBenefitings',
      model: 'query.benefiting_id',
      content: {
        label: i18n.getLocalesTranslations('literal.show_metrics_from_these_beneficiaries')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'metrics',
      content: {
        label: i18n.getLocalesTranslations('literal.metrics'),
        wrapper_title: 'text',
        btn_label: i18n.getLocalesTranslations('literal.add_metric'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          value: 'contributors_count',
          settings: {
            options: metrics_type_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.metric')
          }
        },
        {
          path: 'modules/pages/SectionInputDate',
          model: 'date',
          content: {
            label: i18n.getLocalesTranslations('literal.date')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'date',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionSelect',
          model: 'sporting_statistics',
          value: 'total_distance',
          settings: {
            options: metrics_sporting_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.sporting_statistics')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'sporting_statistics',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text'
        }
      ]
    }
  ],
  style: {
    background: {
      image: i18n.getLocalesTranslations(),
      color: null,
      type: 'color'
    },
    text: {
      color: null
    }
  },
  content: {
    title: i18n.getLocalesTranslations('literal.counter')
  }
}
