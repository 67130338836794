import i18n from '@/vendors/i18n'

export default {
  name: 'transaction_informations_terms_of_use',
  model: 'informations.terms_of_use',
  type: 'input_checkbox',
  settings: {
    icon: 'input_checkbox',
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_terms_of_use')
  },
  is_allowed: {
    'procedure.category': ['donation']
  },
  is_required: {
    'procedure.category': ['donation']
  },
  attributes: {
    editor_content: i18n.getLocalesTranslations('$block_terms_of_use.label_platform_terms_of_use')
  }
}
