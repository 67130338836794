import Link from '@/classes/Link'
import i18n from '@/vendors/i18n'

const socialNetworks = [
  new Link({
    icon: ['fab', 'facebook-square'],
    text: i18n.getLocalesTranslations('Facebook')
  }),
  new Link({
    icon: ['fab', 'twitter'],
    text: i18n.getLocalesTranslations('Twitter')
  }),
  new Link({
    icon: ['fab', 'x-twitter'],
    text: i18n.getLocalesTranslations('X')
  }),
  new Link({
    icon: ['fab', 'instagram'],
    text: i18n.getLocalesTranslations('Instagram')
  }),
  new Link({
    icon: ['fab', 'linkedin'],
    text: i18n.getLocalesTranslations('Linkedin')
  }),
  new Link({
    icon: ['fab', 'youtube'],
    text: i18n.getLocalesTranslations('Youtube')
  }),
  new Link({
    icon: ['fab', 'medium'],
    text: i18n.getLocalesTranslations('Medium')
  }),
  new Link({
    icon: ['fab', 'tiktok'],
    text: i18n.getLocalesTranslations('Tiktok')
  }),
  new Link({
    icon: ['fab', 'twitch'],
    text: i18n.getLocalesTranslations('Twitch')
  })
]

export default socialNetworks
