import i18n from '@/vendors/i18n'

export default {
  name: 'custom_homepage_paralympic',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.partners')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'partners.logos',
      content: {
        label: {
          'fr-FR': 'Logos'
        },
        btn_label: i18n.getLocalesTranslations('literal.add_new_partner'),
        wrapper_title: 'link.text'
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'logo',
          settings: {
            crop: false,
            size: { width: 200, height: 9999 }
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.banner')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'banner.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'banner.text',
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'banner.button',
      content: {
        label: i18n.getLocalesTranslations('literal.button'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_button')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'banner.image',
      settings: {
        crop: false,
        size: { width: 700, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Uitleg',
          'fr-FR': 'Explications',
          'en-GB': 'Explanation'
        }
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'explanation.image',
      settings: {
        crop: false,
        size: { width: 1200, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'explanation.text',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'heading_h2', 'list_ul', 'list_ol', 'link', 'align_left', 'align_center', 'align_right']
      }
    },
  ],
  content: {
    title: {
      'en-GB': 'Paralympic',
      'fr-FR': 'Paralympic',
      'nl-NL': 'Paralympic'
    }
  }
}
