import i18n from '@/vendors/i18n'

export default {
  name: 'custom_header_vijvens',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'logo.image',
      settings: {
        crop: false,
        size: { width: 140, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionInputUrl',
      model: 'logo.url',
      content: {
        label: i18n.getLocalesTranslations('literal.url')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'links',
      settings: {
        layout: 'inline',
        hide_label: true
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'item',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.action')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'cta',
      content: {
        label: i18n.getLocalesTranslations('literal.call_to_action')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
  ]
}
