import i18n from '@/vendors/i18n'
import { projects_overview_options } from '@/configurations/general/components-default-data'


const title = [
  {
    path: 'modules/pages/SectionContainer',
    model: 'title',
    content: {
      default_wrapper_title: i18n.getLocalesTranslations('literal.title'),
      wrapper_title: 'title'
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        model: 'icon',
        settings: {
          crop: false,
          size: { width: 94, height: 9999 }
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'text',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      }
    ]
  }
]

export default [
  {
    name: 'homepage2024_technopolis_banner',
    settings: {
      icon: 'image',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        model: 'image',
        settings: {
          crop: true,
          size: { width: 960, height: 890 }
        }
      },
      {
        path: 'modules/pages/SectionInputUrl',
        model: 'video',
        content: {
          label: i18n.getLocalesTranslations('literal.video'),
          placeholder: i18n.getLocalesTranslations('editor.placeholder_video')
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'text_color', 'link']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.banner')
    }
  },
  {
    name: 'homepage2024_technopolis_crowdfunding',
    settings: {
      icon: 'markbook',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      ...title,
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'text_color', 'link']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        }
      },
      {
        path: 'modules/pages/SectionSelectProjects',
        model: 'project_id',
        content: {
          label: i18n.getLocalesTranslations('literal.projects')
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: {
        'en-GB': 'Crowdfunding',
        'nl-NL': 'Crowdfunding',
        'fr-FR': 'Crowdfunding',
        'de-DE': 'Crowdfunding'
      }
    }
  },
  {
    name: 'homepage2024_technopolis_hightlited_action',
    settings: {
      icon: 'markbook',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      ...title,
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'text_color', 'link']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        }
      },
      {
        path: 'modules/pages/SectionSelectProjects',
        model: 'project_id',
        content: {
          label: i18n.getLocalesTranslations('literal.projects')
        },
        settings: {
          limit: 1
        }
      },
      {
        path: 'modules/pages/SectionImageUpload',
        model: 'image',
        settings: {
          crop: false,
          size: { width: 793, height: 9999 }
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: {
        'en-GB': 'Highlighted action',
        'nl-NL': 'Uitgelichte actie',
        'fr-FR': 'Action mise en avant'
      }
    }
  },
  {
    name: 'homepage2024_technopolis_campaign',
    settings: {
      icon: 'flag',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        model: 'image',
        settings: {
          crop: true,
          size: { width: 514, height: 687 }
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'text_color', 'link']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.campaign')
    }
  },
  {
    name: 'homepage2024_technopolis_actions',
    settings: {
      icon: 'markbook',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'query',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelect',
            model: 'sort',
            value: 'created_at_first',
            settings: {
              options: projects_overview_options
            },
            content: {
              label: i18n.getLocalesTranslations('literal.sort_project_by')
            }
          },
          {
            path: 'modules/pages/SectionInputNumber',
            model: 'per_page',
            value: 4,
            content: {
              label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
            }
          },
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.campaigns')
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.projects')
    }
  },
  {
    name: 'homepage2024_technopolis_testimonials',
    settings: {
      icon: 'quote',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'quotes',
        content: {
          label: {
            'fr-FR': 'Témoignages',
            'en-GB': 'Testimonials',
            'nl-NL': 'Getuigenissen'
          },
          wrapper_title: 'author',
          btn_label: {
            'fr-FR': 'Ajouter un témoignage',
            'en-GB': 'Add a testimonial',
            'nl-NL': 'Voeg een getuigenis toe'
          },
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: {
                'fr-FR': 'Photo',
                'en-GB': 'Picture',
                'nl-NL': 'Picture',
              }
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 390, height: 512 }
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'quote',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.quote')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'author',
            content: {
              label: {
                'fr-FR': 'Auteur',
                'en-GB': 'Author',
                'nl-NL': 'Author'
              }
            }
          }
        ]
      }
    ],
    content: {
      title: {
        'fr-FR': 'Témoignages',
        'en-GB': 'Testimonials',
        'nl-NL': 'Getuigenissen'
      }
    }
  },
  {
    name: 'homepage2024_technopolis_about',
    settings: {
      icon: 'text_editor',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        },
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link']
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.about')
    }
  }
]