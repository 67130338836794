<template>
  <div v-if="client">
    <template v-if="!client.is_maintenance">
      <desktop-side-bar v-if="isAuthUser" />

      <router-view
        :class="{ 'app__container': isAuthUser }"
        :key="route.path"
      />

      <mobile-nav-bar v-if="isAuthUser" />
    </template>

    <app-maintenance v-else />

    <app-reload-modal
      v-if="showReloadModal"
      @close="hideReloadModal"
    />

    <app-faq />
    <app-side-notifications />
  </div>
</template>

<script setup>
import { defineAsyncComponent, ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useClientStore } from '@/stores/ClientStore'
import { useGlobalStore } from '@/stores/GlobalStore'
import { initHead } from '@/composables/app/useHead'
import { version as appVersion } from '@/version.js'
import '@/scss/style.scss'

import DesktopSideBar from '@/components/organisms/DesktopSideBar'
import MobileNavBar from '@/components/organisms/MobileNavBar'
import AppSideNotifications from '@/components/organisms/AppSideNotifications'
import AppFaq from '@/components/molecules/AppFaq'

const AppReloadModal = defineAsyncComponent(() => import('&/organisms/AppReloadModal'))
const AppMaintenance = defineAsyncComponent(() => import('@/components/organisms/AppMaintenance'))

initHead()
const { isAuthUser } = storeToRefs(useAuthenticationStore())
const route = useRoute()
const { getClient } = useClientStore()
const globalStore = useGlobalStore()

const { client } = getClient()

const is_new_version = ref(false)

const isRouterError = computed(() => globalStore.is_router_error)
const showReloadModal = computed(() => globalStore.is_router_error && is_new_version.value)

const hideReloadModal = () => globalStore.$patch({ is_router_error: false })

watch(isRouterError, () => {
  fetch('/json/version.json')
    .then(async response => {
      const { version } = await response.json()
      is_new_version.value = version !== appVersion
    })
})
</script>
