export default [
  {
    key: 'pages_what_are_the_system_pages'
  },
  {
    key: 'pages_how_can_i_create_a_new_landing_or_homepage'
  },
  {
    key: 'pages_how_can_i_link_a_page_to_my_header_or_footer'
  },
  {
    key: 'pages_how_can_i_add_campaigns_to_my_campaign_page'
  }
]
