import { createRouter, createWebHistory, RouterView } from 'vue-router'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useGlobalStore } from '@/stores/GlobalStore'
import { useLanguageStore } from '@/stores/LanguageStore'
import { useAllowedFeatures } from '@/composables/app/useAllowedFeatures'
import { storeToRefs } from 'pinia'
import Routes from '@/routes/routes'
import { flattenDeep as _flattenDeep, uniqBy as _uniqBy } from 'lodash'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/:locale',
      component: RouterView,
      children: Routes,
      beforeEnter: (to, from, next) => {
        const { locale } = storeToRefs(useLanguageStore())
        const path = to.path === '/' ? to.path + locale.value + '/' : to.path.replace(to.params.locale, locale.value)

        to.params.locale !== locale.value ? next({ path }) : next()
      }
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: () => {
        const { locale } = storeToRefs(useLanguageStore())
        return '/' + locale.value
      }
    }
  ],
  scrollBehavior (to, from) {
    if (to.path === from.path && to.query !== from.query) {
      return { left: window.scrollX, top: window.scrollY }
    } else {
      return { left: 0, top: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const { initAuthentication } = useAuthenticationStore()

  initAuthentication()
    .then(user => {
      const { locale } = storeToRefs(useLanguageStore())

      if (to.matched.some(route => route.meta.requiresAuth) && !user) {
        next({ name: 'Login', params: { locale: locale.value, ...to.params }, query: { redirectTo: to.path } })
      } else if (to.matched.some(route => route.meta.requiresGuest) && user) {
        next({ name: 'Home', params: { locale: locale.value } })
      } else if (to.matched.some(route => route.meta.requiresManagerRole)) {
        const roles = ['manager', 'accountant', 'admin', 'koalect']
        if (user && roles.includes(user.role)) {
          next()
        } else {
          next({ name: 'NotFound', params: { locale: locale.value } })
        }
      } else if (to.matched.some(route => route.meta.requiresAccountantRole)) {
        const roles = ['accountant', 'admin', 'koalect']
        if (user && roles.includes(user.role)) {
          next()
        } else {
          next({ name: 'NotFound', params: { locale: locale.value } })
        }
      } else if (to.matched.some(route => route.meta.requiresAdminRole)) {
        const roles = ['admin', 'koalect']
        if (user && roles.includes(user.role)) {
          next()
        } else {
          next({ name: 'NotFound', params: { locale: locale.value } })
        }
      } else if (to.matched.some(route => route.meta.requireFeature)) {
        const { isAllowed } = useAllowedFeatures()
        const allowedFeature = _uniqBy(_flattenDeep(to.matched.map(route => route.meta.requireFeature).filter(feature => feature)))
          .every(feature => isAllowed(feature))

        if (allowedFeature) {
          next()
        } else {
          next({ name: 'NotFound', params: { locale: locale.value } })
        }
      } else {
        next()
      }
    })
})


router.onError(error => {
  const globalStore = useGlobalStore()

  if (/ChunkLoadError:.*failed./i.test(error.message) || /Loading.*chunk.*failed./i.test(error.message)) {
    globalStore.$patch({ is_router_error: true })
  }
})

export default router
