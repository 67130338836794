import i18n from '@/vendors/i18n'
import { metrics_type_options, metrics_sporting_options, projects_overview_options } from '@/configurations/general/components-default-data'

export default {
  name: 'custom_homepage_24htelevie',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components:[
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Bannière'
        }
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'hero.image',
      settings: {
        crop: true,
        size: { width: 1600, height: 780 }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'hero.title',
      settings: {
        layout: 'inline'
      },
      content: {
        label: {
          'fr-FR': 'Titre'
        },
        btn_label: i18n.getLocalesTranslations('literal.add_new_line')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'line',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'hero.date',
      content: {
        label: {
          'fr-FR': 'Date'
        }
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'hero.facebook',
      content: {
        label: {
          'fr-FR': 'Événement Facebook'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'hero.organisers.title',
      content: {
        label: {
          'fr-FR': 'Organisation'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'hero.organisers.logos',
      content: {
        wrapper_title: 'link.text',
        label: {
          'fr-FR': 'Logo des organisateurs'
        },
        btn_label: {
          'fr-FR': 'Ajouter un nouveau logo'
        }
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          content: {
            label: {
              'fr-FR': 'Lien'
            }
          }
        },
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'logo',
          settings: {
            crop: false,
            size: { width: 110, height: 9999 }
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Statistiques',
        }
      }
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'stats.campaign_id',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'stats.metrics',
      content: {
        label: {
          'fr-FR': 'Métrique'
        },
        wrapper_title: 'text',
        btn_label: i18n.getLocalesTranslations('literal.add_metric'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          value: 'contributors_count',
          settings: {
            options: metrics_type_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.metric')
          }
        },
        {
          path: 'modules/pages/SectionInputDate',
          model: 'date',
          content: {
            label: i18n.getLocalesTranslations('literal.date')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'date',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionSelect',
          model: 'sporting_statistics',
          value: 'total_distance',
          settings: {
            options: metrics_sporting_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.sporting_statistics')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'sporting_statistics',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'fixed_value',
          content: {
            label: {
              'en-GB': 'Fixed value'
            }
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text'
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Sports',
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'sports',
      content: {
        wrapper_title: 'name',
        label: {
          'fr-FR': 'Sports'
        },
        btn_label: {
          'fr-FR': 'Ajouter un nouveau sport'
        }
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'name',
          content: {
            label: {
              'fr-FR': 'Nom'
            }
          }
        },
        {
          path: 'modules/pages/SectionSelect',
          model: 'icon',
          value: 'running',
          settings: {
            options: [
              { label: 'Course', value: 'running' },
              { label: 'Handbike', value: 'handbike' },
              { label: 'Marche', value: 'boots' },
              { label: 'Vélo', value: 'bike' },
              { label: 'Nage', value: 'swim' },
              { label: 'Esprit d\'equipe', value: 'users' },
              { label: 'Cœur', value: 'heart' },
              { label: 'Ambiance', value: 'party' },
              { label: 'Soutien', value: 'hand_heart' }
            ]
          },
          content: {
            label: {
              'fr-FR': 'Icône'
            }
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Live',
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'live.title',
      content: {
        label: {
          'fr-FR': 'Titre'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputUrl',
      model: 'live.url',
      content: {
        label: {
          'fr-FR': 'URL'
        }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'À propos',
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'about.content',
      content: {
        wrapper_title: 'text',
        label: {
          'fr-FR': 'Contenu'
        },
        btn_label: i18n.getLocalesTranslations('literal.add_new_line')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'text',
          content: {
            label: {
              'fr-FR': 'Texte'
            }
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'about.images',
      content: {
        label: {
          'fr-FR': 'Images'
        },
        btn_label: {
          'fr-FR': 'Ajouter une nouvelle image'
        }
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'image',
          settings: {
            crop: true,
            size: { width: 513, height: 580 }
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Étapes',
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'steps.content',
      content: {
        wrapper_title: 'title',
        btn_label: {
          'fr-FR': 'Ajouter une étape'
        }
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: {
              'fr-FR': 'Titre'
            }
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'content',
          settings: {
            configuration: ['bold', 'italic', 'underline']
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'steps.cta',
      content: {
        label: {
          'fr-FR': 'Appel à l\'action'
        }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Équipe',
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'projects.title',
      content: {
        label: {
          'fr-FR': 'Titre'
        }
      }
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'projects.query.campaign_id',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionSelect',
      model: 'projects.query.sort',
      value: 'created_at_first',
      settings: {
        options: projects_overview_options
      },
      content: {
        label: i18n.getLocalesTranslations('literal.sort_project_by')
      }
    },
    {
      path: 'modules/pages/SectionInputNumber',
      model: 'projects.query.per_page',
      value: 8,
      content: {
        label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'projects.cta',
      content: {
        label: {
          'fr-FR': 'Appel à l\'action'
        }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Compteur'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'counter.title',
      content: {
        label: {
          'fr-FR': 'Titre'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputDate',
      model: 'counter.end_date',
      content: {
        label: {
          'fr-FR': 'Date'
        }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Partenaires'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'partners.title',
      content: {
        label: {
          'fr-FR': 'Titre'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'partners.logos',
      content: {
        label: {
          'fr-FR': 'Logos'
        },
        btn_label: i18n.getLocalesTranslations('literal.add_new_partner'),
        wrapper_title: 'link.text'
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          content: {
            label: {
              'fr-FR': 'Lien'
            }
          }
        },
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'logo',
          settings: {
            crop: false,
            size: { width: 200, height: 9999 }
          }
        }
      ]
    },
  ],
  content: {
    title: {
      'fr-FR': '24h Télévie',
    }
  }
}
