import Step from '@/classes/procedures/Step'
import i18n from '@/vendors/i18n'

let FormConfiguration = class FormConfiguration {
  constructor ({ name, steps, redirection_link, back_redirection_link, style, fee, is_subscription, statement_descriptor, payment_methods } = {}) {
    this.name = name || null
    this.redirection_link = redirection_link || i18n.getLocalesTranslations()
    this.back_redirection_link = back_redirection_link || i18n.getLocalesTranslations()
    this.steps = Array.isArray(steps) ? steps.map(step => new Step(step).static()) : []
    this.style = style || {}
    this.fee = fee || 0
    this.is_subscription = is_subscription || null
    this.statement_descriptor = statement_descriptor || null
    this.payment_methods = payment_methods || {}
  }

  database = () => {
    return {
      name: this.name,
      redirection_link: this.redirection_link,
      back_redirection_link: this.back_redirection_link,
      steps: Array.isArray(this.steps) ? this.steps.map(step => new Step(step).database()) : [],
      style: this.style,
      fee: this.fee,
      is_subscription: this.is_subscription,
      statement_descriptor: this.statement_descriptor,
      payment_methods: this.payment_methods
    }
  }
}

export default FormConfiguration
