import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_baluchon',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Eerste kolom',
          'fr-FR': 'Première colonne',
          'en-GB': 'First column'
        }
      }
    },
    {
      path: 'modules/pages/SectionLogo'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Tweede kolom',
          'fr-FR': 'Deuxième colonne',
          'en-GB': 'Second column'
        }
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'column_2.website',
      content: {
        label: i18n.getLocalesTranslations('literal.website_link')
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.social_networks')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Derde kolom',
          'fr-FR': 'Troisième colonne - Contact',
          'en-GB': 'Third column - Contact'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_3.name',
      content: {
        label: i18n.getLocalesTranslations('literal.name')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_3.address',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.address'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_line')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'line',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_3.contact',
      content: {
        label: i18n.getLocalesTranslations('literal.contact'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_line'),
        wrapper_title: 'text'
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          content: {
            label: i18n.getLocalesTranslations('literal.type')
          },
          value: 'text',
          settings: {
            hide_label: true,
            options: [
              {
                label: i18n.getLocalesTranslations('literal.text'),
                value: 'text'
              },
              {
                label: i18n.getLocalesTranslations('literal.website'),
                value: 'website'
              },
              {
                label: i18n.getLocalesTranslations('literal.email'),
                value: 'email'
              },
              {
                label: i18n.getLocalesTranslations('literal.phone_number'),
                value: 'phone_number'
              }
            ]
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Vierde kolom',
          'fr-FR': 'Quatrième colonne',
          'en-GB': 'Fourth column'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_4.sponsors',
      content: {
        wrapper_title: 'name',
        btn_label: i18n.getLocalesTranslations('literal.add_new_partner')
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'logo',
          settings: {
            crop: false,
            size: { width: 660, height: 440 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.logo')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'name',
          content: {
            label: i18n.getLocalesTranslations('literal.partner_name')
          }
        }
      ]
    }
  ]
}
