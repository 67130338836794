import i18n from '@/vendors/i18n'
import { metrics_sporting_options, metrics_type_options } from '@/configurations/general/components-default-data'

export default {
  name: 'custom_homepage_towalkagain',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.banner')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      content: {
        label: i18n.getLocalesTranslations('literal.background')
      },
      model: 'banner.background',
      settings: {
        crop: false,
        size: { width: 1280, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'banner.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'banner.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'banner.button'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.metrics')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'metrics',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'text',
        btn_label: i18n.getLocalesTranslations('literal.add_metric'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          value: 'contributors_count',
          settings: {
            options: metrics_type_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.metric')
          }
        },
        {
          path: 'modules/pages/SectionInputDate',
          model: 'date',
          content: {
            label: i18n.getLocalesTranslations('literal.date')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'date',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionSelect',
          model: 'sporting_statistics',
          value: 'total_distance',
          settings: {
            options: metrics_sporting_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.sporting_statistics')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'sporting_statistics',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text'
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Appel aux actions',
          'nl-NL': 'Oproep tot acties',
          'en-GB': 'Call to actions'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'ctas.items',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'title',
        btn_label: i18n.getLocalesTranslations('literal.add_an_element')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'img_src',
          settings: {
            size: { width: 400, height: 150 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          },
          settings: {
            configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'button',
          content: {
            label: i18n.getLocalesTranslations('literal.button')
          }
        },
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.steps')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'steps.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'steps.items',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'title',
        btn_label: i18n.getLocalesTranslations('literal.add_step')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'img_src',
          settings: {
            size: { width: 200, height: 200 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.step_title')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          content: {
            label: i18n.getLocalesTranslations('literal.step_description')
          },
          settings: {
            configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'steps.cta',
      content: {
        label: i18n.getLocalesTranslations('literal.button')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.news')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'news.items',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'title',
        btn_label: i18n.getLocalesTranslations('literal.add_element')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'img_src',
          settings: {
            crop: false,
            size: { width: 600, height: 9999 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          content: {
            label: i18n.getLocalesTranslations('literal.description')
          },
          settings: {
            configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'button',
          content: {
            label: i18n.getLocalesTranslations('literal.button')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Testimonials'
        }
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'quote.image',
      settings: {
        size: { width: 210, height: 210 }
      },
      content: {
        label: i18n.getLocalesTranslations('literal.image')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'quote.text',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'quote.author',
      content: {
        label: i18n.getLocalesTranslations('literal.author_name')
      }
    }
  ],
  content: {
    title: {
      'en-GB': 'To Walk Again',
      'fr-FR': 'To Walk Again',
      'nl-NL': 'To Walk Again'
    }
  }
}
