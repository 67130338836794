import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_ihecs',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Première colonne',
          'nl-NL': 'Eerste kolom',
          'en-GB': 'First column'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_1.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_1.menu',
      settings: {
        hide_label: true,
        layout: 'inline'
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Deuxième colonne',
          'nl-NL': 'Tweede kolom',
          'en-GB': 'Second column'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_2.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_2.actions',
      settings: {
        hide_label: true,
        layout: 'inline'
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_action')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Troisième colonne - Liens',
          'nl-NL': 'Derde kolom - Links',
          'en-GB': 'Third column - Links '
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_3.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionNetworks'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Quatrième colonne - Contact',
          'nl-NL': 'Vierde kolom - Contact',
          'en-GB': 'Fourth column - Contact'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_4.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_4.name',
      content: {
        label: i18n.getLocalesTranslations('literal.name')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_4.address',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.address'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_line')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'line',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_4.contact',
      content: {
        label: i18n.getLocalesTranslations('literal.contact'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_line'),
        wrapper_title: 'text'
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          content: {
            label: i18n.getLocalesTranslations('literal.type')
          },
          value: 'text',
          settings: {
            hide_label: true,
            options: [
              {
                label: i18n.getLocalesTranslations('literal.text'),
                value: 'text'
              },
              {
                label: i18n.getLocalesTranslations('literal.email'),
                value: 'email'
              },
              {
                label: i18n.getLocalesTranslations('literal.phone_number'),
                value: 'phone_number'
              }
            ]
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'prefix',
          content: {
            label: {
              'fr-FR': 'Préfixe',
              'nl-NL': 'Voorvoegsel',
              'en-GB': 'Prefix'
            }
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text',
          settings: {
            hide_label: true
          }
        }
      ]
    }
  ]
}
