const EditEmail = () => import(/* webpackChunkName: 'EditEmail' */'@/views/emails/ViewEditEmail')

const routes = [
  {
    path: 'procedures/:procedureSlug/emails/:emailId',
    name: 'ClientProcedureEditEmail',
    component: EditEmail,
    props: true,
    meta: {
      requiresManagerRole: true,
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { label: 'literal.overview', routeName: 'ClientProcedureEmails' },
        { slot: 'EditEmail', routeName: 'ClientProcedureEditEmail' }
      ],
      navigation: {
        back: { name: 'ClientProcedureEmails' }
      }
    }
  },
  {
    path: 'campaigns/:campaignSlug/emails/transactional/:emailId',
    name: 'CampaignEditTransactionalEmail',
    component: EditEmail,
    props: true,
    meta: {
      type: 'transactional',
      requiresManagerRole: true,
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { label: 'literal.campaign_overview', routeName: 'Campaign' },
        { label: 'literal.transactional_emails', routeName: 'CampaignEmails' },
        { slot: 'EditEmail', routeName: 'CampaignEditTransactionalEmail' }
      ],
      navigation: {
        back: { name: 'CampaignEmails' }
      }
    }
  },
  {
    path: 'campaigns/:campaignSlug/emails/activation/:emailId',
    name: 'CampaignEditActivationEmail',
    component: EditEmail,
    props: true,
    meta: {
      type: 'activation',
      requiresManagerRole: true,
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { label: 'literal.campaign_overview', routeName: 'Campaign' },
        { label: 'literal.activation_emails', routeName: 'CampaignEmailsActivation' },
        { slot: 'EditEmail', routeName: 'CampaignEditActivationEmail' }
      ],
      navigation: {
        back: { name: 'CampaignEmailsActivation' }
      }
    }
  },
  {
    path: 'qrcode/:slug/emails/:emailId',
    name: 'QrcodeEditEmail',
    component: EditEmail,
    props: true,
    meta: {
      type: 'transactional',
      breadcrumb: [
        { label: 'literal.qrcodes', routeName: 'Qrcodes' },
        {
          label: 'literal.emails',
          route: { name: 'Qrcode', query: { tab: 'emails' } }
        },
        { slot: 'EditEmail', routeName: 'QrcodeEditEmail' }
      ],
      navigation: {
        back: { name: 'Qrcode', query: { tab: 'emails' } }
      }
    }
  }
]

export default routes
