import i18n from '@/vendors/i18n'

export default {
  name: 'text_editor',
  settings: {
    icon: 'text_editor'
  },
  components: [
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'text'
    }
  ],
  style: {
    background: {
      image: i18n.getLocalesTranslations(),
      color: null,
      type: 'color'
    },
    text: {
      color: null
    }
  },
  content: {
    title: i18n.getLocalesTranslations('literal.text')
  }
}
