import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_jessa',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.footer')
      }
    }
  ]
}
