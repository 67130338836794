import i18n from '@/vendors/i18n'

export default {
  name: 'transaction_customer_address_country',
  model: 'customer.address.country',
  settings: {
    icon: 'country',
    category: 'address_fields',
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_customer_address_country')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      value: i18n.getLocalesTranslations('$block_customer_address_country.label_country'),
      content: {
        label: i18n.getLocalesTranslations('literal.label')
      }
    },
    {
      path: 'molecules/ModuleInputSelectCountry',
      model: 'attributes.default_value',
      value: 'BE',
      content: {
        label: i18n.getLocalesTranslations('literal.default_value')
      }
    },
    {
      path: 'molecules/ModuleInputCheckboxMandatory',
      value: 'required',
      content: {
        label: i18n.getLocalesTranslations('$procedure_block.label_is_mandatory')
      }
    }
  ]
}
