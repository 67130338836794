import { ref, computed, watch, toValue } from 'vue'
import { useI18n } from '@/vendors/i18n'
import { useRoute } from 'vue-router'
import { useHead as useUnhead } from '@unhead/vue'
import { get as _get, truncate as _truncate } from 'lodash'

const state = ref({})

export function initHead () {
  const { t, translate } = useI18n()
  const route = useRoute()

  const breadcrumb = computed(() => _get(route, 'meta.breadcrumb', []))

  const title = computed(() => {
    const items = breadcrumb.value
      .map(({ label, route, routeName, slot }) => {
        const name = _get(route, 'name', routeName)
        const key = slot ? getKey(slot) : getKey(name)
        const title = _get(state.value, key)

        return label ? t(label) : translate(toValue(title))
      })
      .filter(item => item)

    return _truncate(items.reverse().join(' / '), { length: 45, separator: '' })
  })

  const getKey = name => name.charAt(0).toLowerCase() + name.slice(1)

  useUnhead({ title, titleTemplate: title => `${title || t('literal.loading')} | MyKoalect` })

  watch(breadcrumb, () => state.value = {})

  return { state }
}

export function useHead (obj = {}) {
  state.value = obj
}
