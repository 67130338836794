import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_technopolis',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.logo')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'logo_1',
      settings: {
        hide_label: true,
        crop: false,
        size: { width: 9999, height: 168 }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'logo_2',
      settings: {
        hide_label: true,
        crop: false,
        size: { width: 9999, height: 168 }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'navigation',
      settings: {
        layout: 'inline',
        hide_label: true
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.footer')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'sub_navigation',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'value'
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          value: 'text',
          settings: {
            options: [
              { value: 'text', label: i18n.getLocalesTranslations('literal.text') },
              { value: 'email', label: i18n.getLocalesTranslations('literal.email') },
              { value: 'phone', label: i18n.getLocalesTranslations('literal.phone') }
            ]
          },
          content: {
            label: i18n.getLocalesTranslations('literal.type')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'value',
          content: {
            label: i18n.getLocalesTranslations('literal.value')
          }
        }
      ]
    }
  ]
}
