import i18n from '@/vendors/i18n'
import { projects_overview_options } from '@/configurations/general/components-default-data'

export default {
  name: 'projects_overview',
  settings: {
    icon: 'markbook'
  },
  components: [
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'text_editor'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.filters_and_search_element')
      }
    },
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['input__label', 'label--light']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.search')
      }
    },
    {
      path: 'modules/pages/SectionInputSwitch',
      model: 'query.show_search',
      content: {
        label: i18n.getLocalesTranslations('literal.show_element_for_users')
      }
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'query.campaign_id',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns'),
        placeholder: i18n.getLocalesTranslations('literal.all_projects')
      }
    },
    {
      path: 'modules/pages/SectionInputSwitch',
      model: 'query.show_campaigns_filter',
      content: {
        label: i18n.getLocalesTranslations('literal.show_element_for_users')
      }
    },
    {
      path: 'modules/pages/SectionSelectBenefitings',
      model: 'query.benefiting_id',
      content: {
        label: i18n.getLocalesTranslations('literal.beneficiaries'),
        placeholder: i18n.getLocalesTranslations('literal.all_beneficiaries')
      }
    },
    {
      path: 'modules/pages/SectionInputSwitch',
      model: 'query.show_benefitings_filter',
      content: {
        label: i18n.getLocalesTranslations('literal.show_element_for_users')
      }
    },
    {
      path: 'modules/pages/SectionSelect',
      model: 'query.sort',
      value: 'activity_last',
      settings: {
        options: projects_overview_options
      },
      content: {
        label: i18n.getLocalesTranslations('literal.sort_project_by')
      }
    },
    {
      path: 'modules/pages/SectionInputSwitch',
      model: 'query.show_sort',
      content: {
        label: i18n.getLocalesTranslations('literal.show_element_for_users')
      }
    },
    {
      path: 'modules/pages/SectionInputNumber',
      model: 'query.per_page',
      value: 8,
      settings: {
        min: 0,
        max: 40
      },
      content: {
        label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionInputSwitch',
      model: 'query.show_pagination',
      content: {
        label: i18n.getLocalesTranslations('literal.pagination')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'button',
      value: {
        target_url: 'projects',
        type: 'page',
        text: i18n.getLocalesTranslations('literal.see_all_projects')
      },
      content: {
        label: i18n.getLocalesTranslations('literal.cta_button')
      }
    }
  ],
  style: {
    background: {
      image: i18n.getLocalesTranslations(),
      color: null,
      type: 'color'
    },
    text: {
      color: null
    },
    button: {
      color: null,
      background: null
    }
  },
  content: {
    title: i18n.getLocalesTranslations('literal.project_selection')
  }
}
