<template>
  <ul class="list">
    <template v-if="routes.length > 1">
      <li
        class="list__item"
        v-for="(item, index) in routes"
        :key="index"
      >
        <router-link
          class="list__link"
          :class="{ 'list__link--active': index === routes.length - 1 }"
          :to="item.route || { name: item.routeName }"
        >
          <slot
            v-if="!item.label || item.slot"
            :name="item.slot || item.routeName"
          />

          <span v-else>
            {{ t(item.label) }}
          </span>
        </router-link>
      </li>
    </template>
  </ul>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from '@/vendors/i18n'
import { get as _get } from 'lodash'

const route = useRoute()
const { t } = useI18n()

const routes = computed(() => _get(route, 'meta.breadcrumb', []))
</script>

<style scoped lang="scss">
.list {
  margin: $margin__base 0;
  display: flex;

  &__item {
    &:not(:first-child) {
      margin-left: calc($margin__base / 2);

      &:before {
        content: '/';
        margin-right: calc($margin__base / 4);
        color: $lg;
      }
    }
  }

  &__link {
    font-weight: normal;
    color: $mg;
    font-size: rem(14px);
    transition: all $transition__duration;

    &:hover,
    &--active {
      color: $color--secondary;
    }

    &--active {
      font-weight: bold;
    }
  }
}
</style>
