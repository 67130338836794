import i18n from '@/vendors/i18n'

export default {
  name: 'media+text',
  settings: {
    icon: 'layout'
  },
  components: [
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'text'
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'button'
    },
    {
      path: 'modules/pages/SectionMedia',
      model: 'media',
      settings: {
        size: { width: 700, height: 466 }
      }
    }
  ],
  style: {
    background: {
      image: i18n.getLocalesTranslations(),
      color: null,
      type: 'color'
    },
    text: {
      color: null
    },
    button: {
      color: null,
      background: null
    },
    layout: 'normal'
  },
  content: {
    title: i18n.getLocalesTranslations('$page_section.media_text')
  }
}
