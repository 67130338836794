import i18n from '@/vendors/i18n'
import { metrics_type_options, metrics_sporting_options } from '@/configurations/general/components-default-data'

const rooms = []

for (let i = 0; i < 9; i++) {
  rooms.push({
    path: 'modules/pages/SectionContainer',
    model: `room_${i}`,
    content: {
      default_wrapper_title: { 'fr-FR': `Pièce - ${i + 1}`, 'nl-NL': `Kamer - ${i + 1}`, 'en-GB': `Room - ${i + 1}` }
    },
    components: [
      {
        path: 'modules/pages/SectionRepeater',
        model: 'gallery',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.images')
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'image',
            settings: {
              crop: true,
              size: { width: 456, height: 238 }
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        },
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
        }
      }
    ]
  })
}

export default [
  {
    name: 'homepage_vijvens_banner',
    settings: {
      icon: 'image',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        },
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      },
      {
        path: 'modules/pages/SectionImageUpload',
        model: 'image',
        settings: {
          crop: true,
          size: { width: 581, height: 606 }
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.banner')
    }
  },
  {
    name: 'homepage_vijvens_stats',
    settings: {
      icon: 'timer',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'settings',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_campaigns')
            }
          },
          {
            path: 'modules/pages/SectionSelectForms',
            model: 'procedure_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_forms')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'metrics',
        content: {
          wrapper_title: 'text',
          btn_label: i18n.getLocalesTranslations('literal.add_metric'),
          default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
        },
        settings: {
          hide_label: true
        },
        components: [
          {
            path: 'modules/pages/SectionSelect',
            model: 'type',
            value: 'contributors_count',
            settings: {
              options: [...metrics_type_options, { value: 'custom', label: i18n.getLocalesTranslations('literal.custom') }]
            },
            content: {
              label: i18n.getLocalesTranslations('literal.metric')
            }
          },
          {
            path: 'modules/pages/SectionInputDate',
            model: 'date',
            content: {
              label: i18n.getLocalesTranslations('literal.date')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'date',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionSelect',
            model: 'sporting_statistics',
            value: 'total_distance',
            settings: {
              options: metrics_sporting_options
            },
            content: {
              label: i18n.getLocalesTranslations('literal.sporting_statistics')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'sporting_statistics',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'value',
            content: {
              label: i18n.getLocalesTranslations('literal.value')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'custom',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'text'
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.metrics')
    }
  },
  {
    name: 'homepage_vijvens_building',
    settings: {
      icon: 'location',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      ...rooms,
      {
        path: 'modules/pages/SectionInputText',
        model: 'legend',
        content: {
          label: i18n.getLocalesTranslations('literal.help')
        }
      },
    ],
    content: {
      title: { 'fr-FR': 'Bâtiment', 'nl-NL': 'Gebouw', 'en-GB': 'Building' }
    }
  },
  {
    name: 'homepage_vijvens_howitworks',
    settings: {
      icon: 'message_cog',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'steps',
        settings: {
          hide_label: true
        },
        content: {
          wrapper_title: 'title',
          btn_label: i18n.getLocalesTranslations('literal.add_new_element')
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'icon',
            settings: {
              crop: true,
              size: { width: 56, height: 56 }
            },
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'content',
            settings: {
              configuration: ['bold', 'italic', 'underline', 'text_color']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.content')
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'link',
            content: {
              label: i18n.getLocalesTranslations('literal.link')
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.how_it_works')
    }
  },
  {
    name: 'homepage_vijvens_why',
    settings: {
      icon: 'text_editor',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        model: 'image',
        settings: {
          crop: true,
          size: { width: 516, height: 516 }
        },
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link', 'list_ul', 'list_ol', 'text_color']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: { 'fr-FR': 'Pourquoi ?', 'nl-NL': 'Waarom?', 'en-GB': 'Why?' }
    }
  },
  {
    name: 'homepage_vijvens_testimonials',
    settings: {
      icon: 'quote',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionRepeater',
        model: 'quotes',
        settings: {
          hide_label: true
        },
        content: {
          wrapper_title: 'quote',
          btn_label: i18n.getLocalesTranslations('literal.add_new_element')
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'quote',
            content: {
              label: i18n.getLocalesTranslations('literal.quote')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'author',
            content: {
              label: i18n.getLocalesTranslations('literal.name')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'content',
            settings: {
              configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.content')
            }
          }
        ]
      }
    ],
    content: {
      title: { 'fr-FR': 'Témoignages', 'nl-NL': 'Getuigenissen', 'en-GB': 'Testimonials' }
    }
  },
  {
    name: 'homepage_vijvens_articles',
    settings: {
      icon: 'newsfeed',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        settings: {
          hide_label: true
        },
        content: {
          wrapper_title: 'title'
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 410, height: 309 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'text_color']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'link',
            content: {
              label: i18n.getLocalesTranslations('literal.link')
            }
          }
        ]
      }
    ],
    content: {
      title: {
        'fr-FR': 'Nouvelles',
        'en-GB': 'News',
        'nl-NL': 'Nieuws'
      }
    }
  },
  {
    name: 'homepage_vijvens_timeline',
    settings: {
      icon: 'timer',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        content: {
          wrapper_title: 'date'
        },
        settings: {
          hide_label: true
        },
        components: [
          {
            path: 'modules/pages/SectionInputSwitch',
            model: 'background',
            content: {
              label: i18n.getLocalesTranslations('literal.background')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'date',
            content: {
              label: i18n.getLocalesTranslations('literal.date')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'content',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'text_color']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'link',
            content: {
              label: i18n.getLocalesTranslations('literal.link')
            }
          }
        ]
      }
    ],
    content: {
      title: {
        'fr-FR': 'Capsule temporelle',
        'en-GB': 'Time capsule',
        'nl-NL': 'Tijdscapsule'
      }
    }
  },
  {
    name: 'homepage_vijvens_gallery',
    settings: {
      icon: 'banner',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        content: {
          btn_label: i18n.getLocalesTranslations('literal.add_image'),
          default_wrapper_title: i18n.getLocalesTranslations('literal.image')
        },
        settings: {
          hide_label: true
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'image',
            settings: {
              crop: true,
              size: { width: 662, height: 516 }
            }
          }
        ]
      }
    ],
    content: {
      title: {
        'fr-FR': 'Galerie',
        'en-GB': 'Gallery',
        'nl-NL': 'Gallery'
      }
    }
  },
  {
    name: 'homepage_vijvens_sponsors',
    settings: {
      icon: 'sponsor',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        content: {
          btn_label: i18n.getLocalesTranslations('literal.add_new_sponsor')
        },
        settings: {
          hide_label: true
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'logo',
            settings: {
              crop: true,
              size: { width: 662, height: 516 }
            }
          },
          {
            path: 'modules/pages/SectionInputUrl',
            model: 'url',
            content: {
              label: i18n.getLocalesTranslations('literal.url')
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.sponsors')
    }
  },
]