import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_uzbrusselfoundation',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.contact')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'contact.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'contact.text',
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      },
      settings: {
        configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'contact.address',
      content: {
        label: i18n.getLocalesTranslations('literal.address')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'contact.phone',
      content: {
        label: i18n.getLocalesTranslations('literal.phone_number')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'contact.email',
      content: {
        label: i18n.getLocalesTranslations('literal.email')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'contact.bank_account',
      content: {
        label: i18n.getLocalesTranslations('literal.iban')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.links')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'links.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'links.navigation',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.links'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.social_networks')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'social.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionNetworks'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.logo')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'logo.src',
      settings: {
        hide_label: true,
        crop: false,
        size: { width: 302, height: 73 }
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'logo.link',
      content: {
        label: i18n.getLocalesTranslations('literal.link')
      }
    }
  ]
}
