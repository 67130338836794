import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_designmuseumgent',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.image')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'column_1.image',
      settings: {
        hide_label: true,
        crop: false,
        size: { width: 182, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionInputUrl',
      model: 'column_1.image_url',
      content: {
        label: i18n.getLocalesTranslations('literal.url')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.contact')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'column_2.address',
      content: {
        label: i18n.getLocalesTranslations('literal.address')
      },
      settings: {
        configuration: ['bold', 'italic']
      }
    },
    {
      path: 'modules/pages/SectionNetworks'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_3.links',
      settings: {
        layout: 'inline',
        hide_label: true
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.actions')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_4.login',
      content: {
        label: {
          'nl-NL': 'Login button'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_4.links',
      settings: {
        layout: 'inline',
        hide_label: true
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_button')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.logo')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'column_5.logo',
      settings: {
        hide_label: true,
        crop: false,
        size: { width: 182, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionInputUrl',
      model: 'column_5.logo_url',
      content: {
        label: i18n.getLocalesTranslations('literal.url')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.images')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_6.images',
      settings: {
        hide_label: true
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_image')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'image',
          settings: {
            crop: true,
            size: { width: 9999, height: 50 }
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    }
  ]
}
