import i18n from '@/vendors/i18n'

const room_index = [
  {
    value: 1,
    label: {
      'nl-NL': 'Ruwbouw',
      'fr-FR': 'Gros œuvre'
    }
  },
  {
    value: 2,
    label: {
      'nl-NL': 'Consultatieruimte 1',
      'fr-FR': 'Salle de consultation 1'
    }
  },
  {
    value: 3,
    label: {
      'nl-NL': 'Consultatieruimte 2',
      'fr-FR': 'Salle de consultation 2'
    }
  },
  {
    value: 4,
    label: {
      'nl-NL': 'Visuele therapie ruimte',
      'fr-FR': 'Salle de thérapie visuelle'
    }
  },
  {
    value: 5,
    label: {
      'nl-NL': 'Optiekzaak',
      'fr-FR': 'Magasin de lunettes'
    }
  },
  {
    value: 6,
    label: {
      'nl-NL': 'Optisch atelier',
      'fr-FR': 'Atelier de lunettes'
    }
  },
  {
    value: 7,
    label: {
      'nl-NL': 'Refractieruimte',
      'fr-FR': 'Espace réfractif'
    }
  },
  {
    value: 8,
    label: {
      'nl-NL': 'Pre-operatiekamer',
      'fr-FR': 'Salle pré-opératoire'
    }
  },
  {
    value: 9,
    label: {
      'nl-NL': 'Operatiezaal',
      'fr-FR': 'Salle d\'opération'
    }
  },
  {
    value: 10,
    label: {
      'nl-NL': 'Wachtkamer',
      'fr-FR': 'Salle d\'attente'
    }
  },
  {
    value: 11,
    label: {
      'nl-NL': 'Patiëntenkamers vrouwen',
      'fr-FR': 'Chambres des femmes'
    }
  },
  {
    value: 12,
    label: {
      'nl-NL': 'Patiëntenkamers mannen',
      'fr-FR': 'Chambres des hommes'
    }
  },
  {
    value: 13,
    label: {
      'nl-NL': 'Patiëntenkamers kinderen',
      'fr-FR': 'Chambres des enfants'
    }
  },
  {
    value: 14,
    label: {
      'nl-NL': 'Elektriciteitsvoorziening',
      'fr-FR': 'Approvisionnement en électricité'
    }
  }
]

export default {
  name: 'custom_lightfortheworld_kananga',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    undeletable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.banner')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      content: {
        label: {
          'fr-FR': 'Carte',
          'en-GB': 'Map',
          'nl-NL': 'Kaart'
        }
      },
      model: 'banner.map',
      settings: {
        crop: false,
        size: { width: 842, height: 741 }
      }
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'banner.logo',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.logo'),
        wrapper_title: 'alt'
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'url',
          settings: {
            hide_label: true,
            crop: false,
            size: { width: 265, height: 9999 }
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'alt',
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'banner.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'banner.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'banner.button',
      content: {
        label: i18n.getLocalesTranslations('literal.button')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.metrics')
      }
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'stats.labels',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.content')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'collected_amount',
          content: {
            label: {
              'fr-FR': 'Déjà financé',
              'nl-NL': 'Reeds gefinancierd',
              'en-GB': 'Already funded'
            }
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'still_needed',
          content: {
            label: {
              'fr-FR': 'Encore nécessaire',
              'nl-NL': 'Nog nodig',
              'en-GB': 'Still needed'
            }
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'goal_amount',
          content: {
            label: {
              'fr-FR': 'Montant cible',
              'nl-NL': 'Doelbedrag',
              'en-GB': 'Goal amount'
            }
          }
        },
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Bâtiment',
          'en-GB': 'Building',
          'nl-NL': 'Building'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'campaign.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'campaign.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'campaign.id',
      settings: {
        limit: 1
      },
      content: {
        label: i18n.getLocalesTranslations('literal.campaign')
      }
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'campaign.legend',
      content: {
        default_wrapper_title: {
          'fr-FR': 'Légende',
          'nl-NL': 'Legende',
          'en-GB': 'Legend'
        }
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'open',
          content: {
            label: {
              'en-GB': 'Open for donations',
              'fr-FR': 'Ouvert aux dons',
              'nl-NL': 'Open voor giften'
            }
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'close',
          content: {
            label: {
              'en-GB': 'Already funded',
              'fr-FR': 'Déjà financé',
              'nl-NL': 'Reeds gefinancierd'
            }
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'tax_certificate',
          content: {
            label: i18n.getLocalesTranslations('literal.tax_certificate')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'campaign.modal',
      content: {
        default_wrapper_title: {
          'fr-FR': 'Popup',
          'nl-NL': 'Popup',
          'en-GB': 'Popup'
        }
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'already_funded',
          content: {
            label: {
              'en-GB': 'Already funded',
              'fr-FR': 'Déjà financé',
              'nl-NL': 'Reeds gefinancierd'
            }
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'button',
          content: {
            label: i18n.getLocalesTranslations('literal.button')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'campaign.rooms',
      content: {
        label: {
          'fr-FR': 'Pièces',
          'en-GB': 'Rooms',
          'nl-NL': 'Kamers'
        },
        wrapper_title: 'name'
      },
      components: [
        {
          path: 'modules/pages/SectionInputSwitch',
          model: 'show',
          content: {
            label: {
              'fr-FR': 'Afficher la pièce',
              'en-GB': 'Show room',
              'nl-NL': 'Toon kamer'
            }
          }
        },
        {
          path: 'modules/pages/SectionSelect',
          model: 'index',
          settings: {
            options: room_index
          },
          content: {
            label: {
              'fr-FR': 'Pièce',
              'en-GB': 'Room',
              'nl-NL': 'Kamer'
            },
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'name',
          content: {
            label: i18n.getLocalesTranslations('literal.name')
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'images',
          content: {
            label: {
              'fr-FR': 'Images',
              'en-GB': 'Images',
              'nl-NL': 'Afbeeldingen',
            },
            btn_label: i18n.getLocalesTranslations('literal.add_image')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'show',
              value: true,
              comparison_operator: 'eq'
            }
          ],
          components: [
            {
              path: 'modules/pages/SectionImageUpload',
              model: 'url',
              settings: {
                hide_label: true,
                crop: true,
                size: { width: 440, height: 296 }
              }
            }
          ]
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          settings: {
            configuration: ['bold', 'italic', 'underline']
          },
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'show',
              value: true,
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionSelectProjects',
          model: 'project_id',
          content: {
            label: i18n.getLocalesTranslations('literal.project')
          },
          settings: {
            limit: 1
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'show',
              value: true,
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputSwitch',
          model: 'show_progressbar',
          content: {
            label: {
              'fr-FR': 'Afficher la barre de progression',
              'en-GB': 'Show progress bar',
              'nl-NL': 'Toon evolutie'
            }
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'show',
              value: true,
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputSwitch',
          model: 'show_button',
          content: {
            label: {
              'fr-FR': 'Afficher le bouton',
              'en-GB': 'Show button',
              'nl-NL': 'Toon button'
            }
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'show',
              value: true,
              comparison_operator: 'eq'
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'campaign.cta',
      content: {
        label: i18n.getLocalesTranslations('literal.call_to_action')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Pourquoi',
          'en-GB': 'Why',
          'nl-NL': 'Waarom'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'why.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'why.image',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.image')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'url',
          settings: {
            crop: true,
            size: { width: 566, height: 354 }
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'alt',
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'why.text',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'link', 'heading_h2']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'why.button',
      content: {
        label: i18n.getLocalesTranslations('literal.button')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.video')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'video.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionInputUrl',
      model: 'video.url',
      content: {
        label: i18n.getLocalesTranslations('literal.url')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'video.text',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'link', 'heading_h2']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'video.button',
      content: {
        label: i18n.getLocalesTranslations('literal.button')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.about')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'about.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'about.text',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'link']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'about.button',
      content: {
        label: i18n.getLocalesTranslations('literal.button')
      }
    }
  ],
  content: {
    title: {
      'en-GB': 'Kananga',
      'fr-FR': 'Kananga',
      'nl-NL': 'Kananga'
    }
  }
}
