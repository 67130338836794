export default class TwikeyFormConfiguration {
  constructor ({ redirect_url, qr_codes, default_amount, payment_links, reference } = {}) {
    this.redirect_url = redirect_url || {}
    this.qr_codes = qr_codes || {}
    this.default_amount = default_amount || 0
    this.payment_links = payment_links || {}
    this.reference = reference || null
  }

  database = () => {
    return {
      redirect_url: this.redirect_url,
      qr_codes: this.qr_codes,
      default_amount: this.default_amount,
      payment_links: this.payment_links,
      reference: this.reference
    }
  }
}
