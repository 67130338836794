import i18n from '@/vendors/i18n'

export default {
  name: 'divider',
  settings: {
    icon: 'divider'
  },
  style: {
    background: {
      color: null,
      type: 'color'
    }
  },
  content: {
    title: i18n.getLocalesTranslations('literal.divider')
  }
}
