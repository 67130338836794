import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_umons',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.contact')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'column_1.logo',
      content: {
        label: i18n.getLocalesTranslations('literal.logo')
      },
      settings: {
        crop: false,
        size: { width: 170, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_1.organisation.name',
      content: {
        label: i18n.getLocalesTranslations('literal.name')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_1.organisation.address',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.address'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_line')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'line',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_1.contact.infos',
      content: {
        label: i18n.getLocalesTranslations('literal.contact'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_line'),
        wrapper_title: 'text'
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          content: {
            label: i18n.getLocalesTranslations('literal.type')
          },
          value: 'text',
          settings: {
            hide_label: true,
            options: [
              {
                label: i18n.getLocalesTranslations('literal.text'),
                value: 'text'
              },
              {
                label: i18n.getLocalesTranslations('literal.email'),
                value: 'email'
              },
              {
                label: i18n.getLocalesTranslations('literal.phone_number'),
                value: 'phone_number'
              }
            ]
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_2.navigation',
      settings: {
        layout: 'inline'
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.social_networks')
      }
    },
    {
      path: 'modules/pages/SectionNetworks'
    }
  ]
}
