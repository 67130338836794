import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_kikirpa',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.newsletter')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'newsletter.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'newsletter.text',
      content: {
        label: i18n.getLocalesTranslations('literal.description')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'newsletter.link',
      content: {
        label: i18n.getLocalesTranslations('literal.link')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.social_media')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'social.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionNetworks'
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'social.description',
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'navigation.links',
      settings: {
        layout: 'inline',
        hide_label: true
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.actions')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'action.links',
      settings: {
        layout: 'inline',
        hide_label: true
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_button')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.logo')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      content: {
        label: i18n.getLocalesTranslations('literal.logo')
      },
      model: 'logo.image',
      settings: {
        crop: false,
        size: { width: 100, height: 9999 },
        hide_label: true
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.contact')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'contact.address',
      content: {
        label: i18n.getLocalesTranslations('literal.address')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'contact.email',
      content: {
        label: i18n.getLocalesTranslations('literal.email')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'contact.phone',
      content: {
        label: i18n.getLocalesTranslations('literal.phone')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.partners')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'partners.images',
      settings: {
        hide_label: true
      },
      content: {
        btn_label: {
          'fr-FR': 'Ajouter un logo',
          'en-GB': 'Add a logo',
          'nl-NL': 'Add a logo',
        }
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          content: {
            label: i18n.getLocalesTranslations('literal.logo')
          },
          model: 'image',
          settings: {
            crop: false,
            size: { width: 100, height: 9999 },
            hide_label: true
          }
        }
      ]
    }
  ]
}
