import i18n from '@/vendors/i18n'
import { metrics_type_options, projects_overview_options } from '@/configurations/general/components-default-data'

export default {
  name: 'custom_homepage_ulb',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'hero.image',
      settings: {
        crop: false,
        size: { width: 1800, height: 687 }
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'hero.content'
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'metrics',
      content: {
        label: {
          'fr-FR': 'Statistiques'
        },
        wrapper_title: 'text',
        btn_label: i18n.getLocalesTranslations('literal.add_metric'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'image',
          settings: {
            crop: false,
            size: { width: 36, height: 36 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        },
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          value: 'contributors_count',
          settings: {
            options: metrics_type_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.metric')
          }
        },
        {
          path: 'modules/pages/SectionInputDate',
          model: 'date',
          content: {
            label: i18n.getLocalesTranslations('literal.date')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'date',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text'
        }
      ]
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'projects.text_editor',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'heading_h2', 'align_left', 'align_center', 'align_right']
      }
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'projects.query.campaign_id',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionSelect',
      model: 'projects.query.sort',
      value: 'created_at_first',
      settings: {
        options: projects_overview_options
      },
      content: {
        label: i18n.getLocalesTranslations('literal.sort_project_by')
      }
    },
    {
      path: 'modules/pages/SectionInputNumber',
      model: 'projects.query.per_page',
      value: 8,
      content: {
        label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'projects.button',
      value: {
        target_url: 'projects',
        type: 'page',
        text: i18n.getLocalesTranslations('literal.see_all_projects')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'partners.title',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'heading_h2', 'align_left', 'align_center', 'align_right']
      },
      content: {
        label: {
          'fr-FR': 'Titre de la section "Partenaire"'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'partners.partners',
      content: {
        label: {
          'fr-FR': 'Partenaires'
        },
        wrapper_title: 'title',
        btn_label: {
          'fr-FR': 'Ajouter un nouveau partenaire'
        },
        default_wrapper_title: {
          'fr-FR': 'Partenaire'
        }
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'image',
          settings: {
            crop: false,
            size: { width: 737, height: 737 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'logo.image',
          settings: {
            crop: false,
            size: { width: 500, height: 70 }
          },
          content: {
            label: {
              'fr-FR': 'Logo du partenaire'
            }
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'logo.link',
          content: {
            label: {
              'fr-FR': 'Lien derrière le logo du partenaire'
            }
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'content',
          content: {
            label: {
              'fr-FR': 'Contenu du partenaire'
            }
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'cta'
        }
      ]
    }
  ],
  content: {
    title: {
      'en-GB': 'ULB',
      'fr-FR': 'ULB',
      'nl-NL': 'ULB'
    }
  }
}
