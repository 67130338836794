import i18n from '@/vendors/i18n'

export default {
  name: 'dropdown',
  type: 'input_select',
  settings: {
    icon: 'dropdown',
    category: 'form_fields',
    is_reusable: true,
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_dropdown')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      content: {
        label: i18n.getLocalesTranslations('literal.question')
      }
    },
    {
      path: 'molecules/ModuleRepeater',
      model: 'attributes.options',
      content: {
        label: i18n.getLocalesTranslations('literal.options'),
        btn: i18n.getLocalesTranslations('$block_select.btn_add_option')
      },
      components: [
        {
          path: 'molecules/ModuleInputOption',
          components: {
            label: {
              path: 'molecules/ModuleInputText',
              model: 'text',
              content: {
                label: i18n.getLocalesTranslations('literal.label')
              }
            },
            value: {
              path: 'molecules/ModuleInputText',
              model: 'value',
              content: {
                label: i18n.getLocalesTranslations('literal.reference')
              },
              settings: {
                multilingual: false
              }
            }
          }
        }
      ]
    },
    {
      path: 'molecules/ModuleInputSelect',
      model: 'attributes.default_value',
      options: 'attributes.options',
      content: {
        label: i18n.getLocalesTranslations('literal.default_value')
      }
    },
    {
      path: 'molecules/ModuleInputCheckboxMandatory',
      value: 'required',
      content: {
        label: i18n.getLocalesTranslations('$procedure_block.label_is_mandatory')
      }
    }
  ]
}
