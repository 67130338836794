import { mixpanel } from '@/vendors/integrations'

export default class {
  static new = () => {
    mixpanel.track('Clicked New benefiting')
  }

  static view = () => {
    mixpanel.track('Visited New benefiting procedure')
  }

  static created = ({ benefiting }) => {
    mixpanel.track('Clicked Create new benefiting', {
      'Benefiting Id': benefiting.id,
      'Name': benefiting.name
    })
  }

  static imported = () => {
    mixpanel.track('Imported new benefitings')
  }
}
