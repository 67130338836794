import i18n from '@/vendors/i18n'

export default {
  name: 'project_products',
  model: 'actions_data.order.order_lines',
  settings: {
    icon: 'shopping_basket',
    category: 'project_fields',
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_project_products')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      value: i18n.getLocalesTranslations(),
      content: {
        label: i18n.getLocalesTranslations('literal.selection_name_only_visible_in_recaps')
      }
    },
    {
      path: 'molecules/ModuleInputCheckboxMandatory',
      value: 'productRequired',
      content: {
        label: i18n.getLocalesTranslations('$procedure_block.label_is_mandatory')
      }
    }
  ],
  is_allowed: {
    'procedure.target_type': ['Campaign'],
    'procedure.category': ['donation']
  }
}
