import i18n from '@/vendors/i18n'
import { metrics_type_options } from '@/configurations/general/components-default-data'

const colors = {
  path: 'modules/pages/SectionSelect',
  model: 'color',
  content: {
    label: i18n.getLocalesTranslations('literal.color')
  },
  value: 'primary',
  settings: {
    hide_label: true,
    options: [
      {
        label: i18n.t('@settings|branding|colors.label_primary_color'),
        value: 'primary'
      },
      {
        label: i18n.t('@settings|branding|colors.label_secondary_color'),
        value: 'secondary'
      },
      {
        label: i18n.t('@settings|branding|colors.label_tertiary_color'),
        value: 'tertiary'
      }
    ]
  }
}

export default {
  name: 'custom_homepage_refugeewalk',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'en-GB': 'Banner',
          'nl-NL': 'Banner'
        }
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'hero.image',
      settings: {
        crop: false,
        size: { width: 1280, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'hero.buttons',
      settings: {
        layout: 'block'
      },
      content: {
        label: {
          'en-GB': 'Call to actions',
          'nl-NL': 'Call to actions'
        },
        btn_label: i18n.getLocalesTranslations('literal.add_new_link'),
        wrapper_title: 'cta.text',
        default_wrapper_title: i18n.getLocalesTranslations('literal.button'),
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'cta'
        },
        colors
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'en-GB': 'Statistics',
          'nl-NL': 'Statistieken'
        }
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'stats.content',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'link', 'align_left', 'align_center', 'align_right']
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'stats.metrics',
      content: {
        label: {
          'en-GB': 'Metrics',
          'nl-NL': 'Metrics'
        },
        wrapper_title: 'text',
        btn_label: i18n.getLocalesTranslations('literal.add_metric'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'image',
          settings: {
            crop: false,
            size: { width: 36, height: 36 }
          }
        },
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          value: 'contributors_count',
          settings: {
            options: metrics_type_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.metric')
          }
        },
        {
          path: 'modules/pages/SectionInputDate',
          model: 'date',
          content: {
            label: i18n.getLocalesTranslations('literal.date')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'date',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text'
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'en-GB': 'Actions',
          'nl-NL': 'Actions'
        }
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'actions.title',
      content: {
        label: {
          'en-GB': 'Title',
          'nl-NL': 'Title'
        }
      },
      settings: {
        configuration: ['bold', 'italic', 'underline', 'heading_h2', 'align_left', 'align_center', 'align_right']
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'actions.items',
      content: {
        label: {
          'en-GB': 'Actions',
          'nl-NL': 'Actions'
        },
        wrapper_title: 'link.text',
        default_wrapper_title: i18n.getLocalesTranslations('literal.action'),
        btn_label: i18n.getLocalesTranslations('literal.add_action')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'image',
          settings: {
            crop: true,
            size: {
              width: 623,
              height: 489
            }
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'link'
        },
        colors
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'en-GB': 'About',
          'nl-NL': 'About'
        }
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'about.content',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'heading_h2', 'list_ul', 'list_ol', 'link', 'align_left', 'align_center', 'align_right']
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'about.cta_1'
    },
    {
      ...colors, model: 'about.cta_1.color'
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'about.image',
      settings: {
        crop: true,
        size: { width: 408, height: 510 }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'about.title'
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'about.cta_2'
    },
    {
      ...colors, model: 'about.cta_2.color'
    }
  ],
  content: {
    title: {
      'en-GB': 'Refugee walk',
      'nl-NL': 'Refugee walk'
    }
  }
}
