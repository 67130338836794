import i18n from '@/vendors/i18n'
import { metrics_type_options, metrics_sporting_options, projects_overview_options } from '@/configurations/general/components-default-data'

export default {
  name: 'custom_homepage_ugent',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.banner')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'banner.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'banner.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'banner.link',
      settings: {
        hide_label: false
      },
      content: {
        label: i18n.getLocalesTranslations('literal.link')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      content: {
        label: i18n.getLocalesTranslations('literal.image')
      },
      model: 'banner.image',
      settings: {
        crop: false,
        size: { width: 730, height: 730 }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.metrics')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'stats.items',
      content: {
        label: i18n.getLocalesTranslations('literal.metrics'),
        wrapper_title: 'text',
        btn_label: i18n.getLocalesTranslations('literal.add_metric'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          value: 'contributors_count',
          settings: {
            options: metrics_type_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.metric')
          }
        },
        {
          path: 'modules/pages/SectionInputDate',
          model: 'date',
          content: {
            label: i18n.getLocalesTranslations('literal.date')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'date',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionSelect',
          model: 'sporting_statistics',
          value: 'total_distance',
          settings: {
            options: metrics_sporting_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.sporting_statistics')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'sporting_statistics',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text'
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'stats.query',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
      },
      components: [
        {
          path: 'modules/pages/SectionSelectCampaigns',
          model: 'campaign_id',
          content: {
            label: i18n.getLocalesTranslations('literal.show_metrics_from_these_campaigns')
          }
        },
        {
          path: 'modules/pages/SectionSelectForms',
          model: 'procedure_id',
          content: {
            label: i18n.getLocalesTranslations('literal.show_metrics_from_these_forms')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'campaigns.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'campaigns.ids',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.projects')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'projects.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'projects.query.campaign_id',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionSelect',
      model: 'projects.query.sort',
      value: 'created_at_first',
      settings: {
        options: projects_overview_options
      },
      content: {
        label: i18n.getLocalesTranslations('literal.sort_project_by')
      }
    },
    {
      path: 'modules/pages/SectionInputNumber',
      model: 'projects.query.per_page',
      value: 4,
      content: {
        label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'projects.button',
      settings: {
        hide_label: false
      },
      content: {
        label: i18n.getLocalesTranslations('literal.call_to_action')
      }
    },
  ],
  content: {
    title: {
      'en-GB': 'UGent',
      'fr-FR': 'UGent',
      'nl-NL': 'UGent'
    }
  }
}
