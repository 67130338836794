import { defineStore } from 'pinia'
import Client from '@/classes/Client'
import DomainName from '@/classes/DomainName'

export const useClientStore = defineStore('ClientStore', {
  state: () => ({
    client: {},
    loader: false,
    error: {}
  }),
  getters: {
    getClient: state => () => {
      return state.$getItem({
        resource: 'client',
        fetch: () => state.fetchClient()
      })
    }
  },
  actions: {
    fetchClient () {
      return this.$fetchItem({
        endpoint: '/mykoalect/client',
        resource: 'client',
        model: Client
      })
    },
    fetchOauthToken () {
      return this.$fetchItem({
        endpoint: '/mykoalect/client/generate_oauth_token'
      })
    },
    patchClient ({ params }) {
      return this.$patchItem({
        endpoint: '/mykoalect/client',
        resource: 'client',
        model: Client,
        params: {
          client: params
        }
      })
    },
    patchClientLogo ({ params }) {
      return this.$patchItem({
        endpoint: '/mykoalect/client',
        resource: 'client',
        params,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
    },
    postClientDomainName ({ params }) {
      return this.$postItem({
        endpoint: '/mykoalect/client/domain_names',
        params: new DomainName(params).database()
      })
    },
    patchClientDomainName ({ params, name }) {
      return this.$patchItem({
        endpoint: `/mykoalect/client/domain_names/${name}`,
        model: DomainName,
        params
      })
    },
    deleteClientDomainName ({ name }) {
      return this.$deleteItem({
        endpoint: `/mykoalect/client/domain_names/${name}`
      })
    }
  }
})