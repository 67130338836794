<template>
  <img
    class="picture"
    :class="[getActiveState, 'picture--' + size ]"
    :src="image"
    alt="User profile image"
    @error="setDefaultImage"
  >
</template>

<script>
import { getImagePath } from '@/utils/utils'
import { get as _get } from 'lodash'

export default {
  name: 'UserProfileImg',
  setup () {
    return {
      getImagePath
    }
  },
  props: {
    avatar: {
      type: Object
    },
    size: {
      type: String,
      default: '3'
    },
    activeState: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    image () {
      let default_image = null

      if (_get(this.avatar, 'url')) {
        default_image = this.avatar.url
      } else {
        default_image = this.getImagePath('placeholders/profile-picture.png')
      }

      return default_image
    },
    getActiveState () {
      if (this.$route.name && this.$route.name.indexOf('Profile') >= 0 && this.activeState) {
        return 'active'
      } else {
        return null
      }
    }
  },
  methods: {
    setDefaultImage (event) {
      event.target.src = this.getImagePath('placeholders/profile-picture.png')
    }
  }
}
</script>

<style scoped lang="scss">
.picture {
  background-color: $vlg;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  &--warning {
    background-color: $color--warning;
    color: $white;
  }

  &.active {
    border: 2px solid $color--secondary;
  }

  @for $size from 1 through 20 {
    &--#{$size} {
      width: $padding__base * $size;
      height: $padding__base * $size;
    }
  }
}
</style>
