import i18n from '@/vendors/i18n'
import { metrics_type_options } from '@/configurations/general/components-default-data'

export default {
  name: 'custom_homepage_cncd',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Bannière'
        }
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'hero.image',
      settings: {
        crop: false,
        size: { width: 1840, height: 1440 }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'hero.title',
      content: {
        label: {
          'fr-FR': 'Titre principal'
        }
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'hero.cta'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Statistiques'
        }
      }
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'stats.campaign_id',
      content: {
        label: {
          'fr-FR': 'Campagnes'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'stats.metrics',
      content: {
        wrapper_title: 'text',
        btn_label: i18n.getLocalesTranslations('literal.add_metric'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          value: 'contributors_count',
          settings: {
            options: metrics_type_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.metric')
          }
        },
        {
          path: 'modules/pages/SectionInputDate',
          model: 'date',
          content: {
            label: i18n.getLocalesTranslations('literal.date')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'date',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text'
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'À propos'
        }
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'about.content',
      content: {
        label: {
          'fr-FR': 'À propos de vous'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'about.slider',
      content: {
        wrapper_title: 'link.text',
        btn_label: i18n.getLocalesTranslations('literal.add_image'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.image')
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          content: {
            label: {
              'fr-FR': 'Lien'
            }
          },
          settings: {
            hide_label: true
          }
        },
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'image',
          settings: {
            crop: true,
            size: { width: 400, height: 400 }
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Actions'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'actions.title',
      content: {
        label: {
          'fr-FR': 'Titre de section'
        }
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'actions.cta'
    }
  ],
  content: {
    title: {
      'fr-FR': 'CNCD'
    }
  }
}
