import Page from '@/classes/pages/Page'

const endpoint = '/mykoalect/pages'
const resource = 'pages'

export default {
  namespaced: true,
  state: {
    items: {},
    paginator: {}
  },
  getters: {
    pages: state => state.items,
    paginator: state => state.paginator,
    getPage: state => id => Object.values(state.items).find(item => item.id === id)
  },
  actions: {
    fetchPages: ({ dispatch }, { query } = {}) => dispatch(
      'fetchItems',
      {
        endpoint,
        resource,
        query
      },
      { root: true }
    ),

    fetchPage: ({ dispatch }, { id }) => dispatch(
      'fetchItem',
      {
        endpoint: `${endpoint}/${id}`,
        resource,
        model: x => new Page(x).static()
      },
      { root: true }
    ),

    fetchMainLayout: ({ dispatch }) => dispatch(
      'fetchItem',
      {
        endpoint: `${endpoint}/main_layout`,
        resource,
        model: x => new Page(x).static()
      },
      { root: true }
    ),

    patchPageStatus: ({ dispatch }, { id, status }) => dispatch(
      'patchItem',
      {
        endpoint: `${endpoint}/${id}`,
        resource,
        params: {
          page: { status }
        },
        model: x => new Page(x).static()
      },
      { root: true }
    ),

    patchPage: ({ dispatch }, { id, params }) => dispatch(
      'patchItem',
      {
        endpoint: `${endpoint}/${id}`,
        resource,
        params: {
          page: new Page(params).database()
        },
        model: x => new Page(x).static()
      },
      { root: true }
    ),

    postPage: ({ dispatch }, { params }) => dispatch(
      'postItem',
      {
        endpoint,
        resource,
        params: {
          page: new Page(params).database()
        },
        model: x => new Page(x).static()
      },
      { root: true }
    ),

    deletePage: ({ dispatch }, { id }) => dispatch(
      'deleteItem',
      {
        endpoint: `${endpoint}/${id}`,
        resource,
        id
      },
      { root: true }
    )
  }
}
