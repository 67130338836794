import i18n from '@/vendors/i18n'
import { projects_overview_options } from '@/configurations/general/components-default-data'

const colors = {
  path: 'modules/pages/SectionSelect',
  model: 'color',
  content: {
    label: i18n.getLocalesTranslations('literal.color')
  },
  value: 'primary',
  settings: {
    hide_label: true,
    options: [
      {
        label: i18n.t('@settings|branding|colors.label_primary_color'),
        value: 'prim'
      },
      {
        label: i18n.t('@settings|branding|colors.label_secondary_color'),
        value: 'sec'
      }
    ]
  }
}

export default {
  name: 'custom_homepage_umons',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.banner')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      content: {
        label: i18n.getLocalesTranslations('literal.image')
      },
      model: 'banner.image',
      settings: {
        crop: true,
        size: { width: 1600, height: 600 }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'banner.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'banner.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'banner.buttons',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'button.text',
        btn_label: i18n.getLocalesTranslations('literal.add_new_button'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.button')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'button',
          settings: {
            hide_label: true
          },
          content: {
            label: i18n.getLocalesTranslations('literal.button')
          }
        },
        colors
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.campaign')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'campaigns.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'campaigns.ids',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.projects')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'projects.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'projects.query.campaign_id',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionSelect',
      model: 'projects.query.sort',
      value: 'created_at_first',
      settings: {
        options: projects_overview_options
      },
      content: {
        label: i18n.getLocalesTranslations('literal.sort_project_by')
      }
    },
    {
      path: 'modules/pages/SectionInputNumber',
      model: 'projects.query.per_page',
      value: 4,
      content: {
        label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'projects.buttons',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'button.text',
        btn_label: i18n.getLocalesTranslations('literal.add_new_button'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.button')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'button',
          settings: {
            hide_label: true
          },
          content: {
            label: i18n.getLocalesTranslations('literal.button')
          }
        },
        colors
      ]
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.partners')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'partners.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'partners.items',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'name',
        btn_label: i18n.getLocalesTranslations('literal.add_new_partner'),
        default_wrapper_title: {
          'fr-FR': 'Partenaire'
        }
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          },
          model: 'image',
          settings: {
            crop: true,
            size: { width: 900, height: 500 }
          }
        },
        {
          path: 'modules/pages/SectionImageUpload',
          content: {
            label: i18n.getLocalesTranslations('literal.logo')
          },
          model: 'logo',
          settings: {
            crop: false,
            size: { width: 130, height: 9999 }
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'name',
          content: {
            label: i18n.getLocalesTranslations('literal.name')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          settings: {
            configuration: ['bold', 'italic', 'underline', 'link', 'heading_h2', 'list_ul', 'list_ol']
          },
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'button',
          content: {
            label: i18n.getLocalesTranslations('literal.button')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.goal')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'goals.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'goals.items',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'title',
        btn_label: i18n.getLocalesTranslations('literal.add_an_element')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          settings: {
            configuration: ['bold', 'italic', 'underline', 'link', 'list_ul', 'list_ol']
          },
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.about')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'about.main_title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      content: {
        label: i18n.getLocalesTranslations('literal.image')
      },
      model: 'about.image',
      settings: {
        crop: true,
        size: { width: 530, height: 600 }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'about.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'about.text',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'link', 'list_ul', 'list_ol']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'about.button',
      content: {
        label: i18n.getLocalesTranslations('literal.button')
      }
    }
  ],
  content: {
    title: {
      'en-GB': 'UMons',
      'fr-FR': 'UMons',
      'nl-NL': 'UMons'
    }
  }
}
