import i18n from '@/vendors/i18n'

export default {
  name: 'custom_header_uzbrusselfoundation',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Top section',
          'en-GB': 'Top section',
          'nl-NL': 'Top section'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'top.address',
      content: {
        label: i18n.getLocalesTranslations('literal.address')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'top.phone',
      content: {
        label: i18n.getLocalesTranslations('literal.phone_number')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'top.bank_account',
      content: {
        label: i18n.getLocalesTranslations('literal.iban')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'top.website',
      content: {
        label: i18n.getLocalesTranslations('literal.website')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Middle section',
          'en-GB': 'Middle section',
          'nl-NL': 'Middle section'
        }
      }
    },
    {
      path: 'modules/pages/SectionLogo'
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'logo.link',
      settings: {
        hide_label: true
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Bottom section',
          'en-GB': 'Bottom section',
          'nl-NL': 'Bottom section'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'bottom.navigation',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.navigation'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'bottom.actions',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.actions'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_button')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    }
  ]
}
