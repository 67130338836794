import i18n from '@/vendors/i18n'
import { metrics_type_options, projects_overview_options, metrics_sporting_options } from '@/configurations/general/components-default-data'

export default [
  {
    name: 'custom_homepage_internationalamnesty_banner',
    settings: {
      icon: 'banner',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        model: 'background',
        settings: {
          crop: true,
          size: { width: 1440, height: 560 }
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.button')
        },
        value: {
          target_url: 'campaigns',
          type: 'page',
          text: i18n.getLocalesTranslations('literal.all_campaigns')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.banner')
    }
  },
  {
    name: 'custom_homepage_internationalamnesty_counter',
    settings: {
      icon: 'timer',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'query',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_campaigns')
            }
          },
          {
            path: 'modules/pages/SectionSelectForms',
            model: 'procedure_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_forms')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        content: {
          label: i18n.getLocalesTranslations('literal.metrics'),
          wrapper_title: 'text',
          btn_label: i18n.getLocalesTranslations('literal.add_metric'),
          default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
        },
        components: [
          {
            path: 'modules/pages/SectionSelect',
            model: 'type',
            value: 'contributors_count',
            settings: {
              options: metrics_type_options
            },
            content: {
              label: i18n.getLocalesTranslations('literal.metric')
            }
          },
          {
            path: 'modules/pages/SectionInputDate',
            model: 'date',
            content: {
              label: i18n.getLocalesTranslations('literal.date')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'date',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionSelect',
            model: 'sporting_statistics',
            value: 'total_distance',
            settings: {
              options: metrics_sporting_options
            },
            content: {
              label: i18n.getLocalesTranslations('literal.sporting_statistics')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'sporting_statistics',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'text'
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.counter')
    }
  },
  {
    name: 'custom_homepage_internationalamnesty_projects',
    settings: {
      icon: 'markbook',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'query',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.campaigns')
            }
          },
          {
            path: 'modules/pages/SectionSelect',
            model: 'sort',
            value: 'created_at_first',
            settings: {
              options: projects_overview_options
            },
            content: {
              label: i18n.getLocalesTranslations('literal.sort_project_by')
            }
          },
          {
            path: 'modules/pages/SectionInputNumber',
            model: 'per_page',
            value: 4,
            content: {
              label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.button')
        },
        value: {
          target_url: 'campaigns',
          type: 'page',
          text: i18n.getLocalesTranslations('literal.see_all_projects')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.projects')
    }
  },
  {
    name: 'custom_homepage_internationalamnesty_how_it_works',
    settings: {
      icon: 'message_cog',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'steps',
        content: {
          label: i18n.getLocalesTranslations('literal.steps'),
          btn_label: i18n.getLocalesTranslations('literal.add_step'),
          wrapper_title: 'text'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.step_description')
            }
          },
          {
            path: 'modules/pages/SectionSelect',
            model: 'section',
            content: {
              label: {
                'fr-FR': 'Section',
                'en-GB': 'Section',
                'nl-NL': 'Sectie'
              }
            },
            settings: {
              options: [
                { 'label': { 'fr-FR': '', 'en-GB': '', 'nl-NL': '' }, value: '' },
                { 'label': i18n.getLocalesTranslations('literal.banner'), value: 'banner' },
                { 'label': i18n.getLocalesTranslations('literal.counter'), value: 'counter' },
                { 'label': i18n.getLocalesTranslations('literal.projects'), value: 'projects' },
                { 'label': i18n.getLocalesTranslations('literal.campaigns'), value: 'campaigns' },
                { 'label': i18n.getLocalesTranslations('literal.about'), value: 'about' },
                { 'label': { 'fr-FR': 'Citations', 'en-GB': 'Quotes', 'nl-NL': 'Quotes' }, value: 'quotes' }
              ]
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.how_it_works')
    }
  },
  {
    name: 'custom_homepage_internationalamnesty_campaigns',
    settings: {
      icon: 'flag',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'query',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.campaigns')
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.campaigns')
    }
  },
  {
    name: 'custom_homepage_internationalamnesty_about',
    settings: {
      icon: 'layout',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionMedia',
        model: 'media'
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'list_ul', 'link']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.about')
    }
  },
  {
    name: 'custom_homepage_internationalamnesty_quotes',
    settings: {
      icon: 'quote',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        content: {
          wrapper_title: 'name'
        },
        settings: {
          hide_label: true
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'avatar',
            content: {
              label: i18n.getLocalesTranslations('literal.avatar')
            },
            settings: {
              crop: true,
              size: { width: 95, height: 95 }
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'quote',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.quote')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'name',
            content: {
              label: i18n.getLocalesTranslations('literal.name')
            }
          }
        ]
      }
    ],
    content: {
      title: { 'fr-FR': 'Citations', 'en-GB': 'Quotes', 'nl-NL': 'Quotes' }
    }
  }
]
