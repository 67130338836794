import i18n from '@/vendors/i18n'
import Block from '@/classes/procedures/Block'

export default {
  name: 'project_accept_invite',
  content: {
    title: i18n.getLocalesTranslations('$procedure_section.type_project_accept_invite'),
    description: i18n.getLocalesTranslations('$procedure_section.type_project_accept_invite_description')
  },
  blocks: [
    Block.set({
      name: 'title',
      attributes: {
        title: i18n.getLocalesTranslations('$procedure_block.project_accept_invite_title')
      }
    }),
    Block.set({ name: 'project_invite' }),
    Block.set({
      name: 'text',
      attributes: {
        editor_content: i18n.getLocalesTranslations('$procedure_block.project_accept_invite_text')
      }
    })
  ]
}
