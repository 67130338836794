import i18n from '@/vendors/i18n'
import Section from '@/classes/procedures/Section'

const donation = {
  name: 'personal_infos',
  attributes: {
    back_btn: i18n.getLocalesTranslations('$procedure_step.button_back'),
    next_btn: i18n.getLocalesTranslations('$procedure_step.button_next')
  },
  sections: [
    Section.set({ name: 'transaction_amount' }),
    Section.set({ name: 'transaction_customer_informations' }),
    Section.set({ name: 'transaction_customer_address' }),
    Section.set({ name: 'transaction_payment' }),
    Section.set({ name: 'transaction_legal' })
  ],
  static_options: {
    icon: 'amount_choice',
    is_not_deletable: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_step.payment')
  }
}

export default donation
