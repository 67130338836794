import i18n from '@/vendors/i18n'

export default {
  name: 'customer_national_id',
  model: 'customer.national_id',
  type: 'input_text',
  settings: {
    icon: 'id_card',
    category: 'personal_informations',
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_customer_national_id')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      value: i18n.getLocalesTranslations('$block_customer_national_id.label_national_id'),
      content: {
        label: i18n.getLocalesTranslations('literal.question')
      }
    },
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.placeholder',
      value: i18n.getLocalesTranslations('$block_customer_national_id.placeholder_national_id'),
      content: {
        label: i18n.getLocalesTranslations('literal.placeholder')
      }
    },
    {
      path: 'molecules/ModuleInputTextarea',
      model: 'attributes.text',
      value: i18n.getLocalesTranslations('$block_customer_national_id.text_national_id'),
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'molecules/ModuleInputCheckboxMandatory',
      value: 'required',
      content: {
        label: i18n.getLocalesTranslations('$procedure_block.label_is_mandatory')
      }
    }
  ],
  is_allowed: {
    'procedure.category': ['donation']
  }
}
