const Qrcodes = () => import(/* webpackChunkName: 'Qrcodes' */'@/views/qrcodes/ViewQrcodes')
const NewQrcode = () => import(/* webpackChunkName: 'NewQrcode' */'@/views/qrcodes/ViewNewQrcode')
const Qrcode = () => import(/* webpackChunkName: 'Qrcode' */'@/views/qrcodes/ViewQrcode')

export default [
  {
    path: 'qrcodes',
    name: 'Qrcodes',
    component: Qrcodes,
    meta: {
      requiresAuth: true,
      requireFeature: ['twikey'],
      breadcrumb: [
        { label: 'literal.qr_codes', routeName: 'Qrcodes' }
      ],
      faq: ['qrcodes_what_are_the_twikey_qr_codes']
    }
  },
  {
    path: 'qrcodes/new',
    name: 'NewQrcode',
    component: NewQrcode,
    meta: {
      requiresAuth: true,
      requireFeature: ['twikey'],
      breadcrumb: [
        { label: 'literal.qr_codes', routeName: 'Qrcodes' },
        { label: 'literal.create_new_qr_code', routeName: 'NewQrcode' },
      ]
    }
  },
  {
    path: 'qrcode/:slug',
    name: 'Qrcode',
    component: Qrcode,
    props: true,
    meta: {
      requiresAuth: true,
      requireFeature: ['twikey'],
      breadcrumb: [
        { label: 'literal.qr_codes', routeName: 'Qrcodes' },
        { routeName: 'Qrcode' }
      ],
      navigation: {
        email: 'QrcodeEditEmail'
      }
    }
  }
]
