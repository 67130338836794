const Templates = () => import(/* webpackChunkName: 'Templates' */'@/views/templates/ViewTemplates')
const NewTemplate = () => import(/* webpackChunkName: 'NewTemplate' */'@/views/templates/ViewNewTemplate')
const Template = () => import(/* webpackChunkName: 'Template' */'@/views/templates/ViewTemplate')
const TemplateLayout = () => import(/* webpackChunkName: 'TemplateLayout' */'&/modules/templates/TemplateLayout')
const TemplateSettings = () => import(/* webpackChunkName: 'TemplateSettings' */'&/modules/templates/TemplateSettings')

const routes = [
  {
    path: 'templates',
    name: 'Templates',
    component: Templates,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.templates', routeName: 'Templates' }
      ]
    }
  },
  {
    path: 'templates/new',
    name: 'NewTemplate',
    component: NewTemplate,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.templates', routeName: 'Templates' },
        { label: 'literal.create_new_template', routeName: 'NewTemplate' }
      ]
    }
  },
  {
    path: 'templates/:id',
    name: 'Template',
    component: Template,
    props: true,
    redirect: { name: 'TemplateLayout' },
    children: [
      {
        path: '',
        name: 'TemplateLayout',
        component: TemplateLayout,
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { label: 'literal.templates', routeName: 'Templates' },
            { slot: 'Template', routeName: 'Template' }
          ]
        }
      },
      {
        path: 'settings',
        name: 'TemplateSettings',
        component: TemplateSettings,
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { label: 'literal.templates', routeName: 'Templates' },
            { slot: 'Template', routeName: 'Template' },
            { label: 'literal.settings', routeName: 'TemplateSettings' }
          ]
        }
      }
    ]
  }
]

export default routes
