import i18n from '@/vendors/i18n'
import Block from '@/classes/procedures/Block'

export default {
  name: 'transaction_amount',
  content: {
    title: i18n.getLocalesTranslations('$procedure_section.type_amount'),
    description: i18n.getLocalesTranslations('$procedure_section.type_amount_description')
  },
  options: {
    collapse: false
  },
  blocks: [
    Block.set({
      name: 'title',
      attributes: {
        title: i18n.getLocalesTranslations('$procedure_block.contribution_title')
      }
    }),
    Block.set({
      name: 'text',
      attributes: {
        editor_content: i18n.getLocalesTranslations('$procedure_block.contribution_text')
      }
    }),
    Block.set({ name: 'transaction_amount' })
  ]
}
