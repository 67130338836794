const ProcedureSettingsTitle = () => import(/* webpackChunkName: 'ProcedureSettingsTitle' */'@/views/procedures/ViewProcedureSettingsTitle')
const ProcedureSettingsStatus = () => import(/* webpackChunkName: 'ProcedureSettingsStatus' */'@/views/procedures/ViewProcedureSettingsStatus')
const ProcedureSettingsLinks = () => import(/* webpackChunkName: 'ProcedureSettingsLinks' */'@/views/procedures/ViewProcedureSettingsLinks')
const ProcedureSettingsPayments = () => import(/* webpackChunkName: 'ProcedureSettingsPayments' */'@/views/procedures/ViewProcedureSettingsPayments')
const ProcedureSettingsBranding = () => import(/* webpackChunkName: 'ProcedureSettingsBranding' */'@/views/procedures/ViewProcedureSettingsBranding')
const ProcedureSettingsRedirection = () => import(/* webpackChunkName: 'ProcedureSettingsRedirection' */'@/views/procedures/ViewProcedureSettingsRedirection')
const ProcedureSettingsRestrictions = () => import(/* webpackChunkName: 'ProcedureSettingsRestrictions' */'@/views/procedures/ViewProcedureSettingsRestrictions')
const ProcedureSettingsPayconiq = () => import(/* webpackChunkName: 'ProcedureSettingsPayconiq' */'@/views/procedures/ViewProcedureSettingsPayconiq')
const ProcedureSettingsReference = () => import(/* webpackChunkName: 'ProcedureSettingsReference' */'@/views/procedures/ViewProcedureSettingsReference')
const ProcedureSettingsBenefitings = () => import(/* webpackChunkName: 'ProcedureSettingsReference' */'@/views/procedures/ViewProcedureSettingsBenefitings')

const routesClient = [
  {
    path: 'procedures/:procedureSlug/settings/title',
    name: 'ClientProcedureSettingsTitle',
    component: ProcedureSettingsTitle,
    props: true,
    meta: {
      requiresManagerRole: true,
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { slot: 'Procedure', routeName: 'ClientProcedure' },
        { label: 'literal.form_title', routeName: 'ClientProcedureSettingsTitle' }
      ],
      navigation: {
        back: 'ClientProcedure'
      },
      faq: ['procedures_where_can_i_find_the_title_on_my_form']
    }
  },
  {
    path: 'procedures/:procedureSlug/settings/status',
    name: 'ClientProcedureSettingsStatus',
    component: ProcedureSettingsStatus,
    props: true,
    meta: {
      requiresManagerRole: true,
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { slot: 'Procedure', routeName: 'ClientProcedure' },
        { label: 'literal.status', routeName: 'ClientProcedureSettingsStatus' }
      ],
      navigation: {
        back: 'ClientProcedure',
        forms: 'ClientProcedures'
      }
    }
  },
  {
    path: 'procedures/:procedureSlug/settings/links',
    name: 'ClientProcedureSettingsLinks',
    component: ProcedureSettingsLinks,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { slot: 'Procedure', routeName: 'ClientProcedure' },
        { label: 'literal.form_links', routeName: 'ClientProcedureSettingsLinks' }
      ],
      navigation: {
        back: 'ClientProcedure'
      }
    }
  },
  {
    path: 'procedures/:procedureSlug/settings/payments',
    name: 'ClientProcedureSettingsPayments',
    component: ProcedureSettingsPayments,
    props: true,
    meta: {
      requiresManagerRole: true,
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { slot: 'Procedure', routeName: 'ClientProcedure' },
        { label: 'literal.payments', routeName: 'ClientProcedureSettingsPayments' }
      ],
      navigation: {
        back: 'ClientProcedure'
      },
      faq: ['procedures_how_do_i_setup_payment_settings_for_my_form']
    }
  },
  {
    path: 'procedures/:procedureSlug/settings/branding',
    name: 'ClientProcedureSettingsBranding',
    component: ProcedureSettingsBranding,
    props: true,
    meta: {
      requiresManagerRole: true,
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { slot: 'Procedure', routeName: 'ClientProcedure' },
        { label: 'literal.branding', routeName: 'ClientProcedureSettingsBranding' }
      ],
      navigation: {
        back: 'ClientProcedure'
      }
    }
  },
  {
    path: 'procedures/:procedureSlug/settings/reference',
    name: 'ClientProcedureSettingsReference',
    component: ProcedureSettingsReference,
    props: true,
    meta: {
      requiresManagerRole: true,
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { slot: 'Procedure', routeName: 'ClientProcedure' },
        { label: 'literal.reference', routeName: 'ClientProcedureSettingsReference' }
      ],
      navigation: {
        back: 'ClientProcedure'
      }
    }
  },
  {
    path: 'procedures/:procedureSlug/settings/payconiq-qr-code',
    name: 'ClientProcedureSettingsPayconiq',
    component: ProcedureSettingsPayconiq,
    props: true,
    meta: {
      requiresManagerRole: true,
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { slot: 'Procedure', routeName: 'ClientProcedure' },
        { label: 'literal.payconiq_qr_code', routeName: 'ClientProcedureSettingsPayconiq' }
      ],
      navigation: {
        back: 'ClientProcedure'
      },
      faq: [
        'procedures_what_are_the_qr_codes_with_payconiq',
        'procedures_how_to_use_the_payconiq_qr_codes'
      ]
    }
  },
  {
    path: 'procedures/:procedureSlug/settings/redirection',
    name: 'ClientProcedureSettingsRedirection',
    component: ProcedureSettingsRedirection,
    props: true,
    meta: {
      requiresManagerRole: true,
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { slot: 'Procedure', routeName: 'ClientProcedure' },
        { label: 'literal.redirection_link', routeName: 'ClientProcedureSettingsRedirection' }
      ],
      navigation: {
        back: 'ClientProcedure'
      }
    }
  },
  {
    path: 'procedures/:procedureSlug/settings/restrictions',
    name: 'ClientProcedureSettingsRestrictions',
    component: ProcedureSettingsRestrictions,
    props: true,
    meta: {
      requiresManagerRole: true,
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.forms', routeName: 'ClientProcedures' },
        { slot: 'Procedure', routeName: 'ClientProcedure' },
        { label: 'literal.restrictions', routeName: 'ClientProcedureSettingsRestrictions' }
      ],
      navigation: {
        back: 'ClientProcedure'
      }
    }
  }
]
const routesCampaigns = [
  {
    path: 'campaigns/:campaignSlug/procedures/:procedureSlug/settings/title',
    name: 'CampaignProcedureSettingsTitle',
    component: ProcedureSettingsTitle,
    props: true,
    meta: {
      requiresManagerRole: true,
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { label: 'literal.campaign_overview', routeName: 'Campaign' },
        { label: 'literal.forms', routeName: 'CampaignProcedures' },
        { slot: 'Procedure', routeName: 'CampaignProcedure' },
        { label: 'literal.form_title', routeName: 'CampaignProcedureSettingsTitle' }
      ],
      navigation: {
        back: 'CampaignProcedure'
      }
    }
  },
  {
    path: 'campaigns/:campaignSlug/procedures/:procedureSlug/settings/status',
    name: 'CampaignProcedureSettingsStatus',
    component: ProcedureSettingsStatus,
    props: true,
    meta: {
      requiresManagerRole: true,
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { label: 'literal.campaign_overview', routeName: 'Campaign' },
        { label: 'literal.forms', routeName: 'CampaignProcedures' },
        { slot: 'Procedure', routeName: 'CampaignProcedure' },
        { label: 'literal.status', routeName: 'CampaignProcedureSettingsStatus' }
      ],
      navigation: {
        back: 'CampaignProcedure',
        forms: 'CampaignProcedures'
      }
    }
  },
  {
    path: 'campaigns/:campaignSlug/procedures/:procedureSlug/settings/payments',
    name: 'CampaignProcedureSettingsPayments',
    component: ProcedureSettingsPayments,
    props: true,
    meta: {
      requiresManagerRole: true,
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { label: 'literal.campaign_overview', routeName: 'Campaign' },
        { label: 'literal.forms', routeName: 'CampaignProcedures' },
        { slot: 'Procedure', routeName: 'CampaignProcedure' },
        { label: 'literal.payments', routeName: 'CampaignProcedureSettingsPayments' }
      ],
      navigation: {
        back: 'CampaignProcedure'
      }
    }
  },
  {
    path: 'campaigns/:campaignSlug/procedures/:procedureSlug/settings/branding',
    name: 'CampaignProcedureSettingsBranding',
    component: ProcedureSettingsBranding,
    props: true,
    meta: {
      requiresManagerRole: true,
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { label: 'literal.campaign_overview', routeName: 'Campaign' },
        { label: 'literal.forms', routeName: 'CampaignProcedures' },
        { slot: 'Procedure', routeName: 'CampaignProcedure' },
        { label: 'literal.branding', routeName: 'CampaignProcedureSettingsBranding' }
      ],
      navigation: {
        back: 'CampaignProcedure'
      }
    }
  },
  {
    path: 'campaigns/:campaignSlug/procedures/:procedureSlug/settings/reference',
    name: 'CampaignProcedureSettingsReference',
    component: ProcedureSettingsReference,
    props: true,
    meta: {
      requiresManagerRole: true,
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { label: 'literal.campaign_overview', routeName: 'Campaign' },
        { label: 'literal.forms', routeName: 'CampaignProcedures' },
        { slot: 'Procedure', routeName: 'CampaignProcedure' },
        { label: 'literal.reference', routeName: 'CampaignProcedureSettingsReference' }
      ],
      navigation: {
        back: 'CampaignProcedure'
      }
    }
  },
  {
    path: 'campaigns/:campaignSlug/procedures/:procedureSlug/settings/benefitings',
    name: 'CampaignProcedureSettingsBenefitings',
    component: ProcedureSettingsBenefitings,
    props: true,
    meta: {
      requiresManagerRole: true,
      requiresAuth: true,
      requireFeature: ['benefitings'],
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { label: 'literal.campaign_overview', routeName: 'Campaign' },
        { label: 'literal.forms', routeName: 'CampaignProcedures' },
        { slot: 'Procedure', routeName: 'CampaignProcedure' },
        { label: 'literal.beneficiaries', routeName: 'CampaignProcedureSettingsBenefitings' }
      ],
      navigation: {
        back: 'CampaignProcedure'
      }
    }
  },
  {
    path: 'campaigns/:campaignSlug/procedures/:procedureSlug/settings/restrictions',
    name: 'CampaignProcedureSettingsRestrictions',
    component: ProcedureSettingsRestrictions,
    props: true,
    meta: {
      requiresManagerRole: true,
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { label: 'literal.campaign_overview', routeName: 'Campaign' },
        { label: 'literal.forms', routeName: 'CampaignProcedures' },
        { slot: 'Procedure', routeName: 'CampaignProcedure' },
        { label: 'literal.restrictions', routeName: 'CampaignProcedureSettingsRestrictions' }
      ],
      navigation: {
        back: 'CampaignProcedure'
      }
    }
  }
]

const routes = [
  ...routesClient,
  ...routesCampaigns
]

export default routes
