<template>
  <div class="nav__container">
    <div v-if="isLayout('back')">
      <nav class="nav nav__back">
        <router-link
          class="link--naked link__color--mg"
          :to="toRoute || { name: toRouteName || 'Home' }"
        >
          <fa-icon
            class="margin__r--6"
            :icon="['fas', 'long-arrow-alt-left']"
          />

          {{ t('literal.back') }}
        </router-link>
      </nav>
    </div>

    <div v-if="isLayout('cancel')">
      <nav class="nav nav__cancel">
        <router-link
          class="link--naked link__color--mg"
          :to="toRoute || { name: toRouteName || 'Home' }"
        >
          <fa-icon
            class="margin__r--6"
            :icon="['fas', 'times']"
          />

          {{ t('literal.cancel') }}
        </router-link>
      </nav>
    </div>

    <div v-if="isLayout('header')">
      <nav class="nav nav__header">
        <router-link :to="{ name: 'Home' }">
          <img
            :src="getImagePath('logo/mykoalect-raisenow-logo.svg')"
            alt="MyKoalect logo"
          >
        </router-link>

        <router-link :to="{ name: 'Profile' }">
          <user-profile-img
            :avatar="authUser?.avatar"
            size="3"
            :active-state="false"
          />
        </router-link>
      </nav>
    </div>
  </div>
</template>

<script setup>
import { toRef } from 'vue'
import { getImagePath } from '@/utils/utils'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import UserProfileImg from '&/atoms/UserProfileImg'
import { useI18n } from '@/vendors/i18n'

const props = defineProps({
  template: { type: String, default: 'back' },
  routeName: { type: String },
  route: { type: Object }
})

const { t } = useI18n()
const toRoute = toRef(props, 'route')
const toRouteName = toRef(props, 'routeName')

const { getAuthUser } = useAuthenticationStore()
const { authUser } = getAuthUser()

const isLayout = template => template === props.template
</script>

<style scoped lang="scss">
.nav {
  background: $white;
  padding: $padding__base * 1.5 $padding__base * 2;
  width: 100vw;
  height: 60px;
  position: fixed;
  z-index: 999;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__container {
    @include mq(md) {
      display: none;
    }
  }
}
</style>
