import { useClientStore } from '@/stores/ClientStore'
import { useLanguageStore } from '@/stores/LanguageStore'
import { storeToRefs } from 'pinia'

const Menu = () => import(/* webpackChunkName: 'Menu' */'@/views/standalone/ViewMenu')
const NotFound = () => import(/* webpackChunkName: 'Menu' */'@/views/standalone/ViewNotFound')

const routes = [
  {
    path: '',
    name: 'Home',
    meta: { requiresAuth: true },
    beforeEnter (to, from, next) {
      const { getClient } = useClientStore()
      const { client } = getClient()
      const { locale } = storeToRefs(useLanguageStore())

      if (client.value) {
        if (client.value.allowed_features.campaigns) {
          next({ name: 'Campaigns', params: { locale: locale.value } })
        } else if (client.value.allowed_features.donation_forms) {
          next({ name: 'ClientProcedures', params: { locale: locale.value } })
        } else {
          next({ name: 'Profile', params: { locale: locale.value } })
        }
      } else {
        next({ name: 'Login', params: { locale: locale.value } })
      }
    }
  },
  {
    path: 'menu',
    name: 'Menu',
    component: Menu,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'not-found',
    name: 'NotFound',
    component: NotFound,
    meta: {
      requiresAuth: true
    }
  }
]

export default routes
