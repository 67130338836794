import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_blindenzorglichtliefde',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionLogo'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.contact')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'address',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.address'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_line')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'line',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'phone_number',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.phone_number'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_phone_number')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'line',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'email',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.email'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_email')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'line',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'navigation',
      settings: {
        hide_label: true,
        layout: 'inline'
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_sublink')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.social_networks')
      }
    },
    {
      path: 'modules/pages/SectionNetworks'
    }
  ]
}
