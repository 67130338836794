import i18n from '@/vendors/i18n'

export default {
  name: 'custom_header_uzafoundation',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionLogo'
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'website'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'navigation',
      settings: {
        hide_label: true,
        layout: 'inline'
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.actions')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'actions',
      settings: {
        hide_label: true,
        layout: 'inline'
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_button')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.login_register_and_profile')
      }
    }
  ]
}
