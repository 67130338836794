import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_saintluc',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_1',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.logo')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'logo',
          settings: {
            hide_label: true,
            size: { width: 9999, height: 76 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.logo')
          }
        },
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_2',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.organizational_info')
      },
      components: [
        {
          path: 'modules/pages/SectionRepeater',
          model: 'address',
          settings: {
            layout: 'inline'
          },
          content: {
            label: i18n.getLocalesTranslations('literal.address'),
            btn_label: i18n.getLocalesTranslations('literal.add_new_line')
          },
          components: [
            {
              path: 'modules/pages/SectionInputText',
              model: 'line',
              settings: {
                hide_label: true
              }
            }
          ]
        },
        {
          path: 'modules/pages/SectionContainer',
          model: 'phone_number',
          content: {
            default_wrapper_title: i18n.getLocalesTranslations('literal.phone_number'),
            wrapper_title: 'value'
          },
          components: [
            {
              path: 'modules/pages/SectionInputText',
              model: 'label',
              content: {
                label: i18n.getLocalesTranslations('literal.label')
              }
            },
            {
              path: 'modules/pages/SectionInputText',
              model: 'value',
              content: {
                label: i18n.getLocalesTranslations('literal.phone_number')
              }
            }
          ]
        },
        {
          path: 'modules/pages/SectionContainer',
          model: 'email',
          content: {
            default_wrapper_title: i18n.getLocalesTranslations('literal.email'),
            wrapper_title: 'value'
          },
          components: [
            {
              path: 'modules/pages/SectionInputText',
              model: 'label',
              content: {
                label: i18n.getLocalesTranslations('literal.label')
              }
            },
            {
              path: 'modules/pages/SectionInputText',
              model: 'value',
              content: {
                label: i18n.getLocalesTranslations('literal.email')
              }
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'additional_info',
          content: {
            label: i18n.getLocalesTranslations('literal.additional_info')
          }
        },
        {
          path: 'modules/pages/SectionDivider',
          style: {
            divider: ['separator','separator__size--full', 'bg__color--vdw']
          }
        },
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.social_networks')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_3',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.actions')
      },
      components: [
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.menu')
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'nav',
          settings: {
            layout: 'inline',
            hide_label: true
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_link')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        },
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.buttons')
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'cta',
          content: {
            label: i18n.getLocalesTranslations('literal.call_to_action')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_4',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('$procedure_block.legals_title')
      },
      components: [
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'content',
          content: {
            label: i18n.getLocalesTranslations('literal.content')
          },
          settings: {
            configuration: ['bold', 'italic', 'underline']
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'nav',
          settings: {
            layout: 'inline',
          },
          content: {
            label: i18n.getLocalesTranslations('literal.menu'),
            btn_label: i18n.getLocalesTranslations('literal.add_new_link')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'copyright',
          content: {
            label: {
              'fr-FR': 'Copyright'
            }
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_5',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.partners')
      },
      components: [
        {
          path: 'modules/pages/SectionRepeater',
          model: 'partners',
          settings: {
            hide_label: true
          },
          content: {
            wrapper_title: 'link.text',
            btn_label: i18n.t('literal.add_new_partner')
          },
          components: [
            {
              path: 'modules/pages/SectionImageUpload',
              model: 'logo',
              settings: {
                crop: false,
                size: { width: 9999, height: 50 }
              }
            },
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              content: {
                label: i18n.t('literal.website')
              }
            }
          ]
        }
      ]
    }
  ]
}