import i18n from '@/vendors/i18n'
import Block from '@/classes/procedures/Block'

export default {
  name: 'project_informations',
  content: {
    title: i18n.getLocalesTranslations('$procedure_section.type_project_informations'),
    description: i18n.getLocalesTranslations('$procedure_section.type_project_informations_description')
  },
  options: {
    collapse: false
  },
  blocks: [
    Block.set({
      name: 'title',
      attributes: {
        title: i18n.getLocalesTranslations('$procedure_block.project_informations_title')
      }
    }),
    Block.set({ name: 'project_name' }),
    Block.set({ name: 'project_goal_amount' }),
    Block.set({ name: 'project_end_date' })
  ]
}
