import i18n from '@/vendors/i18n'

let Link = class Link {
  constructor ({ show, url, icon, text, target_url, target_id, type } = {}) {
    this.show = typeof show == 'boolean' ? show : true
    this.url = url || i18n.getLocalesTranslations()
    this.icon = icon || null
    this.text = text || i18n.getLocalesTranslations()
    this.target_url = target_url || null
    this.target_id = target_id || null
    this.type = type || null
  }
}

export default Link
