//import i18n from '@/vendors/i18n'

export default {
  name: 'custom_homepage_sfov',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [],
  content: {
    title: {
      'en-GB': 'Streekfonds Oost Vlanderen',
      'fr-FR': 'Streekfonds Oost Vlanderen',
      'nl-NL': 'Streekfonds Oost Vlanderen'
    }
  }
}
