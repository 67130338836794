import i18n from '@/vendors/i18n'
import Block from '@/classes/procedures/Block'

export default {
  name: 'iban_domiciliation',
  content: {
    title: i18n.getLocalesTranslations('$procedure_section.type_iban_domiciliation'),
    description: i18n.getLocalesTranslations('$procedure_section.type_iban_domiciliation_description')
  },
  options: {
    collapse: false
  },
  blocks: [
    Block.set({
      name: 'title',
      attributes: {
        title: i18n.getLocalesTranslations('$procedure_block.iban_domiciliation_title')
      }
    }),
    Block.set({ name: 'informations_manual_domiciliation_amount' }),
    Block.set({ name: 'divider' }),
    Block.set({ name: 'informations_manual_domiciliation_bank_account_owner_name' }),
    Block.set({ name: 'informations_manual_domiciliation_iban' }),
    Block.set({ name: 'informations_manual_domiciliation_bic' })
  ]
}

