import i18n from '@/vendors/i18n'

export default [
  {
    name: 'custom_homepage_allezchantez_banner',
    settings: {
      icon: 'image',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.background')
        },
        model: 'background',
        settings: {
          crop: false,
          size: { width: 2000, height: 9999 }
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      }
    ],
    content: {
      title: {
        'en-GB': 'Banner',
        'fr-FR': 'Bannière',
        'nl-NL': 'Banier'
      }
    }
  },
  {
    name: 'custom_homepage_allezchantez_description',
    settings: {
      icon: 'text',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      }
    ],
    content: {
      title: {
        'en-GB': 'Explanatory text',
        'fr-FR': 'Texte explicatif',
        'nl-NL': 'Verklarende tekst'
      }
    }
  },
  {
    name: 'custom_homepage_allezchantez_stats',
    settings: {
      icon: 'love_hand',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'goal',
        content: {
          default_wrapper_title: {
            'fr-FR': 'Objectif 2023',
            'en-GB': 'Goal 2023',
            'nl-NL': 'Doel 2023'
          },
          wrapper_title: 'title'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'stat_1',
            content: {
              label: {
                'en-GB': 'Number',
                'fr-FR': 'Number',
                'nl-NL': 'Number'
              }
            }
          },
          {
            path: 'modules/pages/SectionDivider',
            style: {
              divider: ['separator', 'separator__size--full', 'bg__color--vdw']
            }
          },
          {
            path: 'modules/pages/SectionInputAmount',
            model: 'stat_2',
            content: {
              label: {
                'en-GB': 'Offline donations in euro',
                'fr-FR': 'Offline donations in euro',
                'nl-NL': 'Offline donations in euro'
              }
            }
          },
          {
            path: 'modules/pages/SectionSelectForms',
            model: 'forms_2',
            content: {
              label: {
                'en-GB': 'Select one or multiple forms',
                'fr-FR': 'Select one or multiple forms',
                'nl-NL': 'Select one or multiple forms'
              }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label_2',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionDivider',
            style: {
              divider: ['separator', 'separator__size--full', 'bg__color--vdw']
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label_3_front',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionInputDate',
            model: 'stat_3',
            content: {
              label: {
                'en-GB': 'Date',
                'fr-FR': 'Date',
                'nl-NL': 'Date'
              }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label_3_back',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'impact',
        content: {
          default_wrapper_title: {
            'fr-FR': 'Impact depuis le début',
            'en-GB': 'Impact since the start',
            'nl-NL': 'Impact sinds de start'
          },
          wrapper_title: 'title'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionDivider',
            style: {
              divider: ['separator', 'separator__size--full', 'bg__color--vdw']
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'stat_1',
            content: {
              label: {
                'en-GB': 'Number',
                'fr-FR': 'Number',
                'nl-NL': 'Number'
              }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label_1',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionDivider',
            style: {
              divider: ['separator', 'separator__size--full', 'bg__color--vdw']
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'stat_2',
            content: {
              label: {
                'en-GB': 'Number',
                'fr-FR': 'Number',
                'nl-NL': 'Number'
              }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label_2',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionDivider',
            style: {
              divider: ['separator', 'separator__size--full', 'bg__color--vdw']
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'stat_3',
            content: {
              label: {
                'en-GB': 'Number',
                'fr-FR': 'Number',
                'nl-NL': 'Number'
              }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label_3',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionDivider',
            style: {
              divider: ['separator', 'separator__size--full', 'bg__color--vdw']
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'stat_4',
            content: {
              label: {
                'en-GB': 'Number',
                'fr-FR': 'Number',
                'nl-NL': 'Number'
              }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label_4',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'cost',
        content: {
          default_wrapper_title: {
            'fr-FR': 'Coût pour 1 an de chant à Gand',
            'en-GB': 'Cost for 1 year of singing in Ghent',
            'nl-NL': 'Kostenplaatje voor 1 jaar zingen in Gent'
          },
          wrapper_title: 'title'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'number',
            content: {
              label: {
                'en-GB': 'Number',
                'fr-FR': 'Number',
                'nl-NL': 'Number'
              }
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: {
                'en-GB': 'Chart',
                'fr-FR': 'Graphique',
                'nl-NL': 'Grafiek'
              }
            },
            model: 'chart',
            settings: {
              crop: false,
              size: { width: 280, height: 9999 }
            }
          },
          {
            path: 'modules/pages/SectionRepeater',
            model: 'categories',
            content: {
              label: {
                'fr-FR': 'Catégories du graphique',
                'en-GB': 'Chart categories',
                'nl-NL': 'Grafiekcategorieën',
              },
              wrapper_title: 'label',
              btn_label: {
                'fr-FR': 'Ajouter une catégorie',
                'en-GB': 'Add a category',
                'nl-NL': 'Voeg een categorie toe',
              },
            },
            components: [
              {
                path: 'modules/pages/SectionImageUpload',
                content: {
                  label: {
                    'fr-FR': 'Couleur de la catégorie',
                    'en-GB': 'Category color',
                    'nl-NL': 'Categorie kleur',
                  }
                },
                model: 'image',
                settings: {
                  crop: false,
                  size: { width: 20, height: 9999 }
                }
              },
              {
                path: 'modules/pages/SectionInputText',
                model: 'label',
                content: {
                  label: i18n.getLocalesTranslations('literal.label')
                }
              }
            ]
          }
        ]
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'button',
        content: {
          label: i18n.getLocalesTranslations('literal.button')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      }
    ],
    content: {
      title: {
        'en-GB': 'Everyone gives what they can afford',
        'fr-FR': 'Chacun donne ce qu\'il peut se permettre',
        'nl-NL': 'Iedereen geeft wat ie missen kan'
      }
    }
  },
  {
    name: 'custom_homepage_allezchantez_about',
    settings: {
      icon: 'text',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        },
        model: 'image',
        settings: {
          crop: true,
          size: { width: 428, height: 428 }
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      }
    ],
    content: {
      title: {
        'en-GB': 'About Allez, Chantez!',
        'fr-FR': 'A propos de Allez, Chantez!',
        'nl-NL': 'Over Allez, Chantez!'
      }
    }
  },
  {
    name: 'custom_homepage_allezchantez_testimonials',
    settings: {
      icon: 'quote',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'testimonials',
        content: {
          label: {
            'fr-FR': 'Témoignages',
            'en-GB': 'Testimonials',
            'nl-NL': 'Getuigenissen',
          },
          wrapper_title: 'author',
          btn_label: {
            'fr-FR': 'Ajouter un témoignage',
            'en-GB': 'Add a testimonial',
            'nl-NL': 'Voeg een getuigenis toe',
          },
        },
        components: [
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'testimonial',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            },
            content: {
              label: {
                'fr-FR': 'Témoignage',
                'en-GB': 'Testimonial',
                'nl-NL': 'Testimonial',
              }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'author',
            content: {
              label: {
                'fr-FR': 'Auteur',
                'en-GB': 'Author',
                'nl-NL': 'Author',
              }
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: {
                'fr-FR': 'Photo',
                'en-GB': 'Picture',
                'nl-NL': 'Picture',
              }
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 217, height: 217 }
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            }
          }
        ]
      }
    ],
    content: {
      title: {
        'en-GB': 'Our stories',
        'fr-FR': 'Nos histoire',
        'nl-NL': 'Onze verhalen'
      }
    }
  },
  {
    name: 'custom_homepage_allezchantez_goal',
    settings: {
      icon: 'target',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      },
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        },
        model: 'image',
        settings: {
          crop: true,
          size: { width: 428, height: 428 }
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'button',
        content: {
          label: i18n.getLocalesTranslations('literal.button')
        }
      }
    ],
    content: {
      title: {
        'en-GB': 'Our dream',
        'fr-FR': 'Notre rêve',
        'nl-NL': 'Onze droom'
      }
    }
  },
  {
    name: 'custom_homepage_allezchantez_picture',
    settings: {
      icon: 'image',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        },
        model: 'image',
        settings: {
          crop: false,
          size: { width: 2400, height: 9999 }
        }
      }
    ],
    content: {
      title: {
        'en-GB': 'Picture',
        'fr-FR': 'Image',
        'nl-NL': 'Afbeelding'
      }
    }
  }
]