let DomainName = class DomainName {
  constructor ({ name, type, is_default, custom } = {}) {
    this.name = name || null
    this.type = type || null
    this.default = is_default || false
    this.custom = custom || false
  }

  database = () => {
    return {
      name: this.name,
      type: this.type,
      default: this.default,
      custom: this.custom
    }
  }
}

export default DomainName
