<template>
  <ul class="list">
    <li
      class="list__item"
      v-if="allowedCampaigns"
    >
      <router-link
        class="list__link"
        :class="{'active': isActive('Campaigns') }"
        :to="{ name: 'Campaigns', params: { locale } }"
        @click="track('Campaigns')"
      >
        <fa-icon
          class="icon"
          fixed-width
          :icon="['fal', 'flag']"
        />

        <span class="list__label">
          {{ t('literal.campaigns') }}
        </span>
      </router-link>
    </li>

    <li
      class="list__item"
      v-if="allowedDonationForms"
    >
      <router-link
        class="list__link"
        :class="{'active': isActive('ClientProcedures') }"
        :to="{ name: 'ClientProcedures', params: { locale } }"
        @click="track('Procedures')"
      >
        <fa-icon
          class="icon"
          fixed-width
          :icon="['fal', 'poll-h']"
        />

        <span class="list__label">
          {{ t('literal.forms') }}
        </span>
      </router-link>
    </li>

    <li
      class="list__item d-none d-md-block"
      v-if="allowedTwikey"
    >
      <router-link
        class="list__link"
        :class="{'active': isActive('Qrcodes') }"
        :to="{ name: 'Qrcodes', params: { locale } }"
      >
        <fa-icon
          class="icon"
          fixed-width
          :icon="['fal', 'qrcode']"
        />

        <span class="list__label">
          {{ t('literal.qr_codes') }}
        </span>
      </router-link>
    </li>

    <li
      class="list__item d-none d-md-block"
      v-if="allowedBenefitings"
    >
      <router-link
        class="list__link"
        :class="{'active': isActive('Benefitings') }"
        :to="{ name: 'Benefitings', params: { locale } }"
      >
        <fa-icon
          class="icon"
          fixed-width
          :icon="['fal', 'hand-holding-heart']"
        />

        <span class="list__label">
          {{ t('literal.benefiting') }}
        </span>
      </router-link>
    </li>

    <li class="list__item d-none d-md-block">
      <router-link
        class="list__link"
        :class="{'active': isActive('Pages') }"
        :to="{ name: 'Pages', params: { locale } }"
        @click="track('Pages')"
      >
        <fa-icon
          class="icon"
          fixed-width
          :icon="['fal', 'file']"
        />

        <span class="list__label">
          {{ t('literal.pages') }}
        </span>
      </router-link>
    </li>

    <li
      class="list__item d-none d-md-block"
      v-if="allowedCampaigns"
    >
      <router-link
        class="list__link"
        :class="{'active': isActive('Templates') }"
        :to="{ name: 'Templates', params: { locale } }"
        @click="track('Templates')"
      >
        <fa-icon
          class="icon"
          fixed-width
          :icon="['fal', 'table-layout']"
        />

        <span class="list__label">
          {{ t('literal.templates') }}
        </span>
      </router-link>
    </li>

    <li class="list__separator" />

    <li class="list__item">
      <router-link
        class="list__link"
        :class="{'active': isActive('Data') }"
        :to="{ name: 'Data', params: { locale } }"
        @click="track('Data')"
      >
        <fa-icon
          class="icon"
          fixed-width
          :icon="['fal', 'table']"
        />

        <span class="list__label">
          {{ t('$sidebar_menu.data') }}
        </span>
      </router-link>
    </li>

    <li class="list__item">
      <router-link
        class="list__link"
        :class="{'active': isActive('Transactions') }"
        :to="{ name: 'Transactions', params: { locale } }"
        @click="track('Transactions')"
      >
        <fa-icon
          class="icon"
          fixed-width
          :icon="['fal', 'money-bill']"
        />

        <span class="list__label">
          {{ t('literal.transactions') }}
        </span>
      </router-link>
    </li>

    <li class="list__item d-block d-md-none">
      <router-link
        class="list__link"
        :class="{'active': $route.name && $route.name.startsWith('Menu') }"
        :to="{ name: 'Menu', params: { locale } }"
      >
        <fa-icon
          class="icon"
          fixed-width
          :icon="['fal', 'bars']"
        />

        <span class="list__label">
          {{ t('$sidebar_menu.more') }}
        </span>
      </router-link>
    </li>

    <li class="list__item d-none d-md-block">
      <router-link
        class="list__link"
        :class="{'active': isActive('Users') || isActive('Users')}"
        :to="{ name: 'Users', params: { locale } }"
        @click="track('Users')"
      >
        <fa-icon
          class="icon"
          fixed-width
          :icon="['fal', 'users']"
        />

        <span class="list__label">
          {{ t('literal.users') }}
        </span>
      </router-link>
    </li>

    <li
      class="list__item d-none d-md-block"
      v-if="allowedCampaigns"
    >
      <router-link
        class="list__link"
        :class="{'active': isActive('Projects') }"
        :to="{ name: 'Projects', params: { locale } }"
      >
        <fa-icon
          class="icon"
          fixed-width
          :icon="['fal', 'bookmark']"
        />

        <span class="list__label">
          {{ t('$sidebar_menu.projects') }}
        </span>
      </router-link>
    </li>

    <li
      class="list__separator"
      v-if="isManager"
    />

    <li
      class="list__item d-none d-md-block"
      v-if="isManager"
    >
      <router-link
        class="list__link"
        :class="{ 'active': isActive('Reports') }"
        :to="{ name: 'Reports', params: { locale } }"
      >
        <fa-icon
          class="icon"
          fixed-width
          :icon="['fal', 'file-chart-column']"
        />

        <span class="list__label">
          {{ t('literal.reports') }}
        </span>
      </router-link>
    </li>

    <li
      class="list__separator"
      v-if="isAccountant"
    />

    <li
      class="list__item d-none d-md-block"
      v-if="isAccountant"
    >
      <router-link
        class="list__link"
        :class="{'active': isActive('Settings') }"
        :to="{ name: 'Settings', params: { locale } }"
        @click="track('Settings')"
      >
        <fa-icon
          class="icon"
          fixed-width
          :icon="['fal', 'cog']"
        />

        <span class="list__label">
          {{ t('$sidebar_menu.settings') }}
        </span>
      </router-link>
    </li>
  </ul>
</template>

<script setup>
import { get as _get } from 'lodash'
import { useI18n } from '@/vendors/i18n'
import { useTracking } from '@/vendors/tracking'
import { useRoles } from '@/composables/app/useRoles'
import { useAllowedFeatures } from '@/composables/app/useAllowedFeatures'
import { useRoute } from 'vue-router'

const { allowedDonationForms, allowedCampaigns, allowedTwikey, allowedBenefitings } = useAllowedFeatures()
const { isAccountant, isManager } = useRoles()
const { t, locale } = useI18n()
const tracking = useTracking()
const route = useRoute()

const track = name => tracking.navigation.item({ name })
const isActive = routeName => _get(route, 'meta.breadcrumb[0].routeName') === routeName || route.name === routeName
</script>

<style scoped lang="scss">
.list {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mq(sm) {
    justify-content: space-around;
  }

  @include mq(md) {
    display: block;
  }

  &__separator {
    width: 100%;
    height: 1px;
    background: rgba($dg, .1);
    margin: calc($margin__base / 2) 0;
    display: none;

    @include mq(md) {
      display: block;
    }
  }

  &__item {
    width: 25%;

    @include mq(sm) {
      width: 100%;
    }

    &:not(:first-child) {
      @include mq(md) {
        margin-top: $margin__base;
      }
    }
  }

  &__link {
    font-size: rem(12px);
    font-weight: normal;
    display: block;
    transition: all $transition__duration;
    padding: $padding__base * 1.5 0;
    position: relative;

    &:hover,
    &:focus,
    &.active {
      color: $color--secondary;
    }

    &.active:before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $color--secondary;

      @include mq(md) {
        display: none;
      }
    }

    @include mq(md) {
      width: 100%;
      padding: $padding__base;
      border-radius: $radius__buttons;
      font-size: rem(14px);

      &:hover,
      &:focus,
      &.active {
        color: $color--secondary;
        background-color: tint($color--secondary, 90%);
      }
    }

    .icon {
      font-size: rem(20px);
      display: block;
      margin: 0 auto calc($margin__base / 2) auto;

      @include mq(md) {
        font-size: rem(16px);
        display: inline-block;
        margin: 0 $margin__base 0 0;
      }
    }
  }

  &__label {
    display: none;
    text-align: center;

    @media (min-width: 450px) {
      display: block;
    }

    @include mq(md) {
      display: inline;
    }
  }
}
</style>
