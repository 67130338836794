import i18n from '@/vendors/i18n'

export default {
  name: 'project_goal_amount',
  model: 'actions_data.project.fixed_goal_amount',
  type: 'input_amount',
  settings: {
    icon: 'amount',
    category: 'project_fields',
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_customer_project_goal_amount')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      value: i18n.getLocalesTranslations('$block.project_goal_amount_label'),
      content: {
        label: i18n.getLocalesTranslations('literal.label')
      }
    },
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.placeholder',
      value: i18n.getLocalesTranslations('$block.project_goal_amount_placeholder'),
      content: {
        label: i18n.getLocalesTranslations('literal.placeholder')
      }
    },
    {
      path: 'molecules/ModuleInputCheckboxMandatory',
      value: 'required',
      content: {
        label: i18n.getLocalesTranslations('$procedure_block.label_is_mandatory')
      }
    },
    { path: 'molecules/ModuleDivider' },
    { path: 'modules/procedures/BlockProjectGoalAmount' }
  ],
  is_allowed: {
    'procedure.actions': ['project']
  }
}
