import { mixpanel } from '@/vendors/integrations'

export default class {
  static new = () => {
    mixpanel.track('Clicked New proceduren')
  }

  static view = () => {
    mixpanel.track('Visited New procedure')
  }

  static created = ({ procedure }) => {
    mixpanel.track('Clicked Create new procedure', {
      'Id': procedure.id,
      'Slug': procedure.slug,
      'Category': procedure.category,
      'Status': procedure.status,
      'Created At': procedure.created_at
    })
  }
}
