import i18n from '@/vendors/i18n'

export default {
  name: 'custom_header_unicefbelgique',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionContainer',
      model: 'logo',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.logo')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'stacked',
          content: {
            label: {
              'en-GB': 'Stacked',
              'fr-FR': 'Empilé',
              'nl-NL': 'Gestapeld'
            }
          },
          settings: {
            crop: false,
            size: { width: 9999, height: 60 }
          }
        },
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'inline',
          content: {
            label: {
              'en-GB': 'Inline',
              'fr-FR': 'En ligne',
              'nl-NL': 'In lijn'
            }
          },
          settings: {
            crop: false,
            size: { width: 9999, height: 60 }
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'en-GB': 'Top navigation',
          'fr-FR': 'Navigation supérieure',
          'nl-NL': 'Navigatie bovenaan'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'top_navigation',
      settings: {
        hide_label: true,
        layout: 'inline'
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.login_register_and_profile')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.language_switch')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'navigation',
      settings: {
        hide_label: true,
        layout: 'inline'
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'navigation',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_sublink')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        },
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.call_to_action')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'cta',
      content: {
        label: i18n.getLocalesTranslations('literal.call_to_action')
      }
    }
  ]
}
