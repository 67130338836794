import i18n from '@/vendors/i18n'

export default {
  name: 'divider',
  settings: {
    icon: 'divider',
    category: 'texts',
    is_reusable: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_divider')
  }
}
