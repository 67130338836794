import i18n from '@/vendors/i18n'
import Section from '@/classes/pages/Section'

let Page = class Page {
  constructor ({ id, title, description, image, remote_image_url, status, category, display_mode, main, reserved, options, path, sections, created_at } = {}) {
    this.id = id || null
    this.title = title || i18n.getLocalesTranslations()
    this.description = description || i18n.getLocalesTranslations()
    this.image = image || {}
    this.remote_image_url = remote_image_url || null
    this.status = status || null
    this.category = category || null
    this.display_mode = display_mode || null
    this.main = main || false
    this.reserved = reserved || false
    this.options = options || {}
    this.path = path || null
    this.sections = sections || []
    this.created_at = created_at || null
  }

  static = () => {
    this.sections = Array.isArray(this.sections) ? this.sections.map(section => new Section(section).static()) : this.sections
    return this
  }

  database = () => {
    this.sections = Array.isArray(this.sections) ? this.sections.map(section => new Section(section).database()) : this.sections
    return this
  }
}

export default Page
