import i18n from '@/vendors/i18n'

export default {
  name: 'transaction_customer_birthday',
  model: 'customer.birthday',
  type: 'input_date',
  settings: {
    icon: 'input_date',
    category: 'personal_informations',
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_customer_birthday')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      value: i18n.getLocalesTranslations('$block_customer_birthday.label_birthday'),
      content: {
        label: i18n.getLocalesTranslations('literal.question')
      }
    },
    {
      path: 'molecules/ModuleInputSelect',
      model: 'attributes.default_option',
      options: [
        {
          label: i18n.getLocalesTranslations('literal.no_default_value'),
          value: null
        },
        {
          label: i18n.getLocalesTranslations('literal.date_of_the_day'),
          value: 'today'
        },
        {
          label: i18n.getLocalesTranslations('literal.specific_date'),
          value: 'specific_date'
        }
      ],
      content: {
        label: i18n.getLocalesTranslations('literal.default_value')
      }
    },
    {
      path: 'molecules/ModuleInputDatepicker',
      model: 'attributes.default_value',
      conditions: [
        {
          logic_operator: 'and',
          model: 'attributes.default_option',
          value: 'specific_date',
          comparison_operator: 'eq'
        }
      ]
    },
    {
      path: 'molecules/ModuleInputCheckboxMandatory',
      value: 'required',
      content: {
        label: i18n.getLocalesTranslations('$procedure_block.label_is_mandatory')
      }
    }
  ]
}
