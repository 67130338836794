export default [
  {
    key: 'campaigns_how_to_get_started'
  },
  {
    key: 'campaigns_how_to_manage_your_campaign_settings'
  },
  {
    key: 'campaigns_how_to_enable_strava_in_your_campaigns',
    is_allowed: { 'route.query.tab': ['sporting-activities'] }
  },
  {
    key: 'campaigns_what_are_the_forms'
  },
  {
    key: 'campaigns_how_can_i_build_a_form'
  },
  {
    key: 'campaigns_what_are_the_emails'
  },
  {
    key: 'campaigns_can_i_change_the_sender_adress_of_the_automatic_emails'
  },
  {
    key: 'campaigns_what_are_the_activation_emails'
  },
  {
    key: 'campaigns_what_are_products'
  },
  {
    key: 'campaigns_how_can_i_use_products_in_my_campaign'
  },
  {
    key: 'campaigns_how_can_i_setup_the_project_page'
  },
  {
    key: 'campaigns_what_are_the_qr_codes_with_payconiq',
    is_allowed: { 'route.query.tab': ['payconiq'] }
  },
  {
    key: 'campaigns_how_to_use_the_payconiq_qr_codes',
    is_allowed: { 'route.query.tab': ['payconiq'] }
  }
]
