import i18n from '@/vendors/i18n'
import { projects_overview_options } from '@/configurations/general/components-default-data'

export default {
  name: 'custom_homepage_baluchon',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.banner')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      content: {
        label: i18n.getLocalesTranslations('literal.image')
      },
      model: 'banner.image',
      settings: {
        crop: false,
        size: { width: 595, height: 615 }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'banner.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'banner.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'banner.button'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.metrics')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'metrics',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'text',
        btn_label: i18n.getLocalesTranslations('literal.add_metric')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          content: {
            hide_label: true,
            label: i18n.getLocalesTranslations('literal.image')
          },
          model: 'icon',
          settings: {
            crop: false,
            size: { width: 40, height: 40 }
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'number',
          content: {
            label: i18n.getLocalesTranslations('literal.number')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text',
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          }
        },
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.projects')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'projects.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'projects.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'projects.query.campaign_id',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionSelect',
      model: 'projects.query.sort',
      value: 'created_at_first',
      settings: {
        options: projects_overview_options
      },
      content: {
        label: i18n.getLocalesTranslations('literal.sort_project_by')
      }
    },
    {
      path: 'modules/pages/SectionInputNumber',
      model: 'projects.query.per_page',
      value: 4,
      content: {
        label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'projects.button'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.goal')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'goal.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'goal.subtitle',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: {
          'nl-NL': 'Ondertitel',
          'fr-FR': 'Sous-titre',
          'en-GB': 'Subtitle'
        }
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'goal.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'goal.button_1'
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'goal.button_2'
    },
    {
      path: 'modules/pages/SectionImageUpload',
      content: {
        label: i18n.getLocalesTranslations('literal.image')
      },
      model: 'goal.image',
      settings: {
        crop: false,
        size: { width: 470, height: 496 }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.additional_info')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'infos',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'item',
        btn_label: i18n.getLocalesTranslations('literal.add_an_element')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'item',
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.description')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'description.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'description.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'description.button_1'
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'description.button_2'
    },
    {
      path: 'modules/pages/SectionImageUpload',
      content: {
        label: i18n.getLocalesTranslations('literal.image')
      },
      model: 'description.image',
      settings: {
        crop: false,
        size: { width: 470, height: 496 }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.quote')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'quotes.item',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'author',
        btn_label: i18n.getLocalesTranslations('literal.add_new_button')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'text',
          content: {
            label: i18n.getLocalesTranslations('literal.quote')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'author',
          content: {
            label: i18n.getLocalesTranslations('literal.author_name')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'quotes.button'
    }
  ],
  content: {
    title: {
      'en-GB': 'Baluchon',
      'fr-FR': 'Baluchon',
      'nl-NL': 'Baluchon'
    }
  }
}
