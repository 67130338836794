import i18n from '@/vendors/i18n'
import Section from '@/classes/procedures/Section'

const project_invite = {
  name: 'project_invitation',
  attributes: {
    back_btn: i18n.getLocalesTranslations('$procedure_step.button_back'),
    next_btn: i18n.getLocalesTranslations('literal.join_project')
  },
  sections: [
    Section.set({ name: 'project_accept_invite' })
  ],
  static_options: {
    icon: 'envelope',
    is_not_deletable: true
  },
  content: {
    title: i18n.getLocalesTranslations('literal.invite')
  }
}

export default project_invite
