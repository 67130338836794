import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_internationalamnesty',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.logo')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'column_1.logo',
      settings: {
        hide_label: true,
        crop: false,
        size: { width: 192, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionNetworks'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.contact')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'column_2.text',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'link']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.content')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.actions')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_3.links',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.links'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_phone_number')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.login')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_4.donation',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.donation_form')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'cta',
          content: {
            label: i18n.getLocalesTranslations('literal.button')
          }
        }
      ]
    }
  ]
}
