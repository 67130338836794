import i18n from '@/vendors/i18n'

export default {
  name: 'project_invite',
  model: 'actions_data.accept_invite.invite_token',
  settings: {
    icon: 'text',
    category: 'custom'
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_project_invite')
  },
  is_allowed: {
    'procedure.category': ['project-invite']
  },
  is_required: {
    'procedure.category': ['project-invite']
  }
}
