import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_jez',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionContainer',
      model: 'sponsors',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.sponsors')
      },
      components: [
        {
          path: 'modules/pages/SectionRepeater',
          model: 'items',
          settings: {
            hide_label: true
          },
          content: {
            wrapper_title: 'name',
            btn_label: i18n.getLocalesTranslations('literal.add_new_sponsor')
          },
          components: [
            {
              path: 'modules/pages/SectionImageUpload',
              model: 'image',
              settings: {
                crop: false,
                size: { width: 176, height: 61 }
              },
              content: {
                label: i18n.getLocalesTranslations('literal.logo')
              }
            },
            {
              path: 'modules/pages/SectionInputNumber',
              model: 'height',
              value: 60,
              settings: {
                min: 35,
                max: 70
              },
              content: {
                label: {
                  'nl-NL': 'Hoogte',
                  'en-GB': 'Height',
                  'fr-FR': 'Hauteur',
                }
              }
            },
            {
              path: 'modules/pages/SectionInputText',
              model: 'name',
              content: {
                label: i18n.getLocalesTranslations('literal.name')
              }
            },
            {
              path: 'modules/pages/SectionInputUrl',
              model: 'url',
              content: {
                label: i18n.getLocalesTranslations('literal.url')
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_1',
      content: {
        default_wrapper_title: { 'nl-NL': 'Colonne #1', 'en-GB': 'Column #1', 'fr-FR': 'Colonne #1' }
      },
      components: [
        {
          path: 'modules/pages/SectionContainer',
          model: 'logo',
          content: {
            default_wrapper_title: i18n.getLocalesTranslations('literal.logo')
          },
          components: [
            {
              path: 'modules/pages/SectionImageUpload',
              model: 'image',
              settings: {
                crop: false,
                size: { width: 9999, height: 76 }
              },
              content: {
                label: { 'nl-NL': ' ', 'en-GB': ' ', 'fr-FR': ' ' }
              }
            },
            {
              path: 'modules/pages/SectionInputUrl',
              model: 'url',
              content: {
                label: i18n.getLocalesTranslations('literal.url')
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_2',
      content: {
        default_wrapper_title: { 'nl-NL': 'Colonne #2', 'en-GB': 'Column #2', 'fr-FR': 'Colonne #2' }
      },
      components: [
        {
          path: 'modules/pages/SectionRepeater',
          model: 'navigation',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_link')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_3',
      content: {
        default_wrapper_title: { 'nl-NL': 'Colonne #3', 'en-GB': 'Column #3', 'fr-FR': 'Colonne #3' }
      },
      components: [
        {
          path: 'modules/pages/SectionRepeater',
          model: 'navigation',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_link')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_4',
      content: {
        default_wrapper_title: { 'nl-NL': 'Colonne #4', 'en-GB': 'Column #4', 'fr-FR': 'Colonne #4' }
      },
      components: [
        {
          path: 'modules/pages/SectionRepeater',
          model: 'navigation',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_link')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.social_networks')
      }
    }
  ]
}