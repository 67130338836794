export default [
  {
    key: 'transactions_how_can_i_export_transactions'
  },
  {
    key: 'transactions_how_can_i_stop_a_recurring_donation'
  },
  {
    key: 'transactions_can_i_make_a_refund_to_a_donor_via_mykoalect'
  }
]
