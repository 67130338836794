import Procedure from '@/classes/procedures/Procedure'
const resource = 'procedures'

export default {
  namespaced: true,
  state: {
    items: {},
    paginator: {}
  },
  getters: {},
  actions: {
    fetchClientProcedures: ({ dispatch }, { query, removeStoredItems = true } = {}) => dispatch(
      'fetchItems',
      {
        endpoint: '/mykoalect/client/procedures',
        resource,
        query,
        model: procedure => new Procedure(procedure),
        removeStoredItems
      },
      { root: true }
    ),

    fetchProcedure: ({ dispatch }, { slug }) => dispatch(
      'fetchItem',
      {
        endpoint: `/mykoalect/procedures/${slug}`,
        resource,
        model: procedure => new Procedure(procedure)
      },
      { root: true }
    ),

    patchProcedure: ({ dispatch }, { params, slug }) => dispatch(
      'patchItem',
      {
        endpoint: `/mykoalect/procedures/${slug}`,
        resource,
        params: {
          procedure: new Procedure(params).database()
        },
        model: procedure => new Procedure(procedure)
      },
      { root: true }
    )
  }
}
