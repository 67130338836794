import { computed } from 'vue'
import faq from '@/configurations/faq'

export function useFaq () {
  const questions = computed(() => faq.map(question => ({ ...question, question: `$faq.${question.key}`, link: `$faq.${question.key}_link` })))

  const getQuestion = key => questions.value.find(question => question.key === key)

  return {
    questions,
    getQuestion
  }
}
