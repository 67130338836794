const Reports = () => import(/* webpackChunkName: 'Reports' */'@/views/reports/ViewReports')
const ReportDownload = () => import(/* webpackChunkName: 'Reports' */'@/views/reports/ViewReportDownload')

const routes = [
  {
    path: 'reports',
    name: 'Reports',
    component: Reports,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.reports', routeName: 'Reports' }
      ]
    }
  },
  {
    path: 'reports/:id/download',
    name: 'ReportDownload',
    component: ReportDownload,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.reports', routeName: 'Reports' },
        { routeName: 'ReportDownload' }
      ]
    }
  }
]

export default routes
