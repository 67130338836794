import i18n from '@/vendors/i18n'
import { metrics_type_options, metrics_sporting_options, projects_overview_options } from '@/configurations/general/components-default-data'

export default {
  name: 'custom_rikolto_campaign',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    undeletable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.banner')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'banner.background',
      settings: {
        crop: true,
        size: { width: 1600, height: 640 }
      },
      content: {
        label: i18n.getLocalesTranslations('literal.background_image')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'banner.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'banner.content',
      content: {
        label: i18n.getLocalesTranslations('literal.content')
      },
      settings: {
        configuration: ['bold', 'italic', 'underline', 'link']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.goal')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'banner.goal.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'banner.goal.items',
      content: {
        wrapper_title: 'text',
        btn_label: {
          'fr-FR': 'Ajouter un but',
          'en-GB': 'Add a goal',
          'nl-NL': 'Voeg een doelstelling toe'
        }
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'text',
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.metrics')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'metrics.items',
      content: {
        wrapper_title: 'text',
        btn_label: i18n.getLocalesTranslations('literal.add_metric'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          value: 'contributors_count',
          settings: {
            options: metrics_type_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.metric')
          }
        },
        {
          path: 'modules/pages/SectionInputDate',
          model: 'date',
          content: {
            label: i18n.getLocalesTranslations('literal.date')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'date',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionSelect',
          model: 'sporting_statistics',
          value: 'total_distance',
          settings: {
            options: metrics_sporting_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.sporting_statistics')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'sporting_statistics',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text'
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'metrics.query',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
      },
      components: [
        {
          path: 'modules/pages/SectionSelectCampaigns',
          model: 'campaign_id',
          content: {
            label: i18n.getLocalesTranslations('literal.show_metrics_from_these_campaigns')
          }
        },
        {
          path: 'modules/pages/SectionSelectForms',
          model: 'procedure_id',
          content: {
            label: i18n.getLocalesTranslations('literal.show_metrics_from_these_forms')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Événements',
          'en-GB': 'Events',
          'nl-NL': 'Evenement'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputSwitch',
      model: 'events.show_illustration',
      content: {
        label: {
          'fr-FR': 'Afficher l\'illustration',
          'en-GB': 'Show illustration',
          'nl-NL': 'Toon afbeelding'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'events.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'events.content',
      content: {
        label: i18n.getLocalesTranslations('literal.content')
      },
      settings: {
        configuration: ['bold', 'italic', 'underline', 'link']
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'events.items',
      content: {
        wrapper_title: 'name',
        btn_label: {
          'fr-FR': 'Ajouter un événement',
          'en-GB': 'Add an event',
          'nl-NL': 'Voeg een evenement toe'
        }
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'background',
          settings: {
            crop: true,
            size: { width: 390, height: 390 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.background_image')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'name',
          content: {
            label: i18n.getLocalesTranslations('literal.name')
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'cta',
          content: {
            label: i18n.getLocalesTranslations('literal.call_to_action')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Actions',
          'en-GB': 'Actions',
          'nl-NL': 'Acties'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputSwitch',
      model: 'projects.show',
      content: {
        label: {
          'fr-FR': 'Afficher la section',
          'en-GB': 'Show section',
          'nl-NL': 'Toon sectie'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputSwitch',
      model: 'projects.show_illustration',
      content: {
        label: {
          'fr-FR': 'Afficher l\'illustration',
          'en-GB': 'Show illustration',
          'nl-NL': 'Toon afbeelding'
        }
      },
      conditions: [
        {
          logic_operator: 'and',
          model: 'projects.show',
          value: true,
          comparison_operator: 'eq'
        }
      ]
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'projects.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      },
      conditions: [
        {
          logic_operator: 'and',
          model: 'projects.show',
          value: true,
          comparison_operator: 'eq'
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'projects.query',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
      },
      components: [
        {
          path: 'modules/pages/SectionSelectCampaigns',
          model: 'campaign_id',
          content: {
            label: i18n.getLocalesTranslations('literal.campaigns')
          }
        },
        {
          path: 'modules/pages/SectionSelect',
          model: 'sort',
          value: 'created_at_first',
          settings: {
            options: projects_overview_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.sort_project_by')
          }
        },
        {
          path: 'modules/pages/SectionInputNumber',
          model: 'per_page',
          value: 8,
          content: {
            label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
          }
        }
      ],
      conditions: [
        {
          logic_operator: 'and',
          model: 'projects.show',
          value: true,
          comparison_operator: 'eq'
        }
      ]
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'projects.cta',
      content: {
        label: i18n.getLocalesTranslations('literal.call_to_action')
      },
      conditions: [
        {
          logic_operator: 'and',
          model: 'projects.show',
          value: true,
          comparison_operator: 'eq'
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Supporters',
          'en-GB': 'Supporters',
          'nl-NL': 'Supporters'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputSwitch',
      model: 'supporters.show',
      content: {
        label: {
          'fr-FR': 'Afficher la section',
          'en-GB': 'Show section',
          'nl-NL': 'Toon sectie'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputSwitch',
      model: 'supporters.show_illustration',
      content: {
        label: {
          'fr-FR': 'Afficher l\'illustration',
          'en-GB': 'Show illustration',
          'nl-NL': 'Toon afbeelding'
        }
      },
      conditions: [
        {
          logic_operator: 'and',
          model: 'supporters.show',
          value: true,
          comparison_operator: 'eq'
        }
      ]
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'supporters.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      },
      conditions: [
        {
          logic_operator: 'and',
          model: 'supporters.show',
          value: true,
          comparison_operator: 'eq'
        }
      ]
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'supporters.items',
      content: {
        wrapper_title: 'name',
        btn_label: {
          'fr-FR': 'Ajouter un supporter',
          'en-GB': 'Add a supporter',
          'nl-NL': 'Voeg een supporter toe'
        }
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'image',
          settings: {
            crop: true,
            size: { width: 414, height: 622 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'name',
          content: {
            label: i18n.getLocalesTranslations('literal.name')
          }
        }
      ],
      conditions: [
        {
          logic_operator: 'and',
          model: 'supporters.show',
          value: true,
          comparison_operator: 'eq'
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Galerie',
          'en-GB': 'Gallery',
          'nl-NL': 'Galerij'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputSwitch',
      model: 'gallery.show',
      content: {
        label: {
          'fr-FR': 'Afficher la section',
          'en-GB': 'Show section',
          'nl-NL': 'Toon sectie'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputSwitch',
      model: 'gallery.show_illustration',
      content: {
        label: {
          'fr-FR': 'Afficher l\'illustration',
          'en-GB': 'Show illustration',
          'nl-NL': 'Toon afbeelding'
        }
      },
      conditions: [
        {
          logic_operator: 'and',
          model: 'gallery.show',
          value: true,
          comparison_operator: 'eq'
        }
      ]
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'gallery.items',
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_image')
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          },
          model: 'image',
          settings: {
            crop: false,
            size: { width: 760, height: 9999 }
          }
        }
      ],
      conditions: [
        {
          logic_operator: 'and',
          model: 'gallery.show',
          value: true,
          comparison_operator: 'eq'
        }
      ]
    }
  ],
  content: {
    title: {
      'en-GB': 'Campaign',
      'fr-FR': 'Campagne',
      'nl-NL': 'Campagne'
    }
  }
}
