import i18n from '@/vendors/i18n'
import { metrics_sporting_options, metrics_type_options } from '@/configurations/general/components-default-data'
import { projects_overview_options } from '@/configurations/general/components-default-data'

export default {
  name: 'custom_homepage_lightfortheworld',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.banner')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      content: {
        label: i18n.getLocalesTranslations('literal.image')
      },
      model: 'banner.image',
      settings: {
        crop: false,
        size: { width: 1280, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'banner.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'banner.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'banner.button',
      content: {
        label: i18n.getLocalesTranslations('literal.button')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.metrics')
      }
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'metrics.campaign_id',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'metrics.items',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'text',
        btn_label: i18n.getLocalesTranslations('literal.add_metric'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          value: 'contributors_count',
          settings: {
            options: metrics_type_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.metric')
          }
        },
        {
          path: 'modules/pages/SectionInputDate',
          model: 'date',
          content: {
            label: i18n.getLocalesTranslations('literal.date')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'date',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionSelect',
          model: 'sporting_statistics',
          value: 'total_distance',
          settings: {
            options: metrics_sporting_options
          },
          content: {
            label: i18n.getLocalesTranslations('literal.sporting_statistics')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'sporting_statistics',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text'
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.steps')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'steps.items',
      settings: {
        hide_label: true
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_step')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'img_src',
          settings: {
            size: { width: 250, height: 250 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.step_title')
          },
          settings: {
            configuration: ['bold', 'italic', 'underline', 'heading_h1', 'heading_h2', 'align_left', 'align_center', 'align_right']
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          content: {
            label: i18n.getLocalesTranslations('literal.step_description')
          },
          settings: {
            configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'steps.cta',
      content: {
        label: i18n.getLocalesTranslations('literal.button')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.projects')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'projects.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'projects.query.campaign_id',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionSelect',
      model: 'projects.query.sort',
      value: 'created_at_first',
      settings: {
        options: projects_overview_options
      },
      content: {
        label: i18n.getLocalesTranslations('literal.sort_project_by')
      }
    },
    {
      path: 'modules/pages/SectionInputNumber',
      model: 'projects.query.per_page',
      value: 4,
      content: {
        label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'projects.cta',
      content: {
        label: i18n.getLocalesTranslations('literal.button')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'campaigns.items',
      settings: {
        hide_label: true
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_campaign')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'image',
          settings: {
            crop: false,
            size: { width: 630, height: 9999 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          settings: {
            configuration: ['bold', 'italic', 'underline']
          },
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'button',
          content: {
            label: i18n.getLocalesTranslations('literal.link')
          }
        }
      ]
    }
  ],
  content: {
    title: {
      'en-GB': 'Light for the World',
      'fr-FR': 'Light for the World',
      'nl-NL': 'Light for the World'
    }
  }
}
