export default [
  {
    key: 'settings_what_is_a_payment_provider_account_and_why_do_you_need_to_create_one_under_koalect'
  },
  {
    key: 'settings_can_i_link_different_bank_accounts_in_order_to_be_able_to_choose_on_which_bank_account_the_transactions_are_paid'
  },
  {
    key: 'settings_what_can_i_upload_to_match_my_branding'
  },
  {
    key: 'settings_can_i_upload_my_own_font'
  },
  {
    key: 'settings_how_to_add_a_custom_domain'
  },
  {
    key: 'settings_what_is_google_tag_manager'
  },
  {
    key: 'settings_what_is_a_facebook_app'
  },
  {
    key: 'settings_what_is_google_search_console'
  },
  {
    key: 'settings_how_can_i_give_my_colleague_access_to_mykoalect'
  }
]
