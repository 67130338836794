import i18n from '@/vendors/i18n'

export default {
  name: 'custom_header_paralympic',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.language_switch')
      }
    }
  ]
}
