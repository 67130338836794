import i18n from '@/vendors/i18n'
import Block from '@/classes/procedures/Block'

export default {
  name: 'transaction_business_donation',
  content: {
    title: i18n.getLocalesTranslations('$procedure_section.type_business'),
    description: i18n.getLocalesTranslations('$procedure_section.type_business_description')
  },
  options: {
    collapse: false
  },
  blocks: [
    Block.set({
      name: 'title',
      attributes: {
        title: i18n.getLocalesTranslations('$procedure_block.business_title')
      }
    }),
    Block.set({ name: 'transaction_customer_business_name' }),
    Block.set({ name: 'transaction_customer_business_vat' })
  ]
}
