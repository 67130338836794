import { mixpanel } from '@/vendors/integrations'

export default class {
  static new = () => {
    mixpanel.track('Clicked New campaign')
  }

  static view = () => {
    mixpanel.track('Visited New campaign procedure')
  }

  static created = ({ campaign }) => {
    mixpanel.track('Clicked Create new campaign', {
      'Campaign Id': campaign.id,
      'Slug': campaign.slug,
      'Status': campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1),
      'Start Date': campaign.start_date,
      'End Date': campaign.end_date
    })
  }
}
