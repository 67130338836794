import i18n from '@/vendors/i18n'

export default {
  name: 'sponsors',
  settings: {
    icon: 'sponsor'
  },
  components: [
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'text'
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'sponsors',
      content: {
        label: i18n.getLocalesTranslations('literal.sponsors'),
        wrapper_title: 'name',
        btn_label: i18n.getLocalesTranslations('literal.add_new_sponsor'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.sponsors')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'logo',
          settings: {
            crop: false,
            size: { width: 660, height: 440 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'name',
          content: {
            label: i18n.getLocalesTranslations('literal.sponsor_name')
          }
        },
        {
          path: 'modules/pages/SectionInputUrl',
          model: 'website',
          content: {
            label: i18n.getLocalesTranslations('literal.website_link')
          }
        }
      ]
    }
  ],
  style: {
    background: {
      image: i18n.getLocalesTranslations(),
      color: null,
      type: 'color'
    },
    text: {
      color: null
    }
  },
  content: {
    title: i18n.getLocalesTranslations('literal.sponsors')
  }
}
