import i18n from '@/vendors/i18n'
import { metrics_type_options, metrics_sporting_options, projects_overview_options } from '@/configurations/general/components-default-data'

export default [
  {
    name: 'homepage_belgizitvol_banner',
    settings: {
      icon: 'image',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        model: 'image',
        settings: {
          crop: true,
          size: { width: 1440, height: 900 }
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'sharing_links',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.sharing_links')
        },
        components: [
          {
            path: 'modules/pages/SectionSelect',
            model: 'social_networks',
            value: [],
            settings: {
              multiple: true,
              options: [{ value: 'facebook', label: 'Facebook' }, { value: 'messenger', label: 'Messenger' }, { value: 'x', label: 'X' }, { value: 'linkedin', label: 'Linkedin' }, { value: 'whatsapp', label: 'Whatsapp' }, { value: 'email', label: i18n.t('literal.email') }, { value: 'url', label: i18n.t('literal.url') }]
            },
            content: {
              label: i18n.getLocalesTranslations('literal.social_networks')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'email',
            content: {
              label: i18n.getLocalesTranslations('literal.email')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'x',
            content: {
              label: 'X'
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'whatsapp',
            content: {
              label: 'WhatsApp'
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.banner')
    }
  },
  {
    name: 'homepage_belgizitvol_map',
    settings: {
      icon: 'location',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'settings',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_campaigns')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'text_color']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.location')
    }
  },
  {
    name: 'homepage_belgizitvol_actions',
    settings: {
      icon: 'flag',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'settings',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.campaigns'),
              placeholder: i18n.getLocalesTranslations('literal.all_campaigns')
            }
          },
          {
            path: 'modules/pages/SectionSelectBenefitings',
            model: 'benefiting_id',
            content: {
              label: i18n.getLocalesTranslations('literal.beneficiaries'),
              placeholder: i18n.getLocalesTranslations('literal.all_beneficiaries')
            }
          },
          {
            path: 'modules/pages/SectionSelect',
            model: 'sort',
            value: 'created_at_first',
            settings: {
              options: projects_overview_options
            },
            content: {
              label: i18n.getLocalesTranslations('literal.sort_project_by')
            }
          },
          {
            path: 'modules/pages/SectionInputNumber',
            model: 'per_page',
            value: 4,
            content: {
              label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.actions')
    }
  },
  {
    name: 'homepage_belgizitvol_about',
    settings: {
      icon: 'text',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline', 'text_color', 'link']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.about')
    }
  },
  {
    name: 'homepage_belgizitvol_stats',
    settings: {
      icon: 'timer',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'settings',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_campaigns')
            }
          },
          {
            path: 'modules/pages/SectionSelectForms',
            model: 'procedure_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_forms')
            }
          },
          {
            path: 'modules/pages/SectionSelectBenefitings',
            model: 'benefiting_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_beneficiaries')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'metrics',
        content: {
          wrapper_title: 'text',
          btn_label: i18n.getLocalesTranslations('literal.add_metric'),
          default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
        },
        settings: {
          hide_label: true
        },
        components: [
          {
            path: 'modules/pages/SectionSelect',
            model: 'type',
            value: 'contributors_count',
            settings: {
              options: [...metrics_type_options, { value: 'benefitings_count', label: i18n.getLocalesTranslations('literal.beneficiaries') }, { value: 'custom', label: i18n.getLocalesTranslations('literal.custom') }]
            },
            content: {
              label: i18n.getLocalesTranslations('literal.metric')
            }
          },
          {
            path: 'modules/pages/SectionInputDate',
            model: 'date',
            content: {
              label: i18n.getLocalesTranslations('literal.date')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'date',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionSelect',
            model: 'sporting_statistics',
            value: 'total_distance',
            settings: {
              options: metrics_sporting_options
            },
            content: {
              label: i18n.getLocalesTranslations('literal.sporting_statistics')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'sporting_statistics',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'value',
            content: {
              label: i18n.getLocalesTranslations('literal.value')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'custom',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'text'
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.metrics')
    }
  },
  {
    name: 'homepage_belgizitvol_links',
    settings: {
      icon: 'sharing_links',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        content: {
          wrapper_title: 'cta.text',
          default_wrapper_title: i18n.getLocalesTranslations('literal.link')
        },
        settings: {
          hide_label: true
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'image',
            settings: {
              crop: true,
              size: { width: 450, height: 328 }
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.call_to_action')
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.links')
    }
  }
]