import i18n from '@/vendors/i18n'

export default {
  name: 'product_selection',
  model: 'actions_data.order.order_lines',
  settings: {
    icon: 'bag',
    category: 'orders',
    is_reusable: true,
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_product_selection')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      value: i18n.getLocalesTranslations(),
      content: {
        label: i18n.getLocalesTranslations('literal.selection_name_only_visible_in_recaps')
      }
    },
    { path: 'molecules/ModuleDivider' },
    {
      path: 'modules/procedures/BlockProductSelection',
      model: 'attributes.ids',
      value: []
    },
    { path: 'molecules/ModuleDivider' },
    { path: 'modules/procedures/BlockProductPerOrder' },
    { path: 'molecules/ModuleDivider' },
    {
      path: 'molecules/ModuleInputCheckboxMandatory',
      value: 'productRequired',
      content: {
        label: i18n.getLocalesTranslations('$procedure_block.label_is_mandatory')
      }
    }
  ],
  is_allowed: {
    'client.allowed_features': ['products']
  }
}
