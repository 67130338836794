const transaction = {
  name: 'transaction',
  attributes: {
    category: 'donation',
    is_subscription: false
  },
  parameters: {
    optional: false,
    statement_descriptor: null
  }
}

const fee = {
  name: 'fee',
  parameters: {
    optional: false,
    default_attributes: {}
  }
}

const create_invite = {
  name: 'create_invite',
  parameters: {
    optional: false,
    type: 'project',
    emails: []
  }
}

const accept_invite = {
  name: 'accept_invite',
  attributes: {
    invite_token: null
  },
  parameters: {
    optional: false
  }
}

const order = {
  name: 'order',
  parameters: {
    optional: false,
    products: [],
    default_attributes: {}
  }
}

const project = {
  name: 'project',
  attributes: {
    status: 'published'
  },
  parameters: {
    optional: false
  }
}

const recaptcha = {
  name: 'recaptcha',
  attributes: {
    recaptcha_response: null
  }
}

export default {
  transaction,
  fee,
  create_invite,
  accept_invite,
  order,
  project,
  recaptcha
}
