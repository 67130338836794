import i18n from '@/vendors/i18n'
import { projects_overview_options } from '@/configurations/general/components-default-data'

export default [
  {
    name: 'homepage_technopolis_banner',
    settings: {
      icon: 'image',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'content',
        content: {
          default_wrapper_title: {
            'fr-FR': 'Content',
            'en-GB': 'Content',
            'nl-NL': 'Content'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.background')
            },
            model: 'background',
            settings: {
              crop: false,
              size: { width: 708, height: 416 }
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'media',
        content: {
          default_wrapper_title: {
            'fr-FR': 'Media',
            'en-GB': 'Media',
            'nl-NL': 'Media'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image_src',
            settings: {
              crop: false,
              size: { width: 708, height: 416 }
            }
          },
          {
            path: 'modules/pages/SectionInputUrl',
            model: 'video_src',
            content: {
              label: {
                'fr-FR': 'URL Youtube',
                'en-GB': 'URL Youtube',
                'nl-NL': 'URL Youtube'
              }
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.banner')
    }
  },
  {
    name: 'homepage_technopolis_news',
    settings: {
      icon: 'newsfeed',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'news_1',
        content: {
          default_wrapper_title: {
            'fr-FR': 'News 1',
            'en-GB': 'News 1',
            'nl-NL': 'News 1'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionButton',
            model: 'link',
            content: {
              label: i18n.getLocalesTranslations('literal.link')
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: false,
              size: { width: 500, height: 9999 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.background')
            },
            model: 'background',
            settings: {
              crop: false,
              size: { width: 500, height: 9999 }
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'news_2',
        content: {
          default_wrapper_title: {
            'fr-FR': 'News 2',
            'en-GB': 'News 2',
            'nl-NL': 'News 2'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionButton',
            model: 'link',
            content: {
              label: i18n.getLocalesTranslations('literal.link')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.background')
            },
            model: 'background',
            settings: {
              crop: false,
              size: { width: 500, height: 9999 }
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: false,
              size: { width: 500, height: 9999 }
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'news_3',
        content: {
          default_wrapper_title: {
            'fr-FR': 'News 3',
            'en-GB': 'News 3',
            'nl-NL': 'News 3'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionButton',
            model: 'link',
            content: {
              label: i18n.getLocalesTranslations('literal.link')
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: false,
              size: { width: 500, height: 9999 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.background')
            },
            model: 'background',
            settings: {
              crop: false,
              size: { width: 500, height: 9999 }
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'sliderbar',
        content: {
          label: {
            'fr-FR': 'Slider bar',
            'en-GB': 'Slider bar',
            'nl-NL': 'Slider bar'
          }
        }
      }
    ],
    content: {
      title: { 'fr-FR': 'Actualités', 'nl-NL': 'Niews', 'en-GB': 'News' }
    }
  },
  {
    name: 'homepage_technopolis_events',
    settings: {
      icon: 'newsfeed',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'event_1',
        content: {
          default_wrapper_title: {
            'fr-FR': 'News 1',
            'en-GB': 'News 1',
            'nl-NL': 'News 1'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionButton',
            model: 'link',
            content: {
              label: i18n.getLocalesTranslations('literal.link')
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.background')
            },
            model: 'background',
            settings: {
              crop: false,
              size: { width: 500, height: 9999 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: false,
              size: { width: 500, height: 9999 }
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'event_2',
        content: {
          default_wrapper_title: {
            'fr-FR': 'News 2',
            'en-GB': 'News 2',
            'nl-NL': 'News 2'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionButton',
            model: 'link',
            content: {
              label: i18n.getLocalesTranslations('literal.link')
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: false,
              size: { width: 500, height: 9999 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.background')
            },
            model: 'background',
            settings: {
              crop: false,
              size: { width: 500, height: 9999 }
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'event_3',
        content: {
          default_wrapper_title: {
            'fr-FR': 'News 3',
            'en-GB': 'News 3',
            'nl-NL': 'News 3'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionButton',
            model: 'link',
            content: {
              label: i18n.getLocalesTranslations('literal.link')
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.background')
            },
            model: 'background',
            settings: {
              crop: false,
              size: { width: 500, height: 9999 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: false,
              size: { width: 500, height: 9999 }
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'sliderbar',
        content: {
          label: {
            'fr-FR': 'Slider bar',
            'en-GB': 'Slider bar',
            'nl-NL': 'Slider bar'
          }
        }
      }
    ],
    content: {
      title: { 'fr-FR': 'Actualités', 'nl-NL': 'Niews', 'en-GB': 'News' }
    }
  },
  {
    name: 'homepage_technopolis_project',
    settings: {
      icon: 'sponsor',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionSelectProjects',
        model: 'id',
        content: {
          label: i18n.getLocalesTranslations('literal.project')
        },
        settings: {
          limit: 1
        }
      },
      {
        path: 'modules/pages/SectionDivider',
        style: {
          divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'button',
        content: {
          label: i18n.getLocalesTranslations('literal.button')
        }
      },
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        },
        model: 'image',
        settings: {
          crop: false,
          size: { width: 848, height: 634 }
        }
      }
    ],
    content: {
      title: { 'fr-FR': 'Projet mit en avant', 'nl-NL': 'Spotlight project', 'en-GB': 'Spotlight project' }
    }
  },
  {
    name: 'homepage_technopolis_steps',
    settings: {
      icon: 'message_cog',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        settings: {
          hide_label: true
        },
        content: {
          wrapper_title: 'title',
          btn_label: {
            'nl-NL': 'Add new step'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'button',
            content: {
              label: i18n.getLocalesTranslations('literal.button')
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.steps')
    }
  },
  {
    name: 'homepage_technopolis_projects',
    settings: {
      icon: 'markbook',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionSelectCampaigns',
        model: 'query.campaign_id',
        content: {
          label: i18n.getLocalesTranslations('literal.campaigns')
        }
      },
      {
        path: 'modules/pages/SectionSelect',
        model: 'query.sort',
        value: 'created_at_first',
        settings: {
          options: projects_overview_options
        },
        content: {
          label: i18n.getLocalesTranslations('literal.sort_project_by')
        }
      },
      {
        path: 'modules/pages/SectionInputNumber',
        model: 'query.per_page',
        value: 4,
        content: {
          label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'button',
        content: {
          label: i18n.getLocalesTranslations('literal.button')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.projects')
    }
  }
]