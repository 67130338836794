import App from '@/App.vue'
import router from '@/routes'
import store from '@/store/store'
import pinia from '@/stores'
import { useClientStore } from '@/stores/ClientStore'
import { createApp } from 'vue'
import { createI18n } from '@/vendors/i18n'
import { createMixpanel } from '@/vendors/integrations'
import { createTracking } from '@/vendors/tracking'
import { createHead } from '@unhead/vue'
import MobileHeaderNav from '&/organisms/MobileHeaderNav'
import DesktopBreadcrumb from '&/molecules/DesktopBreadcrumb'
import FontAwesome from '@/vendors/fontawesome/FontAwesomeIconsLibrary'

const MyKoalect = createApp(App)
  .component('MobileHeaderNav', MobileHeaderNav)
  .component('DesktopBreadcrumb', DesktopBreadcrumb)
  .use(FontAwesome)
  .use(createHead())
  .use(pinia)
  .use(store)
  .use(createI18n)

const { fetchClient } = useClientStore(pinia)

fetchClient().then(() => {
  MyKoalect
    .use(router)
    .use(createMixpanel, { token: process.env.VUE_APP_MIXPANEL_TOKEN, config: { api_host: 'https://api-eu.mixpanel.com', batch_requests: true, debug: false } })
    .use(createTracking)
    .mount('#app')
})
