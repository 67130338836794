import { createStore } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import procedures from './modules/procedures'
import pages from './modules/pages'
import campaigns from './modules/campaigns'

export default createStore({
  actions,
  mutations,
  modules: {
    procedures,
    pages,
    campaigns
  }
})
