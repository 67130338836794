import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_ulb',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.logo')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'column_1.logo_src',
      settings: {
        hide_label: true,
        crop: false,
        size: { width: 302, height: 73 }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.contact')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_2.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_2.address',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.address'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_line')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'line',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_2.phones',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.phone_numbers'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_phone_number')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'phone',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.social_networks')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_3.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionNetworks'
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_3.links',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.links'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_phone_number')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    }
  ]
}
