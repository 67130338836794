import i18n from '@/vendors/i18n'

export default {
  name: 'text',
  settings: {
    icon: 'text',
    category: 'texts',
    is_reusable: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_text')
  },
  components: [
    {
      path: 'molecules/ModuleInputTextEditor',
      model: 'attributes.editor_content',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'link', 'image', 'video', 'code', 'text_color', 'align_left', 'align_center', 'align_right']
      }
    }
  ]
}
