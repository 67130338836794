import i18n from '@/vendors/i18n'

export default {
  name: 'recap',
  settings: {
    icon: 'recap',
    category: 'orders',
    is_reusable: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_recap')
  },
  components: [
    {
      path: 'molecules/ModuleText',
      content: {
        text: i18n.getLocalesTranslations('literal.a_recap_of_everything_people_ordered_is_displayed_on_your_form')
      }
    }
  ],
  is_allowed: {
    'client.allowed_features': ['products']
  }
}