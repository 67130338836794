import i18n from '@/vendors/i18n'

export default {
  name: 'custom_towalkagain_levensverhalen',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionInputText',
      model: 'intro.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'intro.text',
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'articles.items',
      content: {
        wrapper_title: 'title',
        label: {
          'nl-NL': 'Stories'
        },
        btn_label: {
          'nl-NL': 'Add a story'
        }
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          },
          model: 'image',
          settings: {
            size: { width: 742, height: 324 }
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'button',
          content: {
            label: i18n.getLocalesTranslations('literal.button')
          }
        }
      ]
    }
  ],
  content: {
    title: {
      'en-GB': 'Levensverhalen',
      'fr-FR': 'Levensverhalen',
      'nl-NL': 'Levensverhalen'
    }
  }
}
