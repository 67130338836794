import Campaign from '@/classes/Campaign'
const endpoint = '/mykoalect/campaigns'
const resource = 'campaigns'

export default {
  namespaced: true,
  state: {
    items: {},
    paginator: {}
  },
  getters: {
    campaigns: state => Object.values(state.items),
    paginator: state => state.paginator,
    getCampaignBySlug: state => slug => Object.values(state.items).find(item => item.slug === slug)
  },
  actions: {
    fetchCampaigns: ({ dispatch }, { query, removeStoredItems } = {}) => dispatch(
      'fetchItems',
      {
        endpoint,
        resource,
        query,
        model: campaign => new Campaign(campaign),
        removeStoredItems
      },
      { root: true }
    ),

    fetchCampaign: ({ dispatch }, { id }) => dispatch(
      'fetchItem',
      {
        endpoint: `${endpoint}/${id}`,
        resource,
        model: campaign => new Campaign(campaign)
      },
      { root: true }
    ),

    fetchCampaignStatistics: ({ dispatch }, { id }) => dispatch(
      'fetchItem',
      { endpoint: `${endpoint}/${id}/statistics` },
      { root: true }
    ),

    patchCampaign: ({ dispatch }, { id, params }) => dispatch(
      'patchItem',
      {
        endpoint: `${endpoint}/${id}`,
        resource,
        params: { campaign: params },
        model: campaign => new Campaign(campaign)
      },
      { root: true }
    ),

    patchCampaignCover: ({ dispatch }, { id, params }) => dispatch(
      'patchImage',
      {
        endpoint: `${endpoint}/${id}`,
        resource,
        params
      },
      { root: true }
    ),

    postCampaign: ({ dispatch }, { params }) => dispatch(
      'postItem',
      {
        endpoint,
        resource,
        params: { campaign: params },
        model: campaign => new Campaign(campaign)
      },
      { root: true }
    ),

    postDuplicateCampaign: ({ dispatch }, { id, params }) => dispatch(
      'postItem',
      {
        endpoint: `${endpoint}/${id}/duplicate`,
        resource,
        params: { campaign: params },
        model: campaign => new Campaign(campaign)
      },
      { root: true }
    ),

    deleteCampaign: ({ dispatch }, { id }) => dispatch(
      'deleteItem',
      {
        endpoint: `${endpoint}/${id}`,
        resource,
        id
      },
      { root: true }
    )
  }
}
