import i18n from '@/vendors/i18n'

export default {
  name: 'custom_towalkagain_network',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Intro'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'intro.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'intro.text',
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Articles'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'articles.items',
      content: {
        wrapper_title: 'title',
        btn_label: {
          'nl-NL': 'Add an article'
        }
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'news.items',
          content: {
            wrapper_title: 'title',
            label: {
              'nl-NL': 'Cards'
            },
            btn_label: {
              'nl-NL': 'Add a card'
            }
          },
          components: [
            {
              path: 'modules/pages/SectionImageUpload',
              content: {
                label: i18n.getLocalesTranslations('literal.image')
              },
              model: 'image',
              settings: {
                size: { width: 742, height: 324 }
              }
            },
            {
              path: 'modules/pages/SectionInputText',
              model: 'title',
              content: {
                label: i18n.getLocalesTranslations('literal.title')
              }
            },
            {
              path: 'modules/pages/SectionButton',
              model: 'button',
              content: {
                label: i18n.getLocalesTranslations('literal.button')
              }
            }
          ]
        }
      ]
    }
  ],
  content: {
    title: {
      'en-GB': 'Je netwerk overtuigen',
      'fr-FR': 'Je netwerk overtuigen',
      'nl-NL': 'Je netwerk overtuigen'
    }
  }
}
