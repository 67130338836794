import { inject } from 'vue'
import { useClientStore } from '@/stores/ClientStore'
import MixpanelBrowser from 'mixpanel-browser'

class Mixpanel {
  constructor () {
    this.mixpanel = MixpanelBrowser
  }

  init ({ token, config }) {
    this.mixpanel.init(token, config)
    const { getClient } = useClientStore()
    const { client } = getClient()

    if (client.value.id) this.mixpanel.register_once({ 'Client Id': client.value.id, 'Client Name': client.value.name })
  }
}

const mixpanelSymbol = Symbol()
const mixpanel = new Mixpanel()

export const createMixpanel = {
  install: (app, { token, config = {} }) => {
    mixpanel.init({ token, config })

    app.provide(mixpanelSymbol, mixpanel.mixpanel)
  }
}

export function useMixpanel () {
  return inject(mixpanelSymbol)
}

export default mixpanel.mixpanel
