import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_ugent',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Voettekst - Eerste kolom',
          'fr-FR': 'Footer - Première colonne',
          'en-GB': 'Footer - First column'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_1.links',
      settings: {
        layout: 'inline',
        hide_label: true
      },
      content: {
        label: i18n.getLocalesTranslations('literal.button'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'sublinks',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_sublink')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Voettekst - Tweede kolom',
          'fr-FR': 'Footer - Deuxième colonne',
          'en-GB': 'Footer - Second column'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_2.links',
      settings: {
        layout: 'inline',
        hide_label: true
      },
      content: {
        label: i18n.getLocalesTranslations('literal.button'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'sublinks',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_sublink')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Voettekst - Derde kolom',
          'fr-FR': 'Footer - Troisième colonne',
          'en-GB': 'Footer - Third column'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_3.links',
      settings: {
        layout: 'inline',
        hide_label: true
      },
      content: {
        label: i18n.getLocalesTranslations('literal.button'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'sublinks',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_sublink')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Voettekst - Vierde kolom',
          'fr-FR': 'Footer - Quatrième colonne',
          'en-GB': 'Footer - Fourth column'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_4.links',
      settings: {
        layout: 'inline',
        hide_label: true
      },
      content: {
        label: i18n.getLocalesTranslations('literal.button'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'sublinks',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_sublink')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.call_to_action')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'column_5.button',
      settings: {
        hide_label: true
      },
      content: {
        label: i18n.getLocalesTranslations('literal.call_to_action')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label:{
          'nl-NL': 'Voettekst - Onderste deel',
          'fr-FR': 'Footer - Section du bas',
          'en-GB': 'Footer - Bottom section'
        }
      }
    },
    {
      path: 'modules/pages/SectionNetworks'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.login_register_and_profile')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'copyright',
      content: {
        label:{
          'nl-NL': 'Copyright',
          'fr-FR': 'Copyright',
          'en-GB': 'Copyright'
        }
      }
    }
  ]
}
