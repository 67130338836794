import i18n from '@/vendors/i18n'

export default {
  name: 'custom',
  content: {
    title: i18n.getLocalesTranslations('$procedure_section.type_custom'),
    description: i18n.getLocalesTranslations('$procedure_section.type_custom_description')
  },
  options: {
    collapse: false
  },
  static_options: {
    is_reusable: true
  }
}
