import i18n from '@/vendors/i18n'

const cta = [
  {
    path: 'modules/pages/SectionButton',
    model: 'link',
    settings: {
      hide_label: true
    }
  },
  {
    path: 'modules/pages/SectionSelect',
    model: 'color',
    value: 'prim',
    content: {
      label: i18n.getLocalesTranslations('literal.color')
    },
    settings: {
      options: [
        { value: 'prim', label: i18n.t('@settings|branding|colors.label_primary_color') },
        { value: 'sec', label: i18n.t('@settings|branding|colors.label_secondary_color') },
        { value: 'ter', label: i18n.t('@settings|branding|colors.label_tertiary_color') }
      ]
    }
  },
  {
    path: 'modules/pages/SectionSelect',
    model: 'type',
    value: 'color',
    content: {
      label: i18n.getLocalesTranslations('literal.type')
    },
    settings: {
      options: [
        { value: 'color', label: { 'fr-FR': 'Classique', 'nl-NL': 'Klassiek', 'en-GB': 'Classic' } },
        { value: 'solid', label: { 'fr-FR': 'Contour', 'nl-NL': 'Omlijning', 'en-GB': 'Outline' } }
      ]
    }
  }
]

export default {
  name: 'custom_header_ilot',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.login_register_and_profile')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--24']
      }
    },
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.language_switch')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--24']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.logo')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'logo',
      settings: {
        crop: false,
        size: { width: 110, height: 9999 }
      },
      content: {
        label: { 'fr-FR': ' ', 'nl-NL': ' ', 'en-GB': ' ' }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--24']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'links',
      settings: {
        hide_label: true,
        layout: 'inline'
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--24']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.action_button')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'actions',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'link.text',
        btn_label: i18n.getLocalesTranslations('literal.add_new_button')
      },
      components: cta
    }
  ]
}
