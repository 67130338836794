import i18n from '@/vendors/i18n'

const metrics_type_options = [
  {
    value: null,
    label: i18n.getLocalesTranslations()
  },
  {
    value: 'contributors_count',
    label: i18n.getLocalesTranslations('literal.contributors')
  },
  {
    value: 'contributions_count',
    label: i18n.getLocalesTranslations('literal.contributions')
  },
  {
    value: 'amount_collected',
    label: i18n.getLocalesTranslations('literal.amount_collected')
  },
  {
    value: 'projects_count',
    label: i18n.getLocalesTranslations('literal.projects')
  },
  {
    value: 'project_members_count',
    label: i18n.getLocalesTranslations('literal.project_members')
  },
  {
    value: 'date',
    label: i18n.getLocalesTranslations('literal.days_left')
  },
  {
    value: 'sporting_statistics',
    label: i18n.getLocalesTranslations('literal.sporting_statistics'),
    allowed: {
      features: ['allowedSportingActivities']
    }
  }
]

const metrics_sporting_options = [
  {
    value: 'total_distance',
    label: i18n.getLocalesTranslations('literal.distance_in_kilometers')
  },
  {
    value: 'total_calories',
    label: i18n.getLocalesTranslations('literal.calories_in_kcal')
  },
  {
    value: 'total_elevation_gain',
    label: i18n.getLocalesTranslations('literal.elevation_gain_in_meters')
  },
  {
    value: 'total_time',
    label: i18n.getLocalesTranslations('literal.time_in_hours')
  }
]

const projects_overview_options = [
  {
    label: i18n.getLocalesTranslations('literal.latest_created_first'),
    value: 'created_at_first'
  },
  {
    label: i18n.getLocalesTranslations('literal.latest_created_last'),
    value: 'created_at_last'
  },
  {
    label: i18n.getLocalesTranslations('literal.most_collected_first'),
    value: 'amount_first'
  },
  {
    label: i18n.getLocalesTranslations('literal.less_collected_first'),
    value: 'amount_last'
  },
  {
    label: i18n.getLocalesTranslations('literal.ending_soon_first'),
    value: 'end_date_first'
  },
  {
    label: i18n.getLocalesTranslations('literal.most_contributors_first'),
    value: 'most_contributors_first'
  },
  {
    label: i18n.getLocalesTranslations('literal.most_contributors_last'),
    value: 'most_contributors_last'
  },
  {
    label: i18n.getLocalesTranslations('literal.last_activity'),
    value: 'activity_last'
  }
]

export {
  metrics_type_options,
  metrics_sporting_options,
  projects_overview_options
}
