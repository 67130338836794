import { inject } from 'vue'
import session from './session'
import user from './user'
import procedure from './procedure'
import navigation from './navigation'
import campaign from './campaign'
import benefiting from './benefiting'

class Tracking {
  constructor () {
    this.session = session
    this.user = user
    this.procedure = procedure
    this.navigation = navigation
    this.campaign = campaign
    this.benefiting = benefiting
  }
}

const trackingSymbol = Symbol()
const tracking = new Tracking()

export default tracking

export const createTracking = {
  install: app => app.provide(trackingSymbol, tracking)
}

export function useTracking () {
  return inject(trackingSymbol)
}
