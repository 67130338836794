import i18n from '@/vendors/i18n'

export default {
  name: 'custom_header_belgizitvol',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionContainer',
      model: 'logo',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.logo')
      },
      components: [
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.home')
          }
        },
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'image_home',
          settings: {
            crop: false,
            size: { width: 9999, height: 150 }
          }
        },
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.all_pages')
          }
        },
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'image',
          settings: {
            crop: false,
            size: { width: 9999, height: 50 }
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.actions')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'cta_1',
      content: {
        label: i18n.getLocalesTranslations('literal.call_to_action')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'cta_2',
      content: {
        label: i18n.getLocalesTranslations('literal.call_to_action')
      }
    }
  ]
}
