let Client = class Client {
  constructor ({ id, name, active_currencies, active_languages, allowed_features, domain_names, emails, favicon, frontend_integrations, integrations, is_maintenance, logo, mykoalect, platform, style, tax_receipt_enabled, has_benefitings, mykoalect_filter_values } = {}) {
    this.id = id || null
    this.name = name || null
    this.active_currencies = active_currencies || []
    this.active_languages = active_languages || []
    this.allowed_features = allowed_features || {}
    this.domain_names = domain_names || {}
    this.emails = emails || []
    this.favicon = favicon || null
    this.frontend_integrations = frontend_integrations || {}
    this.integrations = integrations || {}
    this.is_maintenance = is_maintenance || false
    this.logo = logo || {}
    this.mykoalect = mykoalect || {}
    this.platform = platform || {}
    this.style = style || {}
    this.tax_receipt_enabled = tax_receipt_enabled || false
    this.has_benefitings = has_benefitings || false
    this.mykoalect_filter_values = mykoalect_filter_values || {}
  }
}

export default Client
