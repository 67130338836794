import i18n from '@/vendors/i18n'

export default {
  name: 'transaction_customer_gender',
  model: 'customer.gender',
  type: 'input_select',
  settings: {
    icon: 'gender',
    category: 'personal_informations',
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('literal.gender_identity')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      value: i18n.getLocalesTranslations('literal.gender_identity'),
      content: {
        label: i18n.getLocalesTranslations('literal.label')
      }
    },
    {
      path: 'molecules/ModuleRepeater',
      model: 'attributes.options',
      content: {
        label: i18n.getLocalesTranslations('literal.options'),
        btn: i18n.getLocalesTranslations('$block_select.btn_add_option')
      },
      value: [
        {
          text: i18n.getLocalesTranslations('literal.male'),
          value: 'male'
        },
        {
          text: i18n.getLocalesTranslations('literal.female'),
          value: 'female'
        },
        {
          text: i18n.getLocalesTranslations('literal.transmale'),
          value: 'transmale'
        },
        {
          text: i18n.getLocalesTranslations('literal.transfemale'),
          value: 'transfemale'
        },
        {
          text: i18n.getLocalesTranslations('literal.genderqueer'),
          value: 'genderqueer'
        },
        {
          text: i18n.getLocalesTranslations('literal.other_gender'),
          value: 'other_gender'
        },
        {
          text: i18n.getLocalesTranslations('literal.decline_to_answer'),
          value: 'decline_to_answer'
        }
      ],
      components: [
        {
          path: 'molecules/ModuleInputOption',
          components: {
            label: {
              path: 'molecules/ModuleInputText',
              model: 'text',
              content: {
                label: i18n.getLocalesTranslations('literal.label')
              }
            },
            value: {
              path: 'molecules/ModuleInputText',
              model: 'value',
              content: {
                label: i18n.getLocalesTranslations('literal.reference')
              },
              settings: {
                multilingual: false
              }
            }
          }
        }
      ]
    },
    {
      path: 'molecules/ModuleInputSelect',
      model: 'attributes.default_value',
      options: 'attributes.options',
      content: {
        label: i18n.getLocalesTranslations('literal.default_value')
      }
    },
    {
      path: 'molecules/ModuleInputCheckboxMandatory',
      value: 'required',
      content: {
        label: i18n.getLocalesTranslations('$procedure_block.label_is_mandatory')
      }
    }
  ]
}
