import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_vijvens',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.logo')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'logo.image',
      settings: {
        crop: false,
        size: { width: 145, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionInputUrl',
      model: 'logo.url',
      content: {
        label: i18n.getLocalesTranslations('literal.url')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.contact')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'contact.address',
      content: {
        label: i18n.getLocalesTranslations('literal.address')
      },
      settings: {
        configuration: ['bold', 'italic']
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'contact.phone',
      content: {
        label: i18n.getLocalesTranslations('literal.phone_number')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'contact.email',
      content: {
        label: i18n.getLocalesTranslations('literal.email')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'links',
      settings: {
        layout: 'inline',
        hide_label: true
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'item',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.social_networks')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'social_networks.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionNetworks'
    }
  ]
}
