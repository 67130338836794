import i18n from '@/vendors/i18n'

export default {
  name: 'custom_header_refugeewalk',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionLogo'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'links_1',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.links_appearing_on_the_left_in_the_header'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'submenu',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_sublink')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'links_2',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.links_appearing_on_the_right_in_the_header'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'submenu',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_sublink')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.search')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'search',
      content: {
        label: {
          'fr-FR': ' ',
          'en-GB': ' ',
          'nl-NL': ' '
        }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.login_register_and_profile')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.language_switch')
      }
    }
  ]
}
