import i18n from '@/vendors/i18n'

export default {
  name: 'informations_manual_domiciliation_iban',
  model: 'informations.manual_domiciliation.iban',
  type: 'input_text',
  settings: {
    icon: 'iban',
    require_model: true,
    is_deletable: false
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_informations_iban')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      value: i18n.getLocalesTranslations('$block_informations_iban.label_iban'),
      content: {
        label: i18n.getLocalesTranslations('literal.label')
      }
    },
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.placeholder',
      value: i18n.getLocalesTranslations('$block_informations_iban.placeholder_iban'),
      content: {
        label: i18n.getLocalesTranslations('literal.placeholder')
      }
    }
  ],
  is_allowed: {
    'client.allowed_features': ['manual_domiciliation']
  }
}
