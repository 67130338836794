import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_expeditienatuurpunt',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Sponsors'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'sponsors.logos',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'link.text',
        btn_label: {
          'nl-NL': 'Add new sponsor'
        }
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'logo',
          settings: {
            crop: false,
            size: { width: 110, height: 9999 }
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          content: {
            label: {
              'nl-NL': 'Link'
            }
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Eerste kolom - contact',
          'fr-FR': 'Première colonne - contact',
          'en-GB': 'First column - contact'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_1.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_1.contact',
      content: {
        label: {
          'nl-NL': 'Contact informations',
          'fr-FR': 'Informations de contact',
          'en-GB': 'Contact informations'
        },
        btn_label: i18n.getLocalesTranslations('literal.add_new_line'),
        wrapper_title: 'text'
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          content: {
            label: i18n.getLocalesTranslations('literal.type')
          },
          value: 'text',
          settings: {
            hide_label: true,
            options: [
              {
                label: i18n.getLocalesTranslations('literal.text'),
                value: 'text'
              },
              {
                label: i18n.getLocalesTranslations('literal.website'),
                value: 'website'
              },
              {
                label: i18n.getLocalesTranslations('literal.email'),
                value: 'email'
              },
              {
                label: i18n.getLocalesTranslations('literal.phone_number'),
                value: 'phone_number'
              }
            ]
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Tweede kolom - links',
          'fr-FR': 'Deuxième colonne - liens',
          'en-GB': 'Second column - links'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_2.group_1',
      settings: {
        layout: 'inline'
      },
      content: {
        label: {
          'nl-NL': 'Group 1',
          'fr-FR': 'Groupe 1',
          'en-GB': 'Group 1'
        },
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_2.group_2',
      settings: {
        layout: 'inline'
      },
      content: {
        label: {
          'nl-NL': 'Group 2',
          'fr-FR': 'Groupe 2',
          'en-GB': 'Group 2'
        },
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    }
  ]
}
