<template>
  <div
    class="side-notifications"
    role="status"
    aria-atomic="true"
    v-show="side_notifications.length"
  >
    <app-side-notification
      v-for="side_notification in side_notifications"
      :notification="side_notification"
      :key="side_notification.timestamp"
    />
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useNotificationStore } from '@/stores/NotificationStore'

const AppSideNotification = defineAsyncComponent(() => import('&/molecules/AppSideNotification'))

const { side_notifications } = storeToRefs(useNotificationStore())
</script>

<style lang="scss" data-include>
.side-notifications {
  position: fixed;
  bottom: 24px;
  right: 12px;
  display: grid;
  gap: 12px;
  z-index: 9999;
  width: calc(100% - 24px);

  @include mq(sm) {
    right: 24px;
    width: 300px;
  }
}
</style>