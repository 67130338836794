import i18n from '@/vendors/i18n'

export default {
  name: 'media',
  settings: {
    icon: 'video'
  },
  components: [
    {
      path: 'modules/pages/SectionMedia',
      model: 'media',
      settings: {
        size: { width: 1440, height: 810 }
      }
    }
  ],
  style: {
    background: {
      color: null,
      type: 'color'
    }
  },
  content: {
    title: i18n.getLocalesTranslations('literal.media')
  }
}
