const Projects = () => import(/* webpackChunkName: 'Projects' */'@/views/projects/ViewProjects')
const ProjectDetails = () => import(/* webpackChunkName: 'ProjectDetails' */'@/views/projects/ViewProjectDetails')
const AddOfflineContribution = () => import(/* webpackChunkName: 'AddOfflineContribution' */'@/views/projects/ViewAddOfflineContribution')

const routes = [
  {
    path: 'projects',
    name: 'Projects',
    component: Projects,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.projects', routeName: 'Projects' }
      ]
    }
  },
  {
    path: 'projects/:id',
    name: 'ProjectDetails',
    component: ProjectDetails,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.projects', routeName: 'Projects' },
        { routeName: 'ProjectDetails' }
      ]
    }
  },
  {
    path: 'projects/:id/add-offline-contribution',
    name: 'AddOfflineContribution',
    component: AddOfflineContribution,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.projects', routeName: 'Projects' },
        { routeName: 'ProjectDetails' },
        { label: 'literal.add_offline_contribution', routeName: 'AddOfflineContribution' }
      ]
    }
  }
]

export default routes
