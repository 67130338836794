import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_belgizitvol',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_1',
      content: {
        default_wrapper_title: { 'en-GB': 'Column #1', 'fr-FR': 'Colonne #1', 'nl-NL': 'Kolom #1' }
      },
      components: [
        {
          path: 'modules/pages/SectionContainer',
          model: 'logo',
          content: {
            default_wrapper_title: i18n.getLocalesTranslations('literal.logo')
          },
          components: [
            {
              path: 'modules/pages/SectionImageUpload',
              model: 'image',
              settings: {
                crop: false,
                size: { width: 9999, height: 130 }
              }
            }
          ]
        },
        {
          path: 'modules/pages/SectionContainer',
          model: 'email',
          content: {
            default_wrapper_title: i18n.getLocalesTranslations('literal.email')
          },
          components: [
            {
              path: 'modules/pages/SectionInputText',
              model: 'text',
              content: {
                label: i18n.getLocalesTranslations('literal.text')
              }
            },
            {
              path: 'modules/pages/SectionInputText',
              model: 'url',
              content: {
                label: i18n.getLocalesTranslations('literal.url')
              }
            }
          ]
        },
        {
          path: 'modules/pages/SectionContainer',
          model: 'website',
          content: {
            default_wrapper_title: i18n.getLocalesTranslations('literal.website')
          },
          components: [
            {
              path: 'modules/pages/SectionInputText',
              model: 'text',
              content: {
                label: i18n.getLocalesTranslations('literal.text')
              }
            },
            {
              path: 'modules/pages/SectionInputText',
              model: 'url',
              content: {
                label: i18n.getLocalesTranslations('literal.url')
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_2',
      content: {
        default_wrapper_title: { 'en-GB': 'Column #2', 'fr-FR': 'Colonne #2', 'nl-NL': 'Kolom #2' }
      },
      components: [
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.menu')
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'nav',
          settings: {
            layout: 'inline',
            hide_label: true
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_link')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_3',
      content: {
        default_wrapper_title: { 'en-GB': 'Column #3', 'fr-FR': 'Colonne #3', 'nl-NL': 'Kolom #3' }
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'cta',
          content: {
            label: i18n.getLocalesTranslations('literal.call_to_action')
          }
        },
        {
          path: 'modules/pages/SectionDivider',
          style: {
            divider: ['separator','separator__size--full', 'bg__color--vdw']
          }
        },
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.social_networks')
          }
        }
      ]
    }
  ]
}