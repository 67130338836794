import i18n from '@/vendors/i18n'

export default {
  name: 'transaction_customer_business_name',
  model: 'customer.business_name',
  type: 'input_text',
  settings: {
    icon: 'input_text',
    category: 'personal_informations',
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_customer_business_name')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      value: i18n.getLocalesTranslations('$block_customer_business_name.label_business_name'),
      content: {
        label: i18n.getLocalesTranslations('literal.label')
      }
    },
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.placeholder',
      value: i18n.getLocalesTranslations('$block_customer_business_name.placeholder_business_name'),
      content: {
        label: i18n.getLocalesTranslations('literal.placeholder')
      }
    },
    {
      path: 'molecules/ModuleInputCheckboxMandatory',
      value: 'required',
      content: {
        label: i18n.getLocalesTranslations('$procedure_block.label_is_mandatory')
      }
    }
  ]
}
