import i18n from '@/vendors/i18n'
import { projects_overview_options } from '@/configurations/general/components-default-data'

export default [
  {
    name: 'campaign_deloods_banner',
    settings: {
      icon: 'banner',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        model: 'image',
        settings: {
          crop: true,
          size: { width: 1440, height: 618 }
        },
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        },
        settings: {
          configuration: ['bold', 'italic', 'underline']
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.banner')
    }
  },
  {
    name: 'campaign_deloods_counter',
    settings: {
      icon: 'timer',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'query',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.campaign')
            }
          },
          {
            path: 'modules/pages/SectionSelectForms',
            model: 'procedure_id',
            content: {
              label: i18n.getLocalesTranslations('literal.forms')
            }
          },
          {
            path: 'modules/pages/SectionInputAmount',
            model: 'goal',
            content: {
              label: i18n.getLocalesTranslations('literal.goal')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'content',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.content')
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'amount',
            content: {
              label: i18n.getLocalesTranslations('literal.amount')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'contributors',
            content: {
              label: i18n.getLocalesTranslations('literal.contributors')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'goal',
            content: {
              label: i18n.getLocalesTranslations('literal.goal')
            }
          },
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.counter')
    }
  },
  {
    name: 'campaign_deloods_building',
    settings: {
      icon: 'flag_star',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        },
        settings: {
          configuration: ['bold', 'italic', 'underline']
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'level_0',
        content: {
          default_wrapper_title: {
            'en-GB': '+0',
            'fr-FR': '+0',
            'nl-NL': '+0'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionRepeater',
            model: 'rooms',
            content: {
              wrapper_title: 'name'
            },
            settings: {
              hide_label: true
            },
            components: [
              {
                path: 'modules/pages/SectionImageUpload',
                model: 'image',
                settings: {
                  crop: true,
                  size: { width: 456, height: 212 }
                },
                content: {
                  label: i18n.getLocalesTranslations('literal.image')
                }
              },
              {
                path: 'modules/pages/SectionInputText',
                model: 'name',
                content: {
                  label: i18n.getLocalesTranslations('literal.name')
                }
              },
              {
                path: 'modules/pages/SectionTextEditor',
                model: 'content',
                content: {
                  label: i18n.getLocalesTranslations('literal.content')
                },
                settings: {
                  configuration: ['bold', 'italic', 'underline']
                }
              },
              {
                path: 'modules/pages/SectionInputColor',
                model: 'color',
                content: {
                  label: i18n.getLocalesTranslations('literal.color')
                }
              },
              {
                path: 'modules/pages/SectionInputText',
                model: 'legend',
                content: {
                  label: { 'fr-FR': 'Légende','nl-NL': 'Legende','en-GB': 'Legend' }
                }
              },
              {
                path: 'modules/pages/SectionInputAmount',
                model: 'goal',
                content: {
                  label: i18n.getLocalesTranslations('literal.goal')
                }
              },
              {
                path: 'modules/pages/SectionSelectCampaigns',
                model: 'campaign_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.campaign')
                },
                settings: {
                  limit: 1
                }
              },
              {
                path: 'modules/pages/SectionButton',
                model: 'cta',
                content: {
                  label: i18n.getLocalesTranslations('literal.call_to_action')
                }
              }
            ]
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'level_1',
        content: {
          default_wrapper_title: { 'en-GB': '+1', 'fr-FR': '+1', 'nl-NL': '+1' }
        },
        components: [
          {
            path: 'modules/pages/SectionRepeater',
            model: 'rooms',
            content: {
              wrapper_title: 'name'
            },
            settings: {
              hide_label: true
            },
            components: [
              {
                path: 'modules/pages/SectionImageUpload',
                model: 'image',
                settings: {
                  crop: true,
                  size: { width: 456, height: 212 }
                },
                content: {
                  label: i18n.getLocalesTranslations('literal.image')
                }
              },
              {
                path: 'modules/pages/SectionInputText',
                model: 'name',
                content: {
                  label: i18n.getLocalesTranslations('literal.name')
                }
              },
              {
                path: 'modules/pages/SectionTextEditor',
                model: 'content',
                content: {
                  label: i18n.getLocalesTranslations('literal.content')
                },
                settings: {
                  configuration: ['bold', 'italic', 'underline']
                }
              },
              {
                path: 'modules/pages/SectionInputColor',
                model: 'color',
                content: {
                  label: i18n.getLocalesTranslations('literal.color')
                }
              },
              {
                path: 'modules/pages/SectionInputText',
                model: 'legend',
                content: {
                  label: { 'fr-FR': 'Légende','nl-NL': 'Legende','en-GB': 'Legend' }
                }
              },
              {
                path: 'modules/pages/SectionInputAmount',
                model: 'goal',
                content: {
                  label: i18n.getLocalesTranslations('literal.goal')
                }
              },
              {
                path: 'modules/pages/SectionSelectCampaigns',
                model: 'campaign_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.campaign')
                },
                settings: {
                  limit: 1
                }
              },
              {
                path: 'modules/pages/SectionButton',
                model: 'cta',
                content: {
                  label: i18n.getLocalesTranslations('literal.call_to_action')
                }
              }
            ]
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.campaign')
    }
  },
  {
    name: 'campaign_deloods_support',
    settings: {
      icon: 'contribute',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        content: {
          wrapper_title: 'title'
        },
        settings: {
          hide_label: true
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'content',
            content: {
              label: i18n.getLocalesTranslations('literal.content')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.call_to_action')
            }
          }
        ]
      }
    ],
    content: {
      title: { 'en-GB': 'Support', 'fr-FR': 'Soutien', 'nl-NL': 'Steun' }
    }
  },
  {
    name: 'campaign_deloods_ambassadors',
    settings: {
      icon: 'message_cog',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        content: {
          wrapper_title: 'name'
        },
        settings: {
          hide_label: true
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'image',
            settings: {
              crop: true,
              size: { width: 324, height: 353 }
            },
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'name',
            content: {
              label: i18n.getLocalesTranslations('literal.name')
            }
          },
          {
            path: 'modules/pages/SectionRepeater',
            model: 'social_networks',
            content: {
              wrapper_title: 'type',
              label: i18n.getLocalesTranslations('literal.social_networks'),
              btn_label: i18n.getLocalesTranslations('literal.add_social_link')
            },
            components: [
              {
                path: 'modules/pages/SectionSelect',
                model: 'type',
                value: 'Instagram',
                settings: {
                  options: [
                    { value: 'Instagram', label: i18n.getLocalesTranslations('Instagram') },
                    { value: 'Facebook', label: i18n.getLocalesTranslations('Facebook') },
                    { value: 'Linkedin', label: i18n.getLocalesTranslations('Linkedin') },
                    { value: 'Twitter', label: i18n.getLocalesTranslations('Twitter') }
                  ]
                }
              },
              {
                path: 'modules/pages/SectionInputUrl',
                model: 'url',
                content: {
                  label: i18n.getLocalesTranslations('literal.url')
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'content',
            content: {
              label: i18n.getLocalesTranslations('literal.content')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline']
            }
          }
        ]
      }
    ],
    content: {
      title: { 'en-GB': 'Ambassadors', 'fr-FR': 'Ambassadeurs', 'nl-NL': 'Ambassadeurs' }
    }
  },
  {
    name: 'campaign_deloods_projects',
    settings: {
      icon: 'markbook',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'query',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelect',
            model: 'sort',
            value: 'created_at_first',
            settings: {
              options: projects_overview_options
            },
            content: {
              label: i18n.getLocalesTranslations('literal.sort_project_by')
            }
          },
          {
            path: 'modules/pages/SectionInputNumber',
            model: 'per_page',
            value: 4,
            content: {
              label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
            }
          },
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.campaigns')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.projects')
    }
  },
  {
    name: 'campaign_deloods_about',
    settings: {
      icon: 'flag',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        model: 'image',
        settings: {
          crop: true,
          size: { width: 564, height: 472 }
        },
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        },
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link']
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.about')
    }
  },
  {
    name: 'campaign_deloods_gallery',
    settings: {
      icon: 'image',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        settings: {
          hide_label: true
        },
        content: {
          btn_label: i18n.getLocalesTranslations('literal.add_image')
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'image',
            settings: {
              crop: false,
              size: { width: 624, height: 9999 },
            },
            options: {
              hide_label: true
            }
          }
        ]
      }
    ],
    content: {
      title: { 'fr-FR': 'Galerie', 'en-GB': 'Gallery', 'nl-NL': 'Galerij' }
    }
  },
  {
    name: 'campaign_deloods_sponsors',
    settings: {
      icon: 'sponsor',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        settings: {
          hide_label: true
        },
        content: {
          wrapper_title: 'name',
          btn_label: i18n.getLocalesTranslations('literal.add_new_sponsor')
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'name',
            content: {
              label: i18n.getLocalesTranslations('literal.name')
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'logo',
            settings: {
              crop: false,
              size: { width: 9999, height: 94 },
            },
            options: {
              hide_label: true
            }
          },
          {
            path: 'modules/pages/SectionInputUrl',
            model: 'url',
            content: {
              label: i18n.getLocalesTranslations('literal.url')
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.sponsors')
    }
  }
]