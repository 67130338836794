import i18n from '@/vendors/i18n'

export default {
  name: 'project_invite_emails',
  model: 'actions_data.create_invite.emails',
  settings: {
    icon: 'invitation',
    category: 'project_fields',
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_project_invitation')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      value: i18n.getLocalesTranslations('literal.email'),
      content: {
        label: i18n.getLocalesTranslations('literal.label')
      }
    },
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.cta',
      value: i18n.getLocalesTranslations('literal.invite_another_member'),
      content: {
        label: i18n.getLocalesTranslations('literal.button_label')
      }
    },
    {
      path: 'molecules/ModuleInputTextarea',
      model: 'attributes.project_limited',
      value: i18n.getLocalesTranslations('literal.projects_limited_to_x_additional_members'),
      settings: {
        variables: ['project.members.limit']
      },
      content: {
        label: i18n.getLocalesTranslations('$block_project_invite_email.projects_limited')
      }
    },
    { path: 'molecules/ModuleDivider' },
    { path: 'modules/procedures/BlockCreateInviteEmails' }
  ],
  is_allowed: {
    'procedure.category': ['create-project']
  }
}
