import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_towalkagain',
  settings: { unavailable: true },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Column 1'
        }
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      content: {
        label: i18n.getLocalesTranslations('literal.logo')
      },
      model: 'column_1.logo',
      settings: {
        crop: false,
        size: { width: 100, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Column 2'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_2.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_2.links',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.links'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionNetworks'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Column 3'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_3.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_3.address',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.address'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_line')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'text',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_3.infos',
      content: {
        label: i18n.getLocalesTranslations('literal.contact'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_line'),
        wrapper_title: 'text'
      },
      components: [
        {
          path: 'modules/pages/SectionSelect',
          model: 'type',
          content: {
            label: i18n.getLocalesTranslations('literal.type')
          },
          value: 'text',
          settings: {
            hide_label: true,
            options: [
              {
                label: i18n.getLocalesTranslations('literal.text'),
                value: 'text'
              },
              {
                label: i18n.getLocalesTranslations('literal.email'),
                value: 'email'
              },
              {
                label: i18n.getLocalesTranslations('literal.phone_number'),
                value: 'phone_number'
              }
            ]
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'text',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Column 4'
        }
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'column_4.button_1',
      content: {
        label: i18n.getLocalesTranslations('literal.button')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'column_4.button_2',
      content: {
        label: i18n.getLocalesTranslations('literal.button')
      }
    }
  ]
}
