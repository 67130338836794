import i18n from '@/vendors/i18n'

export default {
  name: 'custom_header_ihecs',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Section du haut',
          'nl-NL': 'Top sectie',
          'en-GB': 'Top section'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'top.menu',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.menu'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          },
        }
      ]
    },
    {
      path: 'modules/pages/SectionNetworks'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'fr-FR': 'Section du bas',
          'nl-NL': 'Onderste sectie',
          'en-GB': 'Bottom section'
        }
      }
    },
    {
      path: 'modules/pages/SectionLogo'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'bottom.search',
      content: {
        label: i18n.getLocalesTranslations('literal.search_field')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'bottom.button',
      content: {
        label: i18n.getLocalesTranslations('literal.button'),
      }
    },
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.login_register_and_profile')
      }
    }
  ]
}
