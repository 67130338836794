import i18n from '@/vendors/i18n'

export default {
  name: 'sharing_links',
  settings: {
    icon: 'sharing_links',
    category: 'medias',
    is_reusable: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_sharing_links')
  },
  is_allowed: {
    'procedure.category': ['donation']
  }
}
