import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_sfov',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Eerste kolom - Logo',
          'fr-FR': 'Première colonne - Logo',
          'en-GB': 'First column - Logo'
        }
      }
    },
    {
      path: 'modules/pages/SectionLogo'
    },
    {
      path: 'modules/pages/SectionInputUrl',
      model: 'logo.url'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.social_networks')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Tweede kolom - Menu',
          'fr-FR': 'Deuxième colonne - Menu',
          'en-GB': 'Second column - Menu'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_2.navigation',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.menu'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          },
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Derde kolom - Nieuwsbrief',
          'fr-FR': 'Troisième colonne - Newsletter',
          'en-GB': 'Third column - Newsletter'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_3.newsletter.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_3.newsletter.placeholder',
      content: {
        label: i18n.getLocalesTranslations('literal.placeholder')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_3.newsletter.regions',
      content: {
        wrapper_title: 'name',
        btn_label: {
          'nl-NL': 'Voeg nieuwe region toe',
          'en-GB': 'Add new region',
          'fr-FR': 'Ajouter une nouvelle région'
        }
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'name',
          content: {
            label: i18n.getLocalesTranslations('literal.name')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'column_3.newsletter.button',
      content: {
        label: i18n.getLocalesTranslations('literal.button')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Vierde kolom - Partners',
          'fr-FR': 'Quatrième colonne - Partenaires',
          'en-GB': 'Fourth column - Partners'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_4.sponsors',
      content: {
        wrapper_title: 'name',
        btn_label: i18n.getLocalesTranslations('literal.add_new_partner')
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'logo',
          settings: {
            crop: false,
            size: { width: 660, height: 440 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.logo')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'name',
          content: {
            label: i18n.getLocalesTranslations('literal.partner_name')
          }
        },
        {
          path: 'modules/pages/SectionInputUrl',
          model: 'website',
          content: {
            label: i18n.getLocalesTranslations('literal.website_link')
          }
        }
      ]
    }
  ]
}
