import i18n from '@/vendors/i18n'

const custom = {
  name: 'custom',
  attributes: {
    back_btn: i18n.getLocalesTranslations('$procedure_step.button_back'),
    next_btn: i18n.getLocalesTranslations('$procedure_step.button_next')
  },
  static_options: {
    icon: 'checklist_pen'
  }
}

export default custom
