import i18n from '@/vendors/i18n'
import { metrics_sporting_options, metrics_type_options } from '@/configurations/general/components-default-data'

export default {
  name: 'custom_homepage_kajaktrail',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.banner')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      content: {
        label: i18n.getLocalesTranslations('literal.background')
      },
      model: 'banner.background',
      settings: {
        crop: false,
        size: { width: 1600, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionInputUrl',
      content: {
        label: {
          'nl-NL': 'Video url (mp4)',
          'en-GB': 'Video link (mp4)',
          'fr-FR': 'Lien de votre vidéo (mp4)'
        }
      },
      model: 'banner.video'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.additional_info')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'infos.text',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'link', 'heading_h2']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.how_it_works')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'how_it_works.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'how_it_works.steps',
      content: {
        label: i18n.getLocalesTranslations('literal.steps'),
        btn_label: i18n.getLocalesTranslations('literal.add_step')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'img_src',
          settings: {
            size: { width: 250, height: 250 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          content: {
            label: i18n.getLocalesTranslations('literal.step_description')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Formule'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'plan.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'plan.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'plan.nature',
      content: {
        wrapper_title: 'title',
        default_wrapper_title: {
          'nl-NL': 'Natuur'
        }
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'canoe',
          content: {
            label: {
              'nl-NL': 'Kano'
            }
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'walk',
          content: {
            label: {
              'nl-NL': 'Wandelen'
            }
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'bike',
          content: {
            label: {
              'nl-NL': 'Fietsen'
            }
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'bbq',
          content: {
            label: {
              'nl-NL': 'BBQ'
            }
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'button',
          content: {
            label: i18n.getLocalesTranslations('literal.button')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'plan.sport',
      content: {
        wrapper_title: 'title',
        default_wrapper_title: {
          'nl-NL': 'Sport'
        }
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'bike',
          content: {
            label: {
              'nl-NL': 'Fietsen'
            }
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'run',
          content: {
            label: {
              'nl-NL': 'Lopen'
            }
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'canoe',
          content: {
            label: {
              'nl-NL': 'Kano'
            }
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'bbq',
          content: {
            label: {
              'nl-NL': 'BBQ'
            }
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'button',
          content: {
            label: i18n.getLocalesTranslations('literal.button')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'plan.details',
      content: {
        label: i18n.getLocalesTranslations('literal.details')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Of ga metten de uitdaging aan'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'where.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'where.buttons',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.buttons'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_button')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'button',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Aftermovie'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'aftermovie.items',
      content: {
        wrapper_title: 'title',
        btn_label: i18n.getLocalesTranslations('literal.add_video')
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionImageUpload',
          content: {
            label: i18n.getLocalesTranslations('literal.background')
          },
          model: 'image',
          settings: {
            crop: false,
            size: { width: 1280, height: 9999 }
          }
        },
        {
          path: 'modules/pages/SectionMedia',
          model: 'media',
          settings: {
            available_types: ['video']
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.metrics')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'metrics',
      content: {
        wrapper_title: 'title',
        btn_label: i18n.getLocalesTranslations('literal.add_section')
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionSelectCampaigns',
          model: 'campaign_id',
          content: {
            label: i18n.getLocalesTranslations('literal.show_metrics_from_these_campaigns')
          }
        },
        {
          path: 'modules/pages/SectionSelectForms',
          model: 'procedure_id',
          content: {
            label: i18n.getLocalesTranslations('literal.show_metrics_from_these_forms')
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'items',
          settings: {
            hide_label: true
          },
          content: {
            wrapper_title: 'text',
            btn_label: i18n.getLocalesTranslations('literal.add_metric'),
            default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
          },
          components: [
            {
              path: 'modules/pages/SectionSelect',
              model: 'type',
              value: 'contributors_count',
              settings: {
                options: metrics_type_options
              },
              content: {
                label: i18n.getLocalesTranslations('literal.metric')
              }
            },
            {
              path: 'modules/pages/SectionInputDate',
              model: 'date',
              content: {
                label: i18n.getLocalesTranslations('literal.date')
              },
              conditions: [
                {
                  logic_operator: 'and',
                  model: 'type',
                  value: 'date',
                  comparison_operator: 'eq'
                }
              ]
            },
            {
              path: 'modules/pages/SectionSelect',
              model: 'sporting_statistics',
              value: 'total_distance',
              settings: {
                options: metrics_sporting_options
              },
              content: {
                label: i18n.getLocalesTranslations('literal.sporting_statistics')
              },
              conditions: [
                {
                  logic_operator: 'and',
                  model: 'type',
                  value: 'sporting_statistics',
                  comparison_operator: 'eq'
                }
              ]
            },
            {
              path: 'modules/pages/SectionInputText',
              model: 'text'
            },
            {
              path: 'modules/pages/SectionButton',
              model: 'button',
              content: {
                label: i18n.getLocalesTranslations('literal.button')
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Testimonials'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'quotes.items',
      content: {
        wrapper_title: 'author_name',
        btn_label: {
          'nl-NL': 'Add new testimonial'
        }
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          settings: {
            configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'author_name',
          content: {
            label: i18n.getLocalesTranslations('literal.author_name')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'author_job',
          content: {
            label: i18n.getLocalesTranslations('literal.author_job_title')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Pictures'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'pictures.items',
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_image')
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          },
          model: 'image',
          settings: {
            crop: false,
            size: { width: 900, height: 9999 }
          }
        },
        {
          path: 'modules/pages/SectionInputUrl',
          model: 'link',
          content: {
            label: i18n.getLocalesTranslations('literal.link')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.partners')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'sponsors.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'sponsors.items',
      content: {
        wrapper_title: 'name',
        btn_label: i18n.getLocalesTranslations('literal.add_new_partner')
      },
      settings: {
        hide_label: true
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'logo',
          settings: {
            crop: false,
            size: { width: 660, height: 440 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.logo')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'name',
          content: {
            label: i18n.getLocalesTranslations('literal.partner_name')
          }
        },
        {
          path: 'modules/pages/SectionInputUrl',
          model: 'website',
          content: {
            label: i18n.getLocalesTranslations('literal.website_link')
          }
        }
      ]
    }
  ],
  content: {
    title: {
      'en-GB': 'Kajak Trail van 11.11.11',
      'fr-FR': 'Kajak Trail van 11.11.11',
      'nl-NL': 'Kajak Trail van 11.11.11'
    }
  }
}
