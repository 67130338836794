import i18n from '@/vendors/i18n'

export default {
  name: 'how_it_works',
  settings: {
    icon: 'message_cog'
  },
  components: [
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'text'
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'steps',
      content: {
        label: i18n.getLocalesTranslations('literal.steps'),
        btn_label: i18n.getLocalesTranslations('literal.add_step')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'img_src',
          settings: {
            size: { width: 250, height: 250 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          content: {
            label: i18n.getLocalesTranslations('literal.step_description')
          }
        }
      ]
    }
  ],
  style: {
    background: {
      image: i18n.getLocalesTranslations(),
      color: null,
      type: 'color'
    },
    text: {
      color: null
    }
  },
  content: {
    title: i18n.getLocalesTranslations('literal.how_it_works')
  }
}
