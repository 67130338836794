import i18n from '@/vendors/i18n'

export default {
  name: 'custom_header_ugent',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.logo')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'logo.url',
      settings: {
        hide_label: true
      },
      content: {
        label: i18n.getLocalesTranslations('literal.url')
      }
    },
    {
      path: 'modules/pages/SectionLogo'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Koptekst - Bovenste gedeelte',
          'fr-FR': 'Header - Section du haut',
          'en-GB': 'Header - Top section'
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'top.title',
      content: {
        label: {
          'nl-NL': 'Paginatitel',
          'fr-FR': 'Titre de la page',
          'en-GB': 'Page title'
        }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.login_register_and_profile')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'top.button',
      settings: {
        hide_label: false
      },
      content: {
        label: i18n.getLocalesTranslations('literal.call_to_action')
      }
    },
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.language_switch')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Koptekst - Onderste deel',
          'fr-FR': 'Header - Section du bas',
          'en-GB': 'Header - Bottom section'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'bottom.menu',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.menu'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          },
        }
      ]
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'bottom.button',
      settings: {
        hide_label: false
      },
      content: {
        label: i18n.getLocalesTranslations('literal.call_to_action')
      }
    }
  ]
}
