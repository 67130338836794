import i18n from '@/vendors/i18n'

export default {
  name: 'transaction_customer_firstname',
  model: 'customer.firstname',
  type: 'input_text',
  settings: {
    icon: 'input_text',
    category: 'personal_informations',
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_customer_firstname')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      value: i18n.getLocalesTranslations('$block_customer_firstname.label_firstname'),
      content: {
        label: i18n.getLocalesTranslations('literal.question')
      }
    },
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.placeholder',
      value: i18n.getLocalesTranslations('$block_customer_firstname.placeholder_firstname'),
      content: {
        label: i18n.getLocalesTranslations('literal.placeholder')
      }
    }
  ],
  is_required: {
    'procedure.category': ['donation']
  }
}
