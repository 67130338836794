import Axios from 'axios'
import router from '@/routes'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useLanguageStore } from '@/stores/LanguageStore'
import { useNotificationStore } from '@/stores/NotificationStore'
import { $cookies } from '@/utils/utils'
import { get as _get } from 'lodash'

const API_URL = process.env.VUE_APP_ROOT_API

const axios = Axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

axios.CancelToken = Axios.CancelToken
axios.isCancel = Axios.isCancel

axios.interceptors.request.use(config => {
  const token = $cookies.get('authToken') || null

  config.headers['Authorization'] = token ? `Bearer ${token}` : ''

  return config
}, error => Promise.reject(error))

axios.interceptors.response.use(response => {
  return response
}, ({ response }) => {
  if (response?.status === 401 && _get(response, 'data.errors', []).includes('invalid_origin') && _get(response, 'config.url') === '/mykoalect/client') return

  const { logout } = useAuthenticationStore()
  const { locale } = useLanguageStore()
  const { postSideNotification } = useNotificationStore()

  const { replace } = router
  const pathname = window.location.pathname

  if (response?.status === 401 && !pathname.includes('login')) {
    postSideNotification({ text: 'literal.your_session_has_expired', type: 'info' })

    logout()
    return replace({ name: 'Login', params: { locale }, query: { redirectTo: pathname } }).then(() => Promise.reject(response))
  } else if (response?.status === 403) {
    logout()
    return location.reload()
  }

  return Promise.reject(response)
})

export default axios
