import i18n from '@/vendors/i18n'

export default {
  name: 'custom_date_field',
  type: 'input_date',
  settings: {
    icon: 'input_date',
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('literal.date_&_time')
  },
  fields: [
    {
      path: 'molecules/ModuleInputCheckbox',
      model: 'options.show_time',
      value: true,
      content: {
        label: i18n.getLocalesTranslations('literal.display_time')
      }
    },
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      content: {
        label: i18n.getLocalesTranslations('literal.default_label')
      }
    },
    {
      path: 'molecules/ModuleInputSelect',
      model: 'attributes.default_option',
      options: [
        {
          label: i18n.getLocalesTranslations('literal.no_default_value'),
          value: null
        },
        {
          label: i18n.getLocalesTranslations('literal.date_of_the_day'),
          value: 'today'
        },
        {
          label: i18n.getLocalesTranslations('literal.specific_date'),
          value: 'specific_date'
        }
      ],
      content: {
        label: i18n.getLocalesTranslations('literal.default_value')
      }
    },
    {
      path: 'molecules/ModuleInputDatepicker',
      model: 'attributes.default_value',
      conditions: [
        {
          logic_operator: 'and',
          model: 'attributes.default_option',
          value: 'specific_date',
          comparison_operator: 'eq'
        }
      ]
    }
  ],
  components: [
    {
      path: 'molecules/ModuleInputCheckboxMandatory',
      value: 'required',
      content: {
        label: i18n.getLocalesTranslations('$procedure_block.label_is_mandatory')
      }
    }
  ]
}
