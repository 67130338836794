import i18n from '@/vendors/i18n'

export default {
  name: 'transaction_amount',
  is_allowed: {
    'procedure.category': ['donation']
  },
  settings: {
    icon: 'amount_choice',
    category: 'payments',
    require_model: true
  },
  model: 'actions_data.transaction.form_amount',
  type: 'input_amount',
  components: [
    {
      path: 'molecules/ModuleTabs',
      model: 'attributes.active_tab',
      value: 'one_time',
      options: 'transaction_frequency.attributes.options'
    },
    {
      path: 'molecules/ModuleRepeater',
      model: 'attributes.one_time.amounts',
      content: {
        label: i18n.getLocalesTranslations('$block_amount_choice.card_title_amounts'),
        btn: i18n.getLocalesTranslations('$block_amount_choice.btn_add_new_amount')
      },
      settings: {
        max_limit: 5
      },
      value: [
        { value: 1000 },
        { value: 2500 },
        { value: 4000 },
        { value: 10000 }
      ],
      components: [
        {
          path: 'molecules/ModuleInputAmount',
          model: 'value'
        }
      ],
      conditions: [
        {
          logic_operator: 'and',
          model: 'attributes.active_tab',
          value: 'one_time',
          comparison_operator: 'eq'
        }
      ]
    },
    {
      path: 'molecules/ModuleInputSelectAmount',
      model: 'attributes.one_time.highlighted_amount',
      options: 'attributes.one_time.amounts',
      value: 2500,
      content: {
        label: i18n.getLocalesTranslations('$block_amount_choice.default_highlighted_amount_label')
      },
      conditions: [
        {
          logic_operator: 'and',
          model: 'attributes.active_tab',
          value: 'one_time',
          comparison_operator: 'eq'
        }
      ]
    },
    {
      path: 'molecules/ModuleInputCheckbox',
      model: 'attributes.one_time.custom_amount_field_required',
      value: true,
      content: {
        label: i18n.getLocalesTranslations('$block_amount_choice.label_enable_custom_amount')
      },
      conditions: [
        {
          logic_operator: 'and',
          model: 'attributes.active_tab',
          value: 'one_time',
          comparison_operator: 'eq'
        }
      ]
    },
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.one_time.label',
      value: i18n.getLocalesTranslations('$block_amount_choice.label_custom_amount'),
      content: {
        label: i18n.getLocalesTranslations('literal.question')
      },
      conditions: [
        {
          logic_operator: 'and',
          model: 'attributes.one_time.custom_amount_field_required',
          value: true,
          comparison_operator: 'eq'
        },
        {
          logic_operator: 'and',
          model: 'attributes.active_tab',
          value: 'one_time',
          comparison_operator: 'eq'
        }
      ]
    },
    {
      path: 'molecules/ModuleRepeater',
      model: 'attributes.monthly.amounts',
      content: {
        label: i18n.getLocalesTranslations('$block_amount_choice.card_title_amounts'),
        btn: i18n.getLocalesTranslations('$block_amount_choice.btn_add_new_amount')
      },
      settings: {
        max_limit: 5
      },
      value: [
        { value: 500 },
        { value: 1000 },
        { value: 2000 },
        { value: 5000 }
      ],
      components: [
        {
          path: 'molecules/ModuleInputAmount',
          model: 'value'
        }
      ],
      conditions: [
        {
          logic_operator: 'and',
          model: 'attributes.active_tab',
          value: 'monthly',
          comparison_operator: 'eq'
        }
      ]
    },
    {
      path: 'molecules/ModuleInputSelectAmount',
      model: 'attributes.monthly.highlighted_amount',
      options: 'attributes.monthly.amounts',
      value: 1000,
      content: {
        label: i18n.getLocalesTranslations('$block_amount_choice.default_highlighted_amount_label')
      },
      conditions: [
        {
          logic_operator: 'and',
          model: 'attributes.active_tab',
          value: 'monthly',
          comparison_operator: 'eq'
        }
      ]
    },
    {
      path: 'molecules/ModuleInputCheckbox',
      model: 'attributes.monthly.custom_amount_field_required',
      value: true,
      content: {
        label: i18n.getLocalesTranslations('$block_amount_choice.label_enable_custom_amount')
      },
      conditions: [
        {
          logic_operator: 'and',
          model: 'attributes.active_tab',
          value: 'monthly',
          comparison_operator: 'eq'
        }
      ]
    },
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.monthly.label',
      value: i18n.getLocalesTranslations('$block_amount_choice.label_custom_amount'),
      content: {
        label: i18n.getLocalesTranslations('literal.question')
      },
      conditions: [
        {
          logic_operator: 'and',
          model: 'attributes.monthly.custom_amount_field_required',
          value: true,
          comparison_operator: 'eq'
        },
        {
          logic_operator: 'and',
          model: 'attributes.active_tab',
          value: 'monthly',
          comparison_operator: 'eq'
        }
      ]
    }
  ],
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_select_amount')
  }
}
