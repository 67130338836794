import i18n from '@/vendors/i18n'

export default {
  name: 'custom_towalkagain_waaromsteunen',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.banner')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      content: {
        label: i18n.getLocalesTranslations('literal.background')
      },
      model: 'banner.background',
      settings: {
        crop: false,
        size: { width: 1600, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'banner.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'banner.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Waarom',
          'fr-FR': 'Pourquoi',
          'en-GB': 'Why',
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'why.items',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'title'
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'img_src',
          settings: {
            crop: false,
            size: { width: 600, height: 9999 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          },
          settings: {
            configuration: ['bold', 'italic', 'underline']
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Hoe',
          'fr-FR': 'Comment',
          'en-GB': 'How',
        }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'how.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'how.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'how.videos',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'title'
      },
      components: [
        {
          path: 'modules/pages/SectionInputUrl',
          model: 'video_src',
          content: {
            label: i18n.getLocalesTranslations('literal.video')
          }
        },
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'img_src',
          settings: {
            crop: false,
            size: { width: 340, height: 340 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          },
          settings: {
            configuration: ['bold', 'italic', 'underline']
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'how.button',
      content: {
        label: i18n.getLocalesTranslations('literal.button')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Doel',
          'fr-FR': 'But',
          'en-GB': 'Goal',
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'goal.items',
      settings: {
        hide_label: true
      },
      content: {
        wrapper_title: 'title'
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'img_src',
          settings: {
            crop: false,
            size: { width: 600, height: 9999 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          },
          settings: {
            configuration: ['bold', 'italic', 'underline']
          }
        }
      ]
    }
  ],
  content: {
    title: {
      'en-GB': 'Waaromsteunen',
      'fr-FR': 'Waaromsteunen',
      'nl-NL': 'Waaromsteunen'
    }
  }
}
