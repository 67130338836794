import i18n from '@/vendors/i18n'
import { projects_overview_options } from '@/configurations/general/components-default-data'

export default {
  name: 'custom_homepage_uzbrusselfoundation',
  settings: {
    icon: 'text_editor',
    unavailable: true,
    show_visible_option: false,
    show_delete_option: false
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.banner')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      content: {
        label: i18n.getLocalesTranslations('literal.image')
      },
      model: 'banner.image',
      settings: {
        crop: false,
        size: { width: 1280, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'banner.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'banner.text',
      settings: {
        configuration: ['bold', 'italic', 'underline']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'banner.buttons',
      settings: {
        layout: 'inline'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.buttons'),
        btn_label: i18n.getLocalesTranslations('literal.add_new_button')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'button',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.projects')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'projects.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionSelectCampaigns',
      model: 'projects.query.campaign_id',
      content: {
        label: i18n.getLocalesTranslations('literal.show_only_projects_from_campaigns')
      }
    },
    {
      path: 'modules/pages/SectionSelect',
      model: 'projects.query.sort',
      value: 'created_at_first',
      settings: {
        options: projects_overview_options
      },
      content: {
        label: i18n.getLocalesTranslations('literal.sort_project_by')
      }
    },
    {
      path: 'modules/pages/SectionInputNumber',
      model: 'projects.query.per_page',
      value: 8,
      content: {
        label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'campaigns.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'campaigns.items',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns'),
        btn_label: i18n.getLocalesTranslations('literal.add_campaign')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'img',
          settings: {
            size: { width: 400, height: 400 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          settings: {
            configuration: ['bold', 'italic', 'underline']
          },
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'button',
          content: {
            label: i18n.getLocalesTranslations('literal.link')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.steps')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'steps.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'steps.items',
      content: {
        label: i18n.getLocalesTranslations('literal.steps'),
        btn_label: i18n.getLocalesTranslations('literal.add_step')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'img',
          settings: {
            size: { width: 250, height: 250 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'number',
          content: {
            label: i18n.getLocalesTranslations('literal.step_number')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          settings: {
            configuration: ['bold', 'italic', 'underline']
          },
          content: {
            label: i18n.getLocalesTranslations('literal.step_description')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'tip',
          content: {
            label: i18n.getLocalesTranslations('literal.tip')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.call_to_action')
      }
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'cta.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'cta.button',
      content: {
        label: i18n.getLocalesTranslations('literal.button')
      }
    }
  ],
  content: {
    title: {
      'en-GB': 'UZ Brussel Foundation',
      'fr-FR': 'UZ Brussel Foundation',
      'nl-NL': 'UZ Brussel Foundation'
    }
  }
}
