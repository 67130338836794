<template>
  <aside class="sidebar">
    <header class="header padding--24">
      <router-link
        :to="{ name: 'Home', params: { locale } }"
        class="d-block"
      >
        <img
          :src="getImagePath('logo/mykoalect-raisenow-logo.svg')"
          alt="MyKoalect logo"
        >
      </router-link>
    </header>

    <main class="main padding--12">
      <menu-list />
    </main>

    <footer class="footer padding--12">
      <router-link
        class="footer__profile"
        :to="{ name: 'Profile', params:{ locale } }"
      >
        <user-profile-img
          size="4"
          :avatar="authUser.avatar"
          :active-state="true"
        />

        <span
          class="profile__label"
          :class="{'active': route.name && route.name.startsWith('Profile') }"
        >
          {{ t('$sidebar_menu.my_account') }}
        </span>
      </router-link>
    </footer>
  </aside>
</template>

<script setup>
import { getImagePath } from '@/utils/utils'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { useI18n } from '@/vendors/i18n'
import { useRoute } from 'vue-router'

import UserProfileImg from '&/atoms/UserProfileImg'
import MenuList from '&/molecules/MenuList'

const { t, locale } = useI18n()
const route = useRoute()

const { getAuthUser } = useAuthenticationStore()
const { authUser } = getAuthUser()
</script>

<style lang="scss">
.sidebar {
  position: fixed;
  height: 100vh;
  top: 0;
  background-color: $white;
  width: $sidebar--width;
  display: none;
  box-shadow: 2px 0px 20px 0px rgba($dw, .5);

  @include mq(md) {
    display: flex;
    flex-direction: column;
  }

  .header {
    flex: 0 0 auto;
    position: relative;
    z-index: 10;

    &::after {
      content: '';
      background: rgb(255,255,255);
      background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
      position: absolute;
      bottom: -24px;
      left: 0;
      width: calc(100% - 5px);
      height: 24px;
    }
  }

  .main {
    overflow-y: auto;
    flex: 1 1 auto;
    scrollbar-width: auto;
    scrollbar-color: $vdw #ffffff;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $vdw;
      border-radius: 10px;
      border: 1px solid #ffffff;
    }
  }

  .footer {
    flex: 0 0 auto;
    position: relative;

    &::before {
      content: '';
      background: rgb(255,255,255);
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
      position: absolute;
      top: -24px;
      left: 0;
      width: calc(100% - 5px);
      height: 24px;
    }

    &__profile {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .profile__label {
        margin-left: $margin__base;
        transition: all $transition__duration;

        &.active {
          color: $color--secondary;
        }
      }

      &:hover {
        .profile__label {
          color: $color--secondary;
        }
      }
    }
  }
}
</style>
