import i18n from '@/vendors/i18n'

export default {
  name: 'custom_checkbox_field',
  type: 'input_checkbox',
  settings: {
    icon: 'input_checkbox',
    require_model: true
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_input_checkbox')
  },
  fields: [
    {
      path: 'molecules/ModuleInputTextEditor',
      model: 'attributes.label',
      content: {
        label: i18n.getLocalesTranslations('literal.default_label')
      },
      settings: {
        configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
      }
    },
    {
      path: 'molecules/ModuleInputSelect',
      model: 'attributes.default_value',
      value: false,
      options: [
        {
          label: i18n.getLocalesTranslations('literal.checked'),
          value: true
        },
        {
          label: i18n.getLocalesTranslations('literal.not_checked'),
          value: false
        }
      ],
      content: {
        label: i18n.getLocalesTranslations('literal.default_value')
      }
    }
  ],
  components: [
    {
      path: 'molecules/ModuleInputCheckboxMandatory',
      value: 'sameAs:true',
      content: {
        label: i18n.getLocalesTranslations('$procedure_block.label_is_mandatory')
      }
    }
  ]
}
