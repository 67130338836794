import { defineStore } from 'pinia'
import { useClientStore } from '@/stores/ClientStore'
import { useAuthenticationStore } from '@/stores/AuthenticationStore'
import { get as _get } from 'lodash'
import available from '@/vendors/i18n/translations/available'
import i18n from '@/vendors/i18n'
import axios from '@/vendors/axios/axios'

export const useLanguageStore = () => {
  const { authUser } = useAuthenticationStore()
  const { getClient } = useClientStore()
  const { client } = getClient()

  const innerStore = defineStore('LanguageStore', {
    state: () => ({
      available_languages: Object.keys(available) || [],
      client_languages: _get(client.value, 'active_languages', []),
      locale: null,
      translation_locale: null
    }),
    getters: {
      getBrowserLocale: state => state.getIfAvailable(navigator.userLanguage || (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || navigator.systemLanguage),
      getLocaleStorage: state => state.getIfAvailable(localStorage.locale),
      getLocaleFromUrl: state => state.getIfAvailable(window.location.pathname.split('/')[1]),
      getLocaleFromUser: state => state.getIfAvailable(_get(authUser, 'language', null)),
      getTranslationLocale: state => state.client_languages.includes(state.locale) ? state.locale : state.client_languages[0],
      getDocumentLanguage: state => state.locale.split('-')[0],
      getDefaultLocale: state => state.client_languages[0]
    },
    actions: {
      setLocale (locale = null) {
        this.locale = i18n.locale = localStorage.locale = locale || this.getLocaleFromUser || this.getLocaleFromUrl || this.getLocaleStorage || this.getBrowserLocale || this.getDefaultLocale
        this.setTranslationLocale()

        document.querySelector('html').setAttribute('lang', this.getDocumentLanguage)
        axios.defaults.headers.common['Accept-Language'] = this.getDocumentLanguage
      },
      getIfAvailable (locale) {
        return locale && this.available_languages.includes(locale) ? locale : null
      },
      setTranslationLocale (locale = null) {
        this.translation_locale = locale || this.getTranslationLocale
      }
    }
  })

  const store = innerStore()

  if (!store.locale) store.setLocale()

  return store
}