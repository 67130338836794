import i18n from '@/vendors/i18n'
import Block from '@/classes/procedures/Block'

export default {
  name: 'project_benefiting',
  content: {
    title: i18n.getLocalesTranslations('$procedure_section.type_project_benefiting'),
    description: i18n.getLocalesTranslations('$procedure_section.type_project_benefiting_description')
  },
  options: {
    collapse: false
  },
  blocks: [
    Block.set({
      name: 'title',
      attributes: {
        title: i18n.getLocalesTranslations('literal.beneficiary')
      }
    }),
    Block.set({ name: 'project_benefiting' })
  ]
}
