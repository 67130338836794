import procedureBlocks from '@/configurations/procedures/blocks'
import { clone } from '@/utils/utils'
import { merge as _merge, get as _get, set as _set, omit as _omit } from 'lodash'

let Block = class Block {
  constructor ({ name, model, type, settings, content, components, is_allowed, is_required, is_not_editable, options, conditions, attributes, mandatory } = {}) {
    this.name = name || null
    this.model = model || null
    this.type = type || null
    this.settings = settings || {}
    this.content = content || {}
    this.components = components || []
    this.is_allowed = is_allowed || {}
    this.is_required = is_required || {}
    this.is_not_editable = is_not_editable || {}
    this.options = options || {}
    this.conditions = conditions || []
    this.attributes = attributes || {}
    this.mandatory = mandatory || []
  }

  static set (config) {
    let block = clone(procedureBlocks.find(b => b.name === config.name))
    block = _merge(block, config)

    block.components?.forEach(component => {
      if (component.value && component.model) _set(block, component.model, component.value)
    })

    return new Block(block)
  }

  hasUnpatchableModel = () => {
    return !!this.model && !this.model.includes('informations.')
  }

  formatted () {
    return { ...this, attributes: _omit(this.attributes, 'active_tab') }
  }

  certify = (attribute, models) => {
    if (!Object.keys(this[attribute]).length) return attribute !== 'is_required'

    return Object.keys(this[attribute]).every(key => {
      const model = _get(models, key.split('.')[0])
      const value = _get(model, key.split('.').slice(1).join('.'))

      if (!value) return true
      if (typeof value === 'string') return this[attribute][key].includes(value)
      if (typeof value === 'object') return this[attribute][key].some(subkey => typeof _get(value, subkey) === 'boolean' ? value[subkey] : Object.keys(value).includes(subkey))
      if (typeof value === 'boolean') return this[attribute][key] === !!value
    })
  }

  requiresTransactionAction = () => {
    return Array.isArray(this.is_allowed['procedure.actions']) && this.is_allowed['procedure.actions'].includes('transaction')
  }

  database = () => {
    return {
      name: this.name,
      attributes: this.attributes,
      model: this.model,
      mandatory: this.mandatory,
      options: this.options,
      conditions: this.conditions
    }
  }

  get is_custom_field () {
    return this.model?.includes('informations.custom_fields.')
  }

  get custom_field_id () {
    return this.model?.replace('informations.custom_fields.', '')
  }

  get to_custom_field () {
    return {
      name: this.name,
      attributes: this.attributes,
      options: this.options
    }
  }

  static = () => {
    let blockNormalized = clone(procedureBlocks.find(block => block.name === this.name))
    return blockNormalized ? new Block(_merge(blockNormalized, this.database())) : this
  }
}

export default Block
