import pageSections from '@/configurations/pages'
import { clone } from '@/utils/utils'
import { merge as _merge } from 'lodash'

let Section = class Section {
  constructor ({ name, settings, content, style, type, attributes, options, components } = {}) {
    this.name = name || null
    this.style = style || {}
    this.type = type || null
    this.attributes = attributes || {}
    this.options = options || {}
    this.settings = settings || {}
    this.content = content || {}
    this.components = components || []
  }

  database = () => {
    return {
      name: this.name,
      style: this.style,
      type: this.type,
      attributes: this.attributes,
      options: this.options
    }
  }

  static = () => {
    const referenceSection = clone(pageSections.find(section => section.name === this.name))
    return referenceSection ? new Section(_merge(referenceSection, this.database())) : this
  }
}

export default Section
