import { defineStore } from 'pinia'
import { $cookies } from '@/utils/utils'
import { useLanguageStore } from '@/stores/LanguageStore'
import { get as _get } from 'lodash'
import Tracking from '@/vendors/tracking'

export const useAuthenticationStore = defineStore('AuthenticationStore', {
  state: () => ({
    auth_user: {},
    loader: false,
    error: {}
  }),
  getters: {
    getAuthUser: state => () => {
      return state.$getItem({
        resource: 'auth_user',
        fetch: () => state.fetchAuthUser()
      })
    },
    authUser: state => _get(state, 'auth_user.item'),
    isAuthUser: state => !!state.authUser
  },
  actions: {
    initAuthentication () {
      return new Promise((resolve, reject) => {
        if (this.isAuthUser) {
          resolve(this.authUser)
        } else if ($cookies.get('authToken')) {
          return this.fetchAuthUser()
            .then(user => resolve(user))
            .catch(error => reject(error))
        } else {
          resolve(null)
        }
      })
    },
    fetchAuthUser () {
      return this.$fetchItem({
        endpoint: '/auth/user',
        resource: 'auth_user'
      }).then(user => {
        const { setLocale } = useLanguageStore()
        setLocale(user.language)
        Tracking.session.init({ user })
        Tracking.user.set({ user })
        return user
      })
    },
    postAuthUser ({ params, token }) {
      return this.$postItem({
        endpoint: '/auth/user',
        resource: 'auth_user',
        params: {
          user: params,
          token
        }
      })
    },
    patchAuthUser ({ params }) {
      return this.$patchItem({
        endpoint: '/auth/user',
        resource: 'auth_user',
        params: {
          user: params
        }
      })
    },
    postAuthUserPassword ({ params }) {
      return this.$postItem({
        endpoint: '/auth/user/forgot_password',
        params: {
          user: params
        }
      })
    },
    patchAuthUserPassword ({ params, token }) {
      return this.$patchItem({
        endpoint: '/auth/user/password',
        params: {
          user: params,
          token
        }
      })
    },
    patchAuthUserImage ({ params }) {
      return this.$patchItem({
        endpoint: '/auth/user',
        resource: 'auth_user',
        params,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
    },
    login ({ params }) {
      return this.$postItem({
        endpoint: '/auth/login',
        params: {
          user: params
        }
      }).then(({ auth_token }) => {
        $cookies.set({ name: 'authToken', value: auth_token })
        Tracking.session.login({ type: 'Basic' })
        return null
      })
    },
    logout () {
      $cookies.delete({ name: 'authToken' })
      this.$resetStore('auth_user')
    }
  }
})