import i18n from '@/vendors/i18n'

export default [
  {
    name: 'homepage_designmuseumgent_campaign',
    settings: {
      icon: 'banner',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionRepeater',
        model: 'images',
        content: {
          label: i18n.getLocalesTranslations('literal.slider'),
          btn_label: i18n.getLocalesTranslations('literal.add_image'),
          default_wrapper_title: i18n.getLocalesTranslations('literal.image')
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'image',
            settings: {
              crop: true,
              size: { width: 1000, height: 780 }
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        },
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link', 'text_color', 'list_ul', 'list_ol']
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'button',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: {
        'en-GB': 'Campaign',
        'fr-FR': 'Campaign',
        'nl-NL': 'Campaign'
      }
    }
  },
  {
    name: 'homepage_designmuseumgent_goal',
    settings: {
      icon: 'target',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        },
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link', 'text_color', 'list_ul', 'list_ol']
        }
      },
      {
        path: 'modules/pages/SectionImageUpload',
        model: 'image',
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        },
        settings: {
          crop: false,
          size: { width: 300, height: 9999 }
        }
      }
    ],
    content: {
      title: {
        'en-GB': 'Goal',
        'fr-FR': 'Goal',
        'nl-NL': 'Goal'
      }
    }
  },
  {
    name: 'homepage_designmuseumgent_building',
    settings: {
      icon: 'location',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        },
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link', 'text_color', 'list_ul', 'list_ol']
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'steps.from_0_to_50k',
        content: {
          default_wrapper_title: {
            'en-GB': 'From 0 to 50k',
            'fr-FR': 'From 0 to 50k',
            'nl-NL': 'From 0 to 50k'
          },
          wrapper_title: 'label'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionInputAmount',
            model: 'amount',
            content: {
              label: i18n.getLocalesTranslations('literal.amount')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'steps.from_50_to_100k',
        content: {
          default_wrapper_title: {
            'en-GB': 'From 50 to 100k',
            'fr-FR': 'From 50 to 100k',
            'nl-NL': 'From 50 to 100k'
          },
          wrapper_title: 'label'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionInputAmount',
            model: 'amount',
            content: {
              label: i18n.getLocalesTranslations('literal.amount')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'steps.from_100_to_150k',
        content: {
          default_wrapper_title: {
            'en-GB': 'From 100 to 150k',
            'fr-FR': 'From 100 to 150k',
            'nl-NL': 'From 100 to 150k'
          },
          wrapper_title: 'label'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionInputAmount',
            model: 'amount',
            content: {
              label: i18n.getLocalesTranslations('literal.amount')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'steps.from_150_to_200k',
        content: {
          default_wrapper_title: {
            'en-GB': 'From 150 to 200k',
            'fr-FR': 'From 150 to 200k',
            'nl-NL': 'From 150 to 200k'
          },
          wrapper_title: 'label'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionInputAmount',
            model: 'amount',
            content: {
              label: i18n.getLocalesTranslations('literal.amount')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'steps.from_200_to_250k',
        content: {
          default_wrapper_title: {
            'en-GB': 'From 200 to 250k',
            'fr-FR': 'From 200 to 250k',
            'nl-NL': 'From 200 to 250k'
          },
          wrapper_title: 'label'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionInputAmount',
            model: 'amount',
            content: {
              label: i18n.getLocalesTranslations('literal.amount')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'steps.from_250_to_300k',
        content: {
          default_wrapper_title: {
            'en-GB': 'From 250 to 300k',
            'fr-FR': 'From 250 to 300k',
            'nl-NL': 'From 250 to 300k'
          },
          wrapper_title: 'label'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionInputAmount',
            model: 'amount',
            content: {
              label: i18n.getLocalesTranslations('literal.amount')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'steps.from_300_to_350k',
        content: {
          default_wrapper_title: {
            'en-GB': 'From 300 to 350k',
            'fr-FR': 'From 300 to 350k',
            'nl-NL': 'From 300 to 350k'
          },
          wrapper_title: 'label'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionInputAmount',
            model: 'amount',
            content: {
              label: i18n.getLocalesTranslations('literal.amount')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'steps.from_350_to_400k',
        content: {
          default_wrapper_title: {
            'en-GB': 'From 350 to 400k',
            'fr-FR': 'From 350 to 400k',
            'nl-NL': 'From 350 to 400k'
          },
          wrapper_title: 'label'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionInputAmount',
            model: 'amount',
            content: {
              label: i18n.getLocalesTranslations('literal.amount')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'steps.from_400_to_450k',
        content: {
          default_wrapper_title: {
            'en-GB': 'From 400 to 450k',
            'fr-FR': 'From 400 to 450k',
            'nl-NL': 'From 400 to 450k'
          },
          wrapper_title: 'label'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionInputAmount',
            model: 'amount',
            content: {
              label: i18n.getLocalesTranslations('literal.amount')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'steps.from_450_to_500k',
        content: {
          default_wrapper_title: {
            'en-GB': 'From 450 to 500k',
            'fr-FR': 'From 450 to 500k',
            'nl-NL': 'From 450 to 500k'
          },
          wrapper_title: 'label'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionInputAmount',
            model: 'amount',
            content: {
              label: i18n.getLocalesTranslations('literal.amount')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'steps.from_500_to_550k',
        content: {
          default_wrapper_title: {
            'en-GB': 'From 500 to 550k',
            'fr-FR': 'From 500 to 550k',
            'nl-NL': 'From 500 to 550k'
          },
          wrapper_title: 'label'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionInputAmount',
            model: 'amount',
            content: {
              label: i18n.getLocalesTranslations('literal.amount')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'steps.from_550_to_600k',
        content: {
          default_wrapper_title: {
            'en-GB': 'From 550 to 600k',
            'fr-FR': 'From 550 to 600k',
            'nl-NL': 'From 550 to 600k'
          },
          wrapper_title: 'label'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionInputAmount',
            model: 'amount',
            content: {
              label: i18n.getLocalesTranslations('literal.amount')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'steps.from_600_to_650k',
        content: {
          default_wrapper_title: {
            'en-GB': 'From 600 to 650k',
            'fr-FR': 'From 600 to 650k',
            'nl-NL': 'From 600 to 650k'
          },
          wrapper_title: 'label'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionInputAmount',
            model: 'amount',
            content: {
              label: i18n.getLocalesTranslations('literal.amount')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'steps.from_650_to_700k',
        content: {
          default_wrapper_title: {
            'en-GB': 'From 650 to 700k',
            'fr-FR': 'From 650 to 700k',
            'nl-NL': 'From 650 to 700k'
          },
          wrapper_title: 'label'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionInputAmount',
            model: 'amount',
            content: {
              label: i18n.getLocalesTranslations('literal.amount')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'steps.from_700k',
        content: {
          default_wrapper_title: {
            'en-GB': 'From 700k',
            'fr-FR': 'From 700k',
            'nl-NL': 'From 700k'
          },
          wrapper_title: 'label'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'label',
            content: {
              label: i18n.getLocalesTranslations('literal.label')
            }
          },
          {
            path: 'modules/pages/SectionInputAmount',
            model: 'amount',
            content: {
              label: i18n.getLocalesTranslations('literal.amount')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'button',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: {
        'en-GB': 'Building',
        'fr-FR': 'Building',
        'nl-NL': 'Building'
      }
    }
  },
  {
    name: 'homepage_designmuseumgent_donations',
    settings: {
      icon: 'contribute',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'individual',
        content: {
          default_wrapper_title: {
            'en-GB': 'Individual',
            'fr-FR': 'Particulier',
            'nl-NL': 'Particulier'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.content')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'link', 'text_color', 'list_ul', 'list_ol']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'link',
            content: {
              label: i18n.getLocalesTranslations('literal.link')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'company',
        content: {
          default_wrapper_title: {
            'en-GB': 'Company',
            'fr-FR': 'Société',
            'nl-NL': 'Bedrijf'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.content')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'link', 'text_color', 'list_ul', 'list_ol']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'link',
            content: {
              label: i18n.getLocalesTranslations('literal.link')
            }
          }
        ]
      }
    ],
    content: {
      title: {
        'en-GB': 'Donations',
        'fr-FR': 'Donations',
        'nl-NL': 'Donations'
      }
    }
  },
  {
    name: 'homepage_designmuseumgent_beneficiaries',
    settings: {
      icon: 'love_hand',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'left',
        content: {
          default_wrapper_title: {
            'en-GB': 'Left panel',
            'fr-FR': 'Left panel',
            'nl-NL': 'Left panel'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'image',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            settings: {
              crop: true,
              size: { width: 516, height: 322 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.content')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'link', 'text_color', 'list_ul', 'list_ol']
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'right',
        content: {
          default_wrapper_title: {
            'en-GB': 'Right panel',
            'fr-FR': 'Right panel',
            'nl-NL': 'Right panel'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'image',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            settings: {
              crop: true,
              size: { width: 516, height: 322 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.content')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'link', 'text_color', 'list_ul', 'list_ol']
            }
          }
        ]
      }
    ],
    content: {
      title: {
        'en-GB': 'Beneficiaries',
        'fr-FR': 'Beneficiaries',
        'nl-NL': 'Beneficiaries'
      }
    }
  },
  {
    name: 'homepage_designmuseumgent_contributions_individual',
    settings: {
      icon: 'amount',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionSelectProjects',
        model: 'project_id',
        content: {
          label: i18n.getLocalesTranslations('literal.project')
        },
        settings: {
          limit: 1
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'button',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: {
        'en-GB': 'Contributions – Individual',
        'fr-FR': 'Contributions – Individual',
        'nl-NL': 'Contributions – Individual'
      }
    }
  },
  {
    name: 'homepage_designmuseumgent_contributions_company',
    settings: {
      icon: 'amount',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionSelectProjects',
        model: 'project_id',
        content: {
          label: i18n.getLocalesTranslations('literal.project')
        },
        settings: {
          limit: 1
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'button',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: {
        'en-GB': 'Contributions – Company',
        'fr-FR': 'Contributions – Company',
        'nl-NL': 'Contributions – Company'
      }
    }
  }
]