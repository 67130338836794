import i18n from '@/vendors/i18n'

export default {
  name: 'informations_manual_domiciliation_amount',
  model: 'informations.manual_domiciliation.amount',
  type: 'input_amount',
  settings: {
    icon: 'amount_choice',
    require_model: true,
    is_deletable: false
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_informations_domiciliation_amount')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.title',
      value: i18n.getLocalesTranslations('$block_informations_domiciliation_amount.title_domiciliation_amount'),
      content: {
        label: i18n.getLocalesTranslations('literal.label')
      }
    },
    {
      path: 'molecules/ModuleRepeater',
      model: 'attributes.amounts',
      content: {
        label: i18n.getLocalesTranslations('$block_amount_choice.card_title_amounts'),
        btn: i18n.getLocalesTranslations('$block_amount_choice.btn_add_new_amount')
      },
      settings: {
        max_limit: 5
      },
      value: [
        { value: 1000 },
        { value: 2500 },
        { value: 4000 },
        { value: 10000 }
      ],
      components: [
        {
          path: 'molecules/ModuleInputAmount',
          model: 'value'
        }
      ]
    },
    {
      path: 'molecules/ModuleInputSelectAmount',
      model: 'attributes.highlighted_amount',
      options: 'attributes.amounts',
      value: 2500,
      content: {
        label: i18n.getLocalesTranslations('$block_amount_choice.default_highlighted_amount_label')
      }
    },
    {
      path: 'molecules/ModuleInputCheckbox',
      model: 'attributes.custom_amount_field_required',
      value: true,
      content: {
        label: i18n.getLocalesTranslations('$block_amount_choice.label_enable_custom_amount')
      }
    },
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      value: i18n.getLocalesTranslations('$block_amount_choice.label_custom_amount'),
      content: {
        label: i18n.getLocalesTranslations('literal.question')
      },
      conditions: [
        {
          logic_operator: 'and',
          model: 'attributes.custom_amount_field_required',
          value: true,
          comparison_operator: 'eq'
        }
      ]
    }
  ],
  is_allowed: {
    'procedure.category': ['donation'],
    'client.allowed_features': ['manual_domiciliation']
  }
}
