import i18n from '@/vendors/i18n'

export default {
  name: 'transaction_frequency',
  is_allowed: {
    'procedure.category': ['donation']
  },
  settings: {
    icon: 'synchronize',
    category: 'payments',
    require_model: true
  },
  model: 'actions_data.transaction.frequency',
  type: 'input_select',
  components: [
    {
      path: 'modules/procedures/BlockPaymentFrequency',
      model: 'attributes.options',
    }
  ],
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_transaction_frequency')
  }
}
