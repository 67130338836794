import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faArrowLeft as fasArrowLeft,
  faEuroSign as fasEuroSign,
  faLongArrowAltLeft as fasLongArrowAltLeft,
  faChevronDown as fasChevronDown,
  faCheck as fasCheck,
  faTimes as fasTimes,
  faChevronRight as fasChevronRight,
  faChevronLeft as fasChevronLeft,
  faArrowUp as fasArrowUp,
  faArrowRight as fasArrowRight,
  faEllipsisH as fasEllipsisH,
  faBars as fasBars,
  faSearch as fasSearch,
  faPlus as fasPlus,
  faGlobe as fasGlobe,
  faExclamationTriangle as fasExclamationTriangle,
  faExclamationCircle as fasExclamationCircle,
  faGripVertical as fasGripVertical,
  faPlusCircle as fasPlusCircle,
  faFont as fasFont,
  faAlignLeft as fasAlignLeft,
  faAt as fasAt,
  faCloudUploadAlt as fasCloudUploadAlt,
  faShare as fasShare,
  faRedo as fasRedo,
  faInfoCircle as fasInfoCircle,
  faUniversity as fasUniversity,
  faHourglassHalf as fasHourglassHalf,
  faSync as fasSync,
  faUserPlus as fasUserPlus,
  faFilter as fasFilter
} from '@fortawesome/free-solid-svg-icons'

import {
  faFacebookSquare as fabFacebookSquare,
  faFacebookMessenger as fabFacebookMessenger,
  faWhatsapp as fabWhatsapp,
  faTwitterSquare as fabTwitterSquare,
  faTwitter as fabTwitter,
  faXTwitter as fabXTwitter,
  faLinkedin as fabLinkedin,
  faInstagram as fabInstagram,
  faYoutube as fabYoutube,
  faMedium as fabMedium,
  faTiktok as fabTiktok,
  faTwitch as fabTwitch
} from '@fortawesome/free-brands-svg-icons'

import {
  faBold as falBold,
  faItalic as falItalic,
  faUnderline as falUnderline,
  faH1 as falH1,
  faH2 as falH2,
  faListUl as falListUl,
  faListOl as falListOl,
  faQuoteRight as falQuoteRight,
  faUndo as falUndo,
  faRedo as falRedo,
  faPaperclip as falPaperclip,
  faChevronUp as falChevronUp,
  faChevronDown as falChevronDown,
  faChevronRight as falChevronRight,
  faChevronLeft as falChevronLeft,
  faCheck as falCheck,
  faTimes as falTimes,
  faCog as falCog,
  faHandshake as falHandshake,
  faBookmark as falBookmark,
  faMoneyBill as falMoneyBill,
  faTachometer as falTachometer,
  faBars as falBars,
  faSignOut as falSignOut,
  faFile as falFile,
  faQuestionCircle as falQuestionCircle,
  faTrash as falTrash,
  faBadgeCheck as falBadgeCheck,
  faBadge as falBadge,
  faCloudUpload as falCloudUpload,
  faExclamationTriangle as falExclamationTriangle,
  faExclamationCircle as falExclamationCircle,
  faEmptySet as falEmptySet,
  faFilter as falFilter,
  faDownload as falDownload,
  faSearch as falSearch,
  faCheckSquare as falCheckSquare,
  faFont as falFont,
  faAlignLeft as falAlignLeft,
  faAlignCenter as falAlignCenter,
  faAlignRight as falAlignRight,
  faUser as falUser,
  faAt as falAt,
  faAddressCard as falAddressCard,
  faCreditCard as falCreditCard,
  faCalendarAlt as falCalendarAlt,
  faFileAlt as falFileAlt,
  faShare as falShare,
  faExternalLinkSquare as falExternalLinkSquare,
  faExternalLink as falExternalLink,
  faImage as falImage,
  faTimesCircle as falTimesCircle,
  faCheckCircle as falCheckCircle,
  faUsers as falUsers,
  faHourglassHalf as falHourglassHalf,
  faLink as falLink,
  faUnlink as falUnlink,
  faPlusCircle as falPlusCircle,
  faFlag as falFlag,
  faBan as falBan,
  faSave as falSave,
  faCopy as falCopy,
  faPollH as falPollH,
  faLightbulb as falLightbulb,
  faAnalytics as falAnalytics,
  faCircle as falCircle,
  faSquare as falSquare,
  faCalendar as falCalendar,
  faPencilPaintbrush as falPencilPaintbrush,
  faClock as falClock,
  faCreditCardFront as falCreditCardFront,
  faStar as falStar,
  faText as falText,
  faQrcode as falQrcode,
  faDirections as falDirections,
  faFileImage as falFileImage,
  faGripVertical as falGripVertical,
  faVial as falVial,
  faPalette as falPalette,
  faCode as falCode,
  faPlus as falPlus,
  faSync as falSync,
  faInfoCircle as falInfoCircle,
  faTable as falTable,
  faBasketballBall as falBasketballBall,
  faHashtag as falHashtag,
  faPercent as falPercent,
  faSortUp as falSortUp,
  faSortDown as falSortDown,
  faEye as falEye,
  faEyeSlash as falEyeSlash,
  faPenToSquare as falPenToSquare,
  faHandHoldingHeart as falHandHoldingHeart,
  faRotateRight as falRotateRight,
  faXmark as falXmark,
  faSquareMinus as falSquareMinus,
  faSquarePlus as falSquarePlus,
  faTableLayout as falTableLayout,
  faEllipsis as falEllipsis,
  faLanguage as falLanguage,
  faLock as falLock,
  faFileChartColumn as falFileChartColumn,
  faHand as falHand
} from '@fortawesome/pro-light-svg-icons'

import {
  faBadge as fapsBadge
} from '@fortawesome/pro-solid-svg-icons'

import {
  faEdit as farEdit,
  faFlag as farFlag,
  faCheckCircle as farCheckCircle,
  faPaperPlane as farPaperPlane,
  faEye as farEye,
  faEyeSlash as farEyeSlash,
  faFileAlt as farFileAlt,
  faSquare as farSquare,
  faImage as farImage,
  faCreditCard as farCreditCard,
  faCalendarAlt as farCalendarAlt,
  faAddressCard as farAddressCard,
  faSave as farSave,
  faUser as farUser,
  faTimesCircle as farTimesCircle,
  faThumbsUp as farThumbsUp,
  faFileImage as farFileImage
} from '@fortawesome/free-regular-svg-icons'

const icons = {
  fasArrowLeft,
  farEdit,
  fasEuroSign,
  fasLongArrowAltLeft,
  fasChevronDown,
  fasChevronRight,
  fasChevronLeft,
  fasCheck,
  fasTimes,
  fasArrowUp,
  fasArrowRight,
  fasEllipsisH,
  farFlag,
  fasBars,
  fasSearch,
  fasPlus,
  farCheckCircle,
  farPaperPlane,
  farEye,
  farEyeSlash,
  fasGlobe,
  fasExclamationTriangle,
  fasExclamationCircle,
  fasGripVertical,
  fasPlusCircle,
  fasFont,
  farFileAlt,
  fasAlignLeft,
  farSquare,
  farImage,
  farCreditCard,
  fasAt,
  farCalendarAlt,
  farAddressCard,
  farSave,
  fasCloudUploadAlt,
  farUser,
  fasShare,
  fasRedo,
  fasInfoCircle,
  fasUniversity,
  farTimesCircle,
  fasHourglassHalf,
  fasSync,
  farThumbsUp,
  fasUserPlus,
  fasFilter,
  falBold,
  falItalic,
  falUnderline,
  falH1,
  falH2,
  falListUl,
  falListOl,
  falQuoteRight,
  falUndo,
  falRedo,
  falPaperclip,
  falChevronUp,
  falChevronDown,
  falChevronRight,
  falChevronLeft,
  falCheck,
  falTimes,
  falCog,
  falHandshake,
  falBookmark,
  falMoneyBill,
  falTachometer,
  falBars,
  falSignOut,
  falFile,
  falQuestionCircle,
  falTrash,
  falBadgeCheck,
  falBadge,
  falCloudUpload,
  falExclamationTriangle,
  falExclamationCircle,
  falEmptySet,
  falFilter,
  falDownload,
  falSearch,
  falCheckSquare,
  falFont,
  falAlignLeft,
  falAlignCenter,
  falAlignRight,
  falUser,
  falAt,
  falAddressCard,
  falCreditCard,
  falCalendarAlt,
  falFileAlt,
  falShare,
  falExternalLinkSquare,
  falExternalLink,
  falImage,
  falTimesCircle,
  falCheckCircle,
  falUsers,
  fapsBadge,
  falHourglassHalf,
  falLink,
  falUnlink,
  falPlusCircle,
  falFlag,
  falBan,
  falSave,
  falCopy,
  falPollH,
  falLightbulb,
  farFileImage,
  falAnalytics,
  falCircle,
  falSquare,
  falCalendar,
  falPencilPaintbrush,
  falClock,
  falCreditCardFront,
  falStar,
  falText,
  falQrcode,
  falCode,
  falDirections,
  falFileImage,
  falGripVertical,
  falVial,
  fabFacebookSquare,
  fabFacebookMessenger,
  fabWhatsapp,
  fabTwitterSquare,
  fabTwitter,
  fabXTwitter,
  fabLinkedin,
  fabInstagram,
  fabYoutube,
  fabMedium,
  fabTiktok,
  fabTwitch,
  falPalette,
  falPlus,
  falSync,
  falInfoCircle,
  falTable,
  falBasketballBall,
  falHashtag,
  falPercent,
  falSortUp,
  falSortDown,
  falEye,
  falEyeSlash,
  falPenToSquare,
  falHandHoldingHeart,
  falRotateRight,
  falXmark,
  falSquareMinus,
  falSquarePlus,
  falTableLayout,
  falEllipsis,
  falLanguage,
  falLock,
  falFileChartColumn,
  falHand
}

library.add(icons)

export default {
  install (app) {
    app.component('FaIcon', FontAwesomeIcon)
  }
}
