import i18n from '@/vendors/i18n'
import { metrics_type_options, metrics_sporting_options } from '@/configurations/general/components-default-data'
export default [
  {
    name: 'campaign_big_banner',
    settings: {
      icon: 'image',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        },
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
        }
      },
      {
        path: 'modules/pages/SectionImageUpload',
        model: 'image',
        settings: {
          crop: true,
          size: { width: 1440, height: 550 }
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.banner')
    }
  },
  {
    name: 'campaign_big_stats',
    settings: {
      icon: 'timer',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'settings',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_campaigns')
            }
          },
          {
            path: 'modules/pages/SectionSelectForms',
            model: 'procedure_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_forms')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'metrics',
        content: {
          wrapper_title: 'text',
          btn_label: i18n.getLocalesTranslations('literal.add_metric'),
          default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
        },
        settings: {
          hide_label: true
        },
        components: [
          {
            path: 'modules/pages/SectionSelect',
            model: 'type',
            value: 'contributors_count',
            settings: {
              options: [...metrics_type_options, { value: 'custom', label: i18n.getLocalesTranslations('literal.custom') }]
            },
            content: {
              label: i18n.getLocalesTranslations('literal.metric')
            }
          },
          {
            path: 'modules/pages/SectionInputDate',
            model: 'date',
            content: {
              label: i18n.getLocalesTranslations('literal.date')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'date',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionSelect',
            model: 'sporting_statistics',
            value: 'total_distance',
            settings: {
              options: metrics_sporting_options
            },
            content: {
              label: i18n.getLocalesTranslations('literal.sporting_statistics')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'sporting_statistics',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'value',
            content: {
              label: i18n.getLocalesTranslations('literal.value')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'custom',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'text'
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.metrics')
    }
  },
  {
    name: 'campaign_big_about',
    settings: {
      icon: 'text_editor',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        },
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      },
      {
        path: 'modules/pages/SectionImageUpload',
        model: 'image',
        settings: {
          crop: true,
          size: { width: 516, height: 516 }
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.about')
    }
  },
  {
    name: 'campaign_big_breastcancernumbers',
    settings: {
      icon: 'list',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        settings: {
          hide_label: true,
        },
        content: {
          wrapper_title: 'number'
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'icon',
            settings: {
              crop: true,
              size: { width: 180, height: 180 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'number',
            content: {
              label: i18n.getLocalesTranslations('literal.number')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            }
          }
        ]
      }
    ],
    content: {
      title: {
        'en-GB': 'Breast cancer numbers',
        'fr-FR': 'Breast cancer numbers',
        'nl-NL': 'Breast cancer numbers'
      }
    }
  },
  {
    name: 'campaign_big_bignumbers',
    settings: {
      icon: 'list',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        settings: {
          hide_label: true,
        },
        content: {
          wrapper_title: 'number'
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'icon',
            settings: {
              crop: true,
              size: { width: 180, height: 180 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'number',
            content: {
              label: i18n.getLocalesTranslations('literal.number')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            }
          }
        ]
      }
    ],
    content: {
      title: {
        'en-GB': 'BIG numbers',
        'fr-FR': 'BIG numbers',
        'nl-NL': 'BIG numbers'
      }
    }
  },
  {
    name: 'campaign_big_video',
    settings: {
      icon: 'video',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionInputUrl',
        model: 'url',
        content: {
          label: {
            'fr-FR': 'URL (YouTube)',
            'en-GB': 'URL (YouTube)',
            'nl-NL': 'URL (YouTube)'
          }
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'content',
        content: {
          label: i18n.getLocalesTranslations('literal.content')
        },
        settings: {
          configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.video')
    }
  },
  {
    name: 'campaign_big_products',
    settings: {
      icon: 'shopping_basket',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        settings: {
          hide_label: true,
        },
        content: {
          wrapper_title: 'name'
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'image',
            settings: {
              crop: true,
              size: { width: 308, height: 204 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'name',
            content: {
              label: i18n.getLocalesTranslations('literal.name')
            }
          },
          {
            path: 'modules/pages/SectionInputAmount',
            model: 'price',
            content: {
              label: i18n.getLocalesTranslations('literal.price')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.call_to_action')
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.products')
    }
  },
  {
    name: 'campaign_big_events',
    settings: {
      icon: 'newsfeed',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        settings: {
          hide_label: true,
        },
        content: {
          wrapper_title: 'title'
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'image',
            settings: {
              crop: true,
              size: { width: 408, height: 248 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.call_to_action')
            }
          },
          {
            path: 'modules/pages/SectionInputDate',
            model: 'date',
            content: {
              label: i18n.getLocalesTranslations('literal.date')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'location',
            content: {
              label: i18n.getLocalesTranslations('literal.location')
            }
          }
        ]
      }
    ],
    content: {
      title: { 'fr-FR': 'Événements', 'en-GB': 'Events', 'nl-NL': 'Events' }
    }
  }
]