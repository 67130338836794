import i18n from '@/vendors/i18n'

export default {
  name: 'custom_header_jez',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionContainer',
      model: 'logo',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.logo')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'image',
          settings: {
            crop: false,
            size: { width: 9999, height: 76 }
          },
          content: {
            label: { 'nl-NL': ' ', 'en-GB': ' ', 'fr-FR': ' ' }
          }
        },
        {
          path: 'modules/pages/SectionInputUrl',
          model: 'url',
          content: {
            label: i18n.getLocalesTranslations('literal.url')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.login_register_and_profile')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    }
  ]
}