import i18n from '@/vendors/i18n'

export default {
  name: 'faq',
  settings: {
    icon: 'question'
  },
  components: [
    {
      path: 'modules/pages/SectionInputText',
      model: 'question',
      content: {
        label: i18n.getLocalesTranslations('literal.question')
      }
    },
    {
      path: 'modules/pages/SectionTextEditor',
      model: 'answer',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.answer')
      }
    }
  ],
  content: {
    title: i18n.getLocalesTranslations('literal.faq')
  }
}
