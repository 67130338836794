import i18n from '@/vendors/i18n'

export default [
  {
    name: 'crowdfunding_blindenzorglichtliefde_banner',
    settings: {
      icon: 'image',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        },
        model: 'image',
        settings: {
          crop: false,
          size: { width: 2000, height: 9999 }
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'subtitle',
        content: {
          label: i18n.getLocalesTranslations('literal.subtitle')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.banner')
    }
  },
  {
    name: 'crowdfunding_blindenzorglichtliefde_informations',
    settings: {
      icon: 'text',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      },
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        },
        model: 'image',
        settings: {
          crop: true,
          size: { width: 470, height: 470 }
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'quote',
        settings: {
          configuration: ['bold', 'italic', 'underline']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.quote')
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'author',
        content: {
          label: i18n.getLocalesTranslations('literal.author')
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'button',
        content: {
          label: i18n.getLocalesTranslations('literal.button')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.informations')
    }
  },
  {
    name: 'crowdfunding_blindenzorglichtliefde_rooms',
    settings: {
      icon: 'location',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.description')
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'second_floor',
        content: {
          default_wrapper_title: {
            'fr-FR': 'Floor n°2',
            'en-GB': 'Floor n°2',
            'nl-NL': 'Floor n°2'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_1',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 1',
                'en-GB': 'Dot 1',
                'nl-NL': 'Dot 1'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_2',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 2',
                'en-GB': 'Dot 2',
                'nl-NL': 'Dot 2'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_3',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 3',
                'en-GB': 'Dot 3',
                'nl-NL': 'Dot 3'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_4',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 4',
                'en-GB': 'Dot 4',
                'nl-NL': 'Dot 4'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_5',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 5',
                'en-GB': 'Dot 5',
                'nl-NL': 'Dot 5'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_6',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 6',
                'en-GB': 'Dot 6',
                'nl-NL': 'Dot 6'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_7',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 7',
                'en-GB': 'Dot 7',
                'nl-NL': 'Dot 7'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'first_floor',
        content: {
          default_wrapper_title: {
            'fr-FR': 'Floor n°1',
            'en-GB': 'Floor n°1',
            'nl-NL': 'Floor n°1'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_1',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 1',
                'en-GB': 'Dot 1',
                'nl-NL': 'Dot 1'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_2',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 2',
                'en-GB': 'Dot 2',
                'nl-NL': 'Dot 2'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_3',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 3',
                'en-GB': 'Dot 3',
                'nl-NL': 'Dot 3'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_4',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 4',
                'en-GB': 'Dot 4',
                'nl-NL': 'Dot 4'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_5',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 5',
                'en-GB': 'Dot 5',
                'nl-NL': 'Dot 5'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_6',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 6',
                'en-GB': 'Dot 6',
                'nl-NL': 'Dot 6'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_7',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 7',
                'en-GB': 'Dot 7',
                'nl-NL': 'Dot 7'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_8',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 8',
                'en-GB': 'Dot 8',
                'nl-NL': 'Dot 8'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_9',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 9',
                'en-GB': 'Dot 9',
                'nl-NL': 'Dot 9'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_10',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 10',
                'en-GB': 'Dot 10',
                'nl-NL': 'Dot 10'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_11',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 11',
                'en-GB': 'Dot 11',
                'nl-NL': 'Dot 11'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'ground_floor',
        content: {
          default_wrapper_title: {
            'fr-FR': 'Ground floor',
            'en-GB': 'Ground floor',
            'nl-NL': 'Ground floor'
          }
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_1',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 1',
                'en-GB': 'Dot 1',
                'nl-NL': 'Dot 1'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_2',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 2',
                'en-GB': 'Dot 2',
                'nl-NL': 'Dot 2'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_3',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 3',
                'en-GB': 'Dot 3',
                'nl-NL': 'Dot 3'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_4',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 4',
                'en-GB': 'Dot 4',
                'nl-NL': 'Dot 4'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_5',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 5',
                'en-GB': 'Dot 5',
                'nl-NL': 'Dot 5'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_6',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 6',
                'en-GB': 'Dot 6',
                'nl-NL': 'Dot 6'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_7',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 7',
                'en-GB': 'Dot 7',
                'nl-NL': 'Dot 7'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_8',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 8',
                'en-GB': 'Dot 8',
                'nl-NL': 'Dot 8'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_9',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 9',
                'en-GB': 'Dot 9',
                'nl-NL': 'Dot 9'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_10',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 10',
                'en-GB': 'Dot 10',
                'nl-NL': 'Dot 10'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_11',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 11',
                'en-GB': 'Dot 11',
                'nl-NL': 'Dot 11'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          },
          {
            path: 'modules/pages/SectionContainer',
            model: 'dot_12',
            content: {
              default_wrapper_title: {
                'fr-FR': 'Dot 12',
                'en-GB': 'Dot 12',
                'nl-NL': 'Dot 12'
              }
            },
            components: [
              {
                path: 'modules/pages/SectionSelectProjects',
                model: 'project_id',
                content: {
                  label: i18n.getLocalesTranslations('literal.project')
                },
                settings: {
                  limit: 1
                }
              }
            ]
          }
        ]
      }
    ],
    content: {
      title: {
        'en-GB': 'Rooms',
        'fr-FR': 'Rooms',
        'nl-NL': 'Rooms'
      }
    }
  },
  {
    name: 'crowdfunding_blindenzorglichtliefde_timeline',
    settings: {
      icon: 'location',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'events',
        content: {
          label: {
            'fr-FR': 'Events',
            'en-GB': 'Events',
            'nl-NL': 'Events',
          },
          wrapper_title: 'date',
          btn_label: {
            'fr-FR': 'Add an event',
            'en-GB': 'Add an event',
            'nl-NL': 'Add an event',
          },
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.description')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'date',
            content: {
              label: i18n.getLocalesTranslations('literal.date')
            }
          }
        ]
      }
    ],
    content: {
      title: {
        'en-GB': 'Timeline',
        'fr-FR': 'Timeline',
        'nl-NL': 'Timeline'
      }
    }
  },
  {
    name: 'crowdfunding_blindenzorglichtliefde_gallery',
    settings: {
      icon: 'location',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'images_left',
        content: {
          label: {
            'fr-FR': 'Images - left column',
            'en-GB': 'Images - left column',
            'nl-NL': 'Images - left column',
          },
          btn_label: {
            'fr-FR': 'Add an image',
            'en-GB': 'Add an image',
            'nl-NL': 'Add an image',
          },
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: false,
              size: { width: 555, height: 9999 }
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'images_right',
        content: {
          label: {
            'fr-FR': 'Images - right column',
            'en-GB': 'Images - right column',
            'nl-NL': 'Images - right column',
          },
          btn_label: {
            'fr-FR': 'Add an image',
            'en-GB': 'Add an image',
            'nl-NL': 'Add an image',
          },
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: false,
              size: { width: 555, height: 9999 }
            }
          }
        ]
      }
    ],
    content: {
      title: {
        'en-GB': 'Gallery',
        'fr-FR': 'Gallery',
        'nl-NL': 'Gallery'
      }
    }
  },
  {
    name: 'crowdfunding_blindenzorglichtliefde_testimonials',
    settings: {
      icon: 'location',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'testimonials',
        content: {
          label: {
            'fr-FR': 'Testimonials',
            'en-GB': 'Testimonials',
            'nl-NL': 'Testimonials',
          },
          wrapper_title: 'reference',
          btn_label: {
            'fr-FR': 'Add a testimonial',
            'en-GB': 'Add a testimonial',
            'nl-NL': 'Add a testimonial',
          },
        },
        components: [
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.description')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'reference',
            content: {
              label: {
                'fr-FR': 'Reference',
                'en-GB': 'Reference',
                'nl-NL': 'Reference',
              }
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'button',
        content: {
          label: i18n.getLocalesTranslations('literal.button')
        }
      }
    ],
    content: {
      title: {
        'en-GB': 'Testimonials',
        'fr-FR': 'Testimonials',
        'nl-NL': 'Testimonials'
      }
    }
  }
]