import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_kajaktrail',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Column 1'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_1.links',
      settings: {
        layout: 'inline',
        hide_label: true
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Column 2'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_2.links',
      settings: {
        layout: 'inline',
        hide_label: true
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Column 3'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_3.links',
      settings: {
        layout: 'inline',
        hide_label: true
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: {
          'nl-NL': 'Column 4'
        }
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'column_4.links',
      settings: {
        layout: 'inline',
        hide_label: true
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.logo')
      }
    },
    {
      path: 'modules/pages/SectionImageUpload',
      content: {
        label: i18n.getLocalesTranslations('literal.logo')
      },
      model: 'logo.image',
      settings: {
        crop: false,
        size: { width: 100, height: 9999 },
        hide_label: true
      }
    }
  ]
}
