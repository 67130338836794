import i18n from '@/vendors/i18n'
import Section from '@/classes/procedures/Section'

const create_project = {
  name: 'project_informations',
  attributes: {
    back_btn: i18n.getLocalesTranslations('$procedure_step.button_back'),
    next_btn: i18n.getLocalesTranslations('literal.create_project')
  },
  sections: [
    Section.set({ name: 'project_informations' })
  ],
  static_options: {
    icon: 'task_checklist',
    is_not_deletable: true
  },
  content: {
    title: i18n.getLocalesTranslations('literal.project_informations')
  }
}

export default create_project
