import i18n from '@/vendors/i18n'

export default {
  name: 'custom_header_designmuseumgent',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionLogo'
    },
    {
      path: 'modules/pages/SectionImageUpload',
      model: 'mobile_logo',
      content: {
        label: i18n.getLocalesTranslations('literal.mobile_logo')
      },
      settings: {
        crop: false,
        size: { width: 38, height: 9999 }
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.menu')

      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'links',
      settings: {
        layout: 'inline',
        hide_label: true
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.social_networks')
      }
    },
    {
      path: 'modules/pages/SectionNetworks'
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator','separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.actions')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'actions',
      settings: {
        layout: 'inline',
        hide_label: true
      },
      content: {
        btn_label: i18n.getLocalesTranslations('literal.add_new_button')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionInputText',
      model: 'login',
      content: {
        label: {
          'nl-NL': 'Login button'
        }
      }
    }
  ]
}
