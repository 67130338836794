const Transactions = () => import(/* webpackChunkName: 'Transactions' */'@/views/transactions/ViewTransactions')
const TransactionDetails = () => import(/* webpackChunkName: 'TransactionDetails' */'@/views/transactions/ViewTransactionDetails')

const routes = [
  {
    path: 'transactions',
    name: 'Transactions',
    component: Transactions,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.transactions', routeName: 'Transactions' }
      ],
      faq: [
        'transactions_how_can_i_export_transactions',
        'transactions_how_can_i_stop_a_recurring_donation',
        'transactions_can_i_make_a_refund_to_a_donor_via_mykoalect'
      ]
    }
  },
  {
    path: 'transactions/:id',
    name: 'TransactionDetails',
    component: TransactionDetails,
    props (route) {
      const props = { ...route.params }
      props.id = +props.id
      return props
    },
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.transactions', routeName: 'Transactions' },
        { routeName: 'TransactionDetails' }
      ],
      faq: [
        'transactions_how_can_i_stop_a_recurring_donation',
        'transactions_can_i_make_a_refund_to_a_donor_via_mykoalect'
      ]
    }
  }
]

export default routes
